import styled from 'styled-components';

export const Content = styled.div`
    background-color: #f2f2f2;
    padding: 10px;
    width: 100%;
    border-radius: 6px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -webkit-box-shadow: 2px 9px 23px -6px rgba(0,0,0,0.18);
-moz-box-shadow: 2px 9px 23px -6px rgba(0,0,0,0.18);
box-shadow: 2px 8px 10px -5px rgba(0,0,0,0.18);
`;

export const HeaderContent = styled.div`
`;

export const BottomContent = styled.div`
`;

export const Title = styled.div`
    margin-bottom: 5px;
    color: #637381;
`;

export const AmountContent = styled.div`
    font-size: 25px;
    font-weight: bold;
    color: #454F5B
`;