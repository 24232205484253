import { comp } from "./style"
import { ReactNode } from "react";

import ThreeDotsWave from "../ThreeDotsWave";
import "./MessageBox.css";

interface MessageBallonProps {
  isTyping?: boolean;
  notch: 'left' | 'right';
  title: string;
  titleColor?: string;
  content: ReactNode;
  hourSended: Date;
  showOptions?: boolean;
  answers?: ReactNode;
  failState?: boolean;
  onRetrySendMessage?: () => void;
  lastQuestion?: boolean;
  onLastQuestionButtonClick?: () => void;
}

export default function MessageBalloon(props: MessageBallonProps) {
  const formatBRDate = new Intl.DateTimeFormat('pt-BR', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'America/Sao_Paulo', 
  });

  return (
    <comp.MessageContainer failState={props?.failState} lastQuestion={props?.lastQuestion} notch={props.notch}>
      {props?.failState && <>
        <comp.FailContainer onClick={() => {
          props?.onRetrySendMessage?.()
        }}>
          !
        </comp.FailContainer>
      </>
      }
      <comp.Message notch={props.notch}>
        <comp.TextTitle className='rce-mbox-title' titleColor={props.titleColor}>
          {props.title}
        </comp.TextTitle>

        <comp.MessageBalloonContent className='rce-mbox-text'>
          {
            props.isTyping ? <ThreeDotsWave /> 
            :
            <>
              {props.content}
              {!props.lastQuestion && props.answers && props.answers}
              {props.lastQuestion && <comp.ButtonWrapper>
                  <comp.ButtonLastQuestion onClick={() => {props?.onLastQuestionButtonClick?.()}}>
                    Concluir
                  </comp.ButtonLastQuestion>
                </comp.ButtonWrapper>
              }
              
            </>
          }
        </comp.MessageBalloonContent>

        <comp.Hour failState={props.failState}>
          {props.failState && 'Ocorreu um erro durante o envio da sua mensagem, clique na exclamação ao lado para tentar o reenvio.'} 
          <span>{formatBRDate.format(props.hourSended)}</span>
        </comp.Hour>
        
        <comp.NotchContainer>
          {
            (props.notch == 'right' ? (
                <svg
                  className='rce-mbox-right-notch'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path d='M0 0v20L20 0' />
                </svg>
              ) : (
                <svg
                  className='rce-mbox-left-notch'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <defs>
                    <filter id='filter1' x='0' y='0'>
                      <feOffset result='offOut' in='SourceAlpha' dx='-2' dy='-5' />
                      <feGaussianBlur result='blurOut' in='offOut' stdDeviation='3' />
                      <feBlend in='SourceGraphic' in2='blurOut' mode='normal' />
                    </filter>
                  </defs>
                  <path d='M20 0v20L0 0' filter='url(#filter1)' />
                </svg>
              )
            )
          }
        </comp.NotchContainer> 
      </comp.Message>
    </comp.MessageContainer>
  );
}
