import { useEffect, useState } from "react";
import { BiWorld } from "react-icons/bi";
import { CiMail } from "react-icons/ci";
import { FaHashtag } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { LuAtSign } from "react-icons/lu";
import { MdOutlineWorkOutline } from "react-icons/md";
import {
  PiIdentificationCardLight,
  PiPhone,
  PiUserCircleLight,
} from "react-icons/pi";
import { BsPersonHeart } from "react-icons/bs";
import { toast } from "react-toastify";
import AnonymousProfilePhoto from "../../../assets/userAnonymous.jpeg";
import CustomDropdown from "../../../components/CustomDropDown";
import CustomInput from "../../../components/CustomInput";
import ModalStepByStep from "../../../components/ModalStepByStep";
import api from "../../../service/api";
import formatCEP from "../../../utils/formatCEP";
import { formatDocument } from "../../../utils/formatDocument";
import { formatPhone } from "../../../utils/formatPhone";
import { InfoWrapper } from "../../Event/Create/style";
import {
  ContentWrapper,
  FlexContainer,
  ProfileImageWrapper,
  WrapperInput,
} from "./style";

interface CreateProfessionalProps {
  closeModal: () => void;
  updateClients?: () => void;
}

export const CreateProfessional = ({
  closeModal,
  updateClients,
}: CreateProfessionalProps) => {
  const [tags, setClientTags] = useState<any[]>([]);

  const fetchClientTags = () => {
    api
      .get("/tags/")
      .then((resp) => {
        setClientTags(resp.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchClientTags();
    fetchProfessionals();
  }, []);

  const [form, setForm] = useState<any>({
    name: "",
    document: "",
    email: "",
    phone: "",
    address: "",
    origin: "",
    ocupation: "",
    birthday: "",
    alias: "",
    cep: "",
    tag: null,
    professional: null,
  });

  const steps = [
    {
      stepTitle: "Informações Básicas",
    },
    {
      stepTitle: "Dados complementares",
    },
    {
      stepTitle: "Foto de Perfil",
    },
  ];

  const [stepActive, setStepActive] = useState(steps[0]);
  const [loading, setLoading] = useState(false);
  const [selectedTagName, setSelectedTagName] = useState("");
  const [professionals, setProfessionals] = useState<any[]>([]);
  const [selectedProfessional, setSelectedProfessional] = useState<any>({});
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [imagem, setImagem] = useState<any>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;

    if (name == "document") value = formatDocument(value);
    else if (name == "phone") value = formatPhone(value);
    else if (name == "cep") value = formatCEP(value);
    setForm({ ...form, [name]: value });
  };

  const savePatient = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", form["name"]);
    formData.append("document", form["document"]);
    formData.append("email", form["email"]);
    formData.append("phone", form["phone"]);
    formData.append("address", form["address"]);
    formData.append("origin", form["origin"]);
    formData.append("ocupation", form["ocupation"]);
    formData.append("birthday", form["birthday"]);
    formData.append("alias", form["alias"]);
    formData.append("cep", form["cep"]);
    formData.append("tag", form["tag"]);
    formData.append("professional", form["professional"]);

    if (imagem) formData.append("photo", selectedImage);

    api
      .post("/clients/", formData)
      .then(() => {
        setLoading(false);
        closeModal();
        toast.success("Paciente cadastrado com sucesso!");
        updateClients?.();
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao cadastrar o paciente!");
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateNextStep = () => {
    if (stepActive.stepTitle == "Informações Básicas") {
      if (form["name"].length < 3) {
        toast.error("Digite um nome válido");
        return false;
      } else if (form["phone"].replace(/\D/g, "").length != 11) {
        toast.error("Digite um número de telefone válido");
        return false;
      } else if (form["birthday"] == "") {
        toast.error("Selecione uma data de nascimento.");
        return false;
      }
    }
    if (stepActive.stepTitle == "Dados complementares") {
      return validateLastStep();
    }

    return true;
  };

  const validateLastStep = () => {
    const regValidateEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // if (!regValidateEmail.test(form["email"])) {
    //   toast.error("Digite um email válido.");
    //   return false;
    // } else if (form["origin"].length < 3) {
    //   toast.error("Digite uma origem válida");
    //   return false;
    // } else if (form["ocupation"].length < 3) {
    //   toast.error("Digite uma ocupação válida.");
    //   return false;
    if (form["tag"] == null) {
      toast.error("Selecione uma tag para o paciente");
      return false;
    } else if (form["professional"] == null) {
      toast.error("Selecione um profissional para o paciente");
      return false;
    }

    return true;
  };

  const fetchProfessionals = () => {
    api.get(`/professionals/`).then((response) => {
      setProfessionals(response.data);
    });
  };

  const handleDropdownSelected = (key: any, value: any) => {
    setForm({ ...form, [key]: value });
  };

  const handleFileUpload = (event: any) => {
    const arquivo = event.target.files[0];

    const reader = new FileReader();
    setSelectedImage(arquivo);

    reader.onload = () => {
      const result = reader.result as any;
      setImagem(result);
    };

    reader.readAsDataURL(arquivo);
  };

  return (
    <ModalStepByStep
      steps={steps}
      isUpdateModal={false}
      onChangeStep={(step) => {
        setStepActive({ stepTitle: step.stepTitle });
      }}
      closeModal={closeModal}
      onConclude={() => {
        savePatient();
      }}
      validateNextStep={() => validateNextStep()}
      isLoadingRequest={loading}
    >
      <ContentWrapper>
        <InfoWrapper>
          {stepActive.stepTitle == steps[0].stepTitle && (
            <>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  value={form["name"]}
                  onChange={handleInputChange}
                  label="Nome"
                  name="name"
                  placeholder="Digite o nome do paciente"
                  leftIcon={
                    <PiUserCircleLight
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
                <CustomInput
                  disabled={loading}
                  value={form["document"]}
                  onChange={handleInputChange}
                  label="CPF"
                  name="document"
                  placeholder="Digite o CPF do paciente"
                  leftIcon={
                    <PiIdentificationCardLight
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
              </WrapperInput>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  value={form["birthday"]}
                  type="date"
                  onChange={handleInputChange}
                  label="Data de nascimento"
                  name="birthday"
                  placeholder="Digite o telefone do paciente"
                  leftIcon={
                    <LiaBirthdayCakeSolid
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
                <CustomInput
                  disabled={loading}
                  value={form["phone"]}
                  onChange={handleInputChange}
                  label="Telefone"
                  name="phone"
                  placeholder="Digite o telefone do paciente"
                  leftIcon={
                    <PiPhone size={20} color="var(--primary-icon-color)" />
                  }
                />
              </WrapperInput>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  onChange={handleInputChange}
                  value={form["address"]}
                  label="Endereço"
                  name="address"
                  placeholder="Digite o endereço do paciente"
                  leftIcon={
                    <GoLocation size={20} color="var(--primary-icon-color)" />
                  }
                />
                <CustomInput
                  disabled={loading}
                  onChange={handleInputChange}
                  value={form["cep"]}
                  label="CEP"
                  name="cep"
                  placeholder="Digite o CEP do paciente"
                  leftIcon={
                    <BiWorld size={20} color="var(--primary-icon-color)" />
                  }
                />
              </WrapperInput>
            </>
          )}
          {stepActive.stepTitle == steps[1].stepTitle && (
            <>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  value={form["email"]}
                  onChange={handleInputChange}
                  label="E-mail"
                  name="email"
                  placeholder="Digite o email do paciente"
                  leftIcon={
                    <CiMail size={20} color="var(--primary-icon-color)" />
                  }
                />
                <CustomInput
                  disabled={loading}
                  value={form["origin"]}
                  onChange={handleInputChange}
                  label="Origem"
                  name="origin"
                  placeholder="Facebook, Instagram, Whatsapp.."
                  leftIcon={
                    <LuAtSign size={20} color="var(--primary-icon-color)" />
                  }
                />
              </WrapperInput>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  value={form["ocupation"]}
                  onChange={handleInputChange}
                  label="Ocupação"
                  name="ocupation"
                  placeholder="Digite a ocupação do paciente"
                  leftIcon={
                    <MdOutlineWorkOutline
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
                <CustomDropdown
                  label="Tag"
                  options={tags}
                  selectedOption={selectedTagName}
                  setSelectedOption={(value) => {
                    handleDropdownSelected("tag", value.id);
                    setSelectedTagName(value.name);
                  }}
                  leftIcon={
                    <FaHashtag size={16} color="var(--primary-icon-color)" />
                  }
                />
              </WrapperInput>
              <WrapperInput>
                <CustomDropdown
                  label="Profissional"
                  setSelectedOption={(value: any) => {
                    handleDropdownSelected("professional", value.id);
                    setSelectedProfessional(value);
                  }}
                  selectedOption={
                    selectedProfessional?.name != null
                      ? selectedProfessional.name
                      : ""
                  }
                  options={professionals}
                  allowCreate={false}
                  onCreate={() => {}}
                  createLabel="Cadastrar profissional"
                  leftIcon={
                    <HiOutlineUserCircle
                      size={16}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
                <CustomInput
                  disabled={loading}
                  value={form["alias"]}
                  onChange={handleInputChange}
                  label="Apelido"
                  name="alias"
                  placeholder="Como gostaria de ser chamado"
                  leftIcon={
                    <BsPersonHeart
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
              </WrapperInput>
            </>
          )}
          {stepActive.stepTitle == steps[2].stepTitle && (
            <>
              <FlexContainer>
                <ProfileImageWrapper
                  src={imagem || AnonymousProfilePhoto}
                  alt="Foto de perfil"
                />
                <div style={{ marginLeft: "10px" }}>
                  <CustomInput
                    label="Escolha uma foto"
                    type="file"
                    disableBorder
                    onChange={handleFileUpload}
                  />
                </div>
              </FlexContainer>
            </>
          )}
        </InfoWrapper>
      </ContentWrapper>
    </ModalStepByStep>
  );
};

export default CreateProfessional;
