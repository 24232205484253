export default function formatCurrency(inputValue: string): string {
  // Remove todos os caracteres não numéricos do valor de entrada
  const numericValue = inputValue?.replace(/\D/g, '') || '0,00'

  // Converte o valor numérico em um número decimal
  const numericAmount = parseFloat(numericValue) / 100; // Divide por 100 para considerar os centavos
  
  // Formate como "R$ 0,00"
  return `R$ ${numericAmount.toFixed(2).replace('.', ',')}`;
}

// Exemplo de uso:
// const inputValor = '12345abc678,90'; // Insira o valor aqui (pode conter caracteres não numéricos)
// const valorFormatado = formatCurrency(inputValor);
//  // Isso imprimirá o valor formatado
