import styled from "styled-components";

export const TextAreaInput = styled.textarea`
  border: 1px solid #919EAB33;
  background-color: #fff;
  border-radius: 8px;
  padding: 8px 12px;
  resize: vertical;
  outline: none;
  font-family: inherit;
  resize: none;
`;
