export const firstQuestion = [
  {
    id: -1,
    answers: [
      {
        id: -1,
        messageToShow: "Tudo certo",
        question: 1,
      },
      {
        id: -2,
        messageToShow: "Entendido",
        question: -1,
      },
    ],
    question: "Aqui vai uma demonstração. Assim que você escolher uma das opções, nossa pré-consulta vai começar.",
    preform: -5,
  },
]

export const lastQuestion = [
  {
    id: -2,
    answers: [
      {
        id: -3,
        messageToShow: "Finalizar",
        question: -2,
      },
    ],
    question: 'Ótimo, juntei tudo que é necessário. Lembrando que ainda tem chance de atualizar todas as suas respostas. Se estiver satisfeito com todas, basta apertar no botão logo abaixo para finalizarmos a pré-consulta.',
    preform: -5,
  },
]