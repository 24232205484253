import styled from 'styled-components';

export interface ProgressProps {
    currentValue: number;
}

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
`;

export const Bg = styled.div`
    background-color: #e6e6e6;
    height: 5px;
    width: 100%;
    border-radius: 10px;
    position: absolute;
`;

export const ValuesWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
`;

export const InitialValue = styled.div`
    font-size: 15px;
    color: #919EAB;
    
`;

export const FinalValue = styled.div`
    font-size: 15px;
    color: #919EAB;
    width: 100%;
    text-align: end;
    
    &::after {
        content: '%';
    }
`

export const Bar = styled.div`
    width: 100%;
`;

export const Progress = styled.div<ProgressProps>`
    background-color: #22C55E;
    width: ${(props) => props.currentValue}%;
    height: 5px;
    border-radius: 10px;
    position: absolute;
    z-index: 1;
    text-align: end;
`;

export const CurrentProgressValue = styled.div`
    width: 100%;
    text-align: end;
`;

export const Value = styled.div<ProgressProps>`
    width: ${(props) => props.currentValue}%;
    font-size: 15px;
    color: #919EAB;
    text-align: end;

    &::after {
        content: '%';
    }
`;