import Modal from "react-modal";
import * as S from "./styles";
import AtencaoImg from "../../assets/atencao.png";
import CustomButton from "../CustomButton";

interface ModalErrorProps {
  errorList: string[];
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  title: string;
}

const customStyles = {
  overlay: {
    zIndex: 9000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    width: "25%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 16,
    transition: "all 0.3s ease",
    overflow: "visible",
  },
};

export const ModalError = ({
  errorList,
  setShowModal,
  showModal,
  title,
}: ModalErrorProps) => {
  return (
    <Modal isOpen={showModal} style={customStyles}>
      <S.MainWrapper>
        <S.WrapperTitle>
          <S.Image src={AtencaoImg} />
          <S.Title>{title}</S.Title>
          <S.ErrorListWrapper>
            {errorList.map((element) => (
              <S.ErrorMessage>{element}</S.ErrorMessage>
            ))}
          </S.ErrorListWrapper>
        </S.WrapperTitle>
        <CustomButton theme="cancel" onClick={() => setShowModal(false)}>
          Fechar
        </CustomButton>
      </S.MainWrapper>
    </Modal>
  );
};
