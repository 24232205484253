import { ReactNode } from "react";
import { Info, Label, LabelWrapper } from "./style";

interface CustomLabelProps {
  label?: string;
  info?: string;
  infoBold?: boolean;
  children?: ReactNode;
  color: string;
}

export const LabelTags = ({
  label,
  info,
  children,
  infoBold,
  color,
}: CustomLabelProps) => {
  return (
    <LabelWrapper>
      <Label>{label}</Label>
      {info && (
        <Info bold={infoBold || false} color={color}>
          {info}
        </Info>
      )}
      {children && children}
    </LabelWrapper>
  );
};

export default LabelTags;
