import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import api from "../service/api";
import { toast } from "react-toastify";

interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  setMail: Dispatch<SetStateAction<string>>;
  mail: string | null;
  password: string | null;
  setPassword: Dispatch<SetStateAction<string>>;
  userToken: string | null;
  setUserRole: Dispatch<SetStateAction<string>>;
  userRole: string | null;
  Logout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  setIsAuthenticated: () => false,
  userToken: null,
  setUserRole: () => "",
  userRole: null,
  Logout: () => {},
  mail: null,
  setMail: () => "",
  setPassword: () => "",
  password: null,
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [userToken, setUserToken] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");

  const Logout = () => {
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        Logout,
        userRole,
        userToken,
        setMail,
        mail,
        setPassword,
        password,
        setUserRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
