import React, { useState, useCallback } from "react";
import * as S from "./styles";

interface OptionsProps {
  title: string;
  value: string;
}

interface ButtonOptionsListProps {
  options: Array<OptionsProps>;
  title: string;
  selectedValue: string;
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
}

const ButtonOptionList = React.memo(
  ({
    options,
    title,
    selectedValue,
    setSelectedValue,
    disabled,
  }: ButtonOptionsListProps) => {
    const handleOptionClick = useCallback((value: string) => {
      if (disabled) return;
      setSelectedValue(value);
    }, []);

    return (
      <S.Wrapper>
        <S.TitleWrapper>{title}</S.TitleWrapper>
        <S.OptionsWrapper>
          {options.map((element) => (
            <S.Button
              key={element.value}
              isMarked={selectedValue === element.value}
              disabled={disabled}
              onClick={() => handleOptionClick(element.value)}
            >
              {element.title}
            </S.Button>
          ))}
        </S.OptionsWrapper>
      </S.Wrapper>
    );
  }
);

export default ButtonOptionList;
