import styled from "styled-components";

interface ModalWrapperProps {
    size?: 'default' | 'large' | 'small';
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
    width: ${props => {
        switch (props.size) {
            case 'default':
                return '900px';
            case 'small':
                return '460px';
            default:
                return '1000px';
        }
    }};
    transition: all 0.3s ease-in-out;
`

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #E5E5E5;
`

export const HeaderTitle = styled.span`
    font-size: 20px;
    font-weight: 600;
`

export const ContentWrapper = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 24px 0;
`

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 5;
`
export const SeparatorBottom = styled.div`
    margin-bottom: 25px;
`

export const ObservationsWrapper = styled.div`

`

interface FooterWrapperProps {
    type?: 'space-between' | 'space-around' | 'space-evenly' | 'flex-end' | 'flex-start' | 'center';
}

export const FooterWrapper = styled.div<FooterWrapperProps>`
    padding-top: 10px;
    border-top: 1px solid #E5E5E5;
    display: flex;
    justify-content: ${props => props.type ? props.type : 'space-between'};
`
