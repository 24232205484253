import ApexCharts from "apexcharts";
import { useEffect, useRef } from "react";

interface SeriesProps {
    name: string;
    data: number[];
}

interface Profit {
    data: SeriesProps[];
    labelsExtern?: string[];
}

export const GaugeOccupation = ({ data }: Profit) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const total = 45; // Capacidade total
        const occupied = 22.5; // Ocupação atual
        const occupancyRate = (occupied / total) * 100; // Taxa de ocupação em %

        const options = {
            series: [occupancyRate],
            chart: {
                type: "radialBar",
                height: 200,
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '70%',
                    },
                    dataLabels: {
                        name: {
                            offsetY: -10,
                            show: true,
                            color: '#888',
                            fontSize: '11px'
                        },
                        value: {

                            offsetY: 0,
                            formatter: function (val: number) {
                                return `${val.toFixed(2)}%`;
                            },
                            color: '#111',
                            fontSize: '24px',
                            show: true,
                        }
                    }
                }
            },
            labels: ['Taxa de Ocupação'],
            colors: ["#1974fc"],
        };

        const chart = new ApexCharts(chartRef.current, options);
        chart.render();

        return () => {
            chart.destroy();
        };
    }, [data]);

    return <div ref={chartRef} />;
};
