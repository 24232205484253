import styled from "styled-components";

interface DropdownButtonProps {
  type?: "default" | "payment";
  disableBorder?: boolean;
}

export const DropdownWrapper = styled.div<DropdownButtonProps>`
  display: flex;
  padding: ${({ type }) => (type !== "payment" ? "16px 14px" : "9px 14px")};
  align-items: center;
  gap: 8px;
  background-color: #ffffff;
  border: ${(props) => (props.disableBorder ? "none" : "1px solid #919EAB33")};
  border-radius: 8px;
  position: relative;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #f4f6f8;
  }
`;

export const DropdownButton = styled.button<DropdownButtonProps>`
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
`;

interface DropdownListProps {
  open: boolean;
}

export const DropdownList = styled.ul<DropdownListProps>`
  position: absolute;
  /* top: 100%; */
  left: 0;
  width: 100%;
  list-style: none;
  padding: 8px 0;
  margin: 0;
  background-color: #ffffff;
  border: 1px solid #919eab33;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: ${({ open }) => (open ? "block" : "none")};
  z-index: 9999;
  max-height: 300px;
  overflow: auto;
  top: 100%;
`;

export const DropdownListPayments = styled.ul<DropdownListProps>`
  position: absolute;
  /* top: 100%; */
  left: 0;
  width: 100%;
  list-style: none;
  padding: 4px;
  margin: 0;
  background-color: #f4f6f8;
  border: 1px solid #919eab33;
  border-radius: 4px;
  box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12),
    0px 0px 2px 0px rgba(145, 158, 171, 0.2);
  display: ${({ open }) => (open ? "block" : "none")};
  z-index: 9999;
  font-size: 14px;
  max-height: 300px;
  overflow: auto;
  top: 0;
`;

export const DropdownItem = styled.li`
  padding: 8px 16px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const DropdownItemNew = styled.li`
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  display: flex;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 14px;
  background-color: var(--primary-icon-color);
  transition: 0.2s ease-in-out;
  margin: 0.2rem 5rem;
  border-radius: 0.25rem;

  &:hover {
    opacity: 0.9;
  }
`;

export const SideElementWrapper = styled.div``;

export const ElementTitle = styled.div`
  color: black;
`;


interface CheckBoxProps {
  active?: boolean;
}

export const CheckBox = styled.div<CheckBoxProps>`
  width: 15px;
  height: 15px;
  border: 1px solid #919EAB33;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({active}) => active ? 'var(--primary-icon-color)' : 'white'};
`

export const OptionShape = styled.div`
  background-color: var(--primary-icon-color);
  padding: 3px 6px;
  color: white;
  border-radius: 5px;
`