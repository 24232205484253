import styled from 'styled-components';

export const WrapperPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  overflow: hidden;

  @media (max-width: 1200px) {
    padding: 15px;
  }

  @media (max-width: 1024px) {
    padding: 10px;
  }

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const WrapperCenter = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const WrapperMid = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 10px;

  @media (min-width: 1280px) {
    padding: 5px;
  }

  @media (max-width: 1200px) {
    padding: 5px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0;
  }
`;


export const WrapperLogin = styled.div`
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 0 15px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

export const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  text-align: left;

  @media (max-width: 1200px) {
    font-size: 32px;
  }

  @media (max-width: 1024px) {
    font-size: 28px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

export const Description = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #909090;
  margin-bottom: 20px;
  text-align: left;

  @media (max-width: 1200px) {
    font-size: 16px;
    margin-bottom: 18px;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
    margin-bottom: 15px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 10px;
  }
`;

export const WrapperRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 550px;
    min-width: 300px;
    width: 100%;
    height: auto;
  }

  @media (max-width: 1200px) {
    img {
      max-width: 450px;
      min-width: 250px;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const InputTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: left;

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  padding: 8px;
  border: 2px solid #909090;
  border-radius: 10px;
  width: 100%;

  @media (max-width: 1200px) {
    padding: 7px;
  }

  @media (max-width: 1024px) {
    padding: 6px;
  }

  @media (max-width: 768px) {
    padding: 5px;
    border-radius: 8px;
  }
`;

export const Input = styled.input`
  width: 100%;
  font-size: 14px;
  outline: none;
  border: none;

  @media (max-width: 1200px) {
    font-size: 13px;
  }

  @media (max-width: 1024px) {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

export const WrapperImg = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  img {
    width: 160px;
  }

  @media (max-width: 1200px) {
    margin-bottom: 30px;
    img {
      width: 140px;
    }
  }
`;

export const WrapperRecovery = styled.div`
  display: flex;
  justify-content: space-between;
  margin-block: 15px;
  align-items: center;
  flex-wrap: wrap;
`;

export const RecoveryLabel = styled.div`
  color: #1270FC;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;

  @media (max-width: 1024px) {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

export const WrapperInputIcon = styled.div`
  display: flex;
`;
