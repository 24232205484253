export default function formatReaisToFloat(reais: string): number | null {
    // Remove o símbolo de R$ e quaisquer caracteres não numéricos, exceto o ponto decimal
    const cleanedValue = reais.replace(/[^\d,.]/g, "");
  
    // Substitui vírgulas por pontos para obter um número válido em ponto flutuante
    const floatValue = parseFloat(cleanedValue.replace(",", "."));
  
    // Verifica se o resultado é um número válido
    if (!isNaN(floatValue)) {
      return floatValue;
    }
  
    // Se a conversão falhar, retorne null (ou você pode retornar NaN, se preferir)
    return null;
  }
  