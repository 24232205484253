import React, { useState, useCallback } from "react";
import * as S from "./styles";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";

interface IExpandableSectionProps {
  title: string;
  content: JSX.Element;
  icon?: any;
}

const ExpandableSection = React.memo(
  ({ title, content, icon }: IExpandableSectionProps) => {
    const [isExpandable, setIsExpandable] = useState(false);

    const toggleExpand = useCallback(() => {
      setIsExpandable((prev) => !prev);
    }, []);

    return (
      <S.Wrapper>
        <S.OutWrapper onClick={toggleExpand}>
          <S.TitleWrapper>
            <S.TitleTextWrapper>
              {icon && <S.TitleIcon src={icon} />}
              {title}
            </S.TitleTextWrapper>
            {isExpandable ? (
              <MdOutlineArrowDropUp size={30} />
            ) : (
              <MdOutlineArrowDropDown size={30} />
            )}
          </S.TitleWrapper>
        </S.OutWrapper>
        <AnimatePresence>
          {isExpandable && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
              style={{ overflow: "hidden" }}
            >
              <S.PaddingWrapper>
                <S.ContentWrapper>{content}</S.ContentWrapper>
              </S.PaddingWrapper>
            </motion.div>
          )}
        </AnimatePresence>
      </S.Wrapper>
    );
  }
);

export default ExpandableSection;
