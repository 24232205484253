import { useEffect, useMemo, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { IoCalendarOutline, IoCloseOutline, IoEnterSharp } from "react-icons/io5";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { toast } from "react-toastify";
import CustomTableFilter from "../../components/CustomTableFilter";
import PageStructure from "../../components/PageStructure";
import { customStyles } from "../../components/Shared";
import { HeaderPage } from "../../components/Shared/HeaderPage";
import api from "../../service/api";
import PatientProfile from "../Patients/Profile";
import { HeaderTitle, HeaderWrapper } from "../Patients/Profile/style";
import ProfessionalProfile from "../Professionals/Profile";
import {
  ActionsContent,
  ButtonRegister,
  ContactButton,
  DateContact, InfoContent,
  MessageContent,
  PatientName,
  ProfessionalName,
  RegisterContact,
  RegisterContent,
  StatusContent
} from "./style";


const DefaultColumnFilter = ({ column }: any) => {
  const { filterValue, setFilter } = column;
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
      placeholder={`Filter ${column.Header}`}
    />
  );
};

const ColumnFilter = ({ column }: any) => {
  const { filterValue, setFilter } = column;
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
      placeholder={`Filter ${column.Header}`}
    />
  );
};

export const PreConsultation = () => {
  const navigate = useNavigate();
  const [modalPersonDetail, setModalPersonDetail] = useState(false);
  const [modalProfessionalDetail, setModalProfessionalDetail] = useState(false);

  const [data, setData] = useState<any[]>([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [professionalValue, setProfessionalValue] = useState("");
  const [modalRegisterOpen, setRegisterOpen] = useState(false);
  const [consultaSelected, setConsultaSelected] = useState<any>();
  const [message, setMessage] = useState('');

  const fadePersonDetail = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalPersonDetail ? 1 : 0 },
  });

  const fadeProfessionalDetail = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalProfessionalDetail ? 1 : 0 },
  });

  const sendMessage = () => {
    const whatsappURL = `https://wa.me/${consultaSelected?.patient?.phone}/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
    setMessage('');
    closeModal();
  };


  const columns = useMemo(
    () => [
      /*
      {
        Header: "Concluído",
        accessor: 'check',
        Cell: ({ value }: any) => (
          <input
            type="checkbox"
            defaultChecked={value.isChecked}
            onClick={() => {
              const position = data.findIndex((column) => column.id == value.todoId)
              if (position < 0) return

              const localStorageKey = `checkedTodo-${data[position].id}`
              const isChecked = !value.isChecked
              localStorage.setItem(localStorageKey, JSON.stringify({ isChecked }))

              data[position].check.isChecked = isChecked
              setData([...data])
            }}
          />
        ),
      },
      */
      {
        Header: "Concluído",
        accessor: 'check',
        Cell: ({ row }: any) => {
          return <>
            <ActionsContent>
              <FaWhatsapp size={25}
                onClick={() => {
                  setConsultaSelected(row.original)
                  if (!row.original || !row.original.patient || !row.original.patient.phone || row.original.patient.phone == "Não informado") {
                    toast.error("O paciente não possui número para contato.")
                    return
                  }
                  setIsOpen(true)
                }}

              />
              <RegisterContact
                onClick={() => {
                  setConsultaSelected(row.original)
                  setRegisterOpen(true)
                }}

              >
                Registrar
              </RegisterContact>
            </ActionsContent>
          </>
        },
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Data de contato",
        accessor: "date"
      },
      {
        Header: "Paciente",
        accessor: "patientDataColumn",
        Cell: ({ value }: any) => {
          if (!value) return

          const values = value?.split('%');

          const name = values[0]
          const document = values[2]

          const patient = data.find((todo) => {
            return todo.patient?.name == name && todo.patient.document == document
          }).patient

          return (
            <>
              <div
                style={{
                  background: 'none', border: 'none',
                  cursor: 'pointer', textDecoration: 'under_line', color: '#1270FC',
                  textAlign: 'center'
                }}
                onClick={() => {
                  navigate('', { state: { patient: patient, hidesidebar: true } })
                  setModalPersonDetail(true)
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {patient?.name}

                  <span style={{ marginLeft: '3px' }}>
                    <IoEnterSharp size={16} color={'#1270FC'} />
                  </span>
                </div>
              </div>
            </>
          )
        },
      },
      {
        Header: "Objetivo",
        accessor: "objective",
      },
      {
        Header: "Assunto",
        accessor: "subject",
      },
      // {
      //   Header: "Contato",
      //   accessor: "type",
      //   Cell: ({ value }: any) => {
      //     return (
      //       <span>{value}</span>
      //     )
      //   }
      // },
      // {
      //   Header: "Período",
      //   accessor: "period",
      //   Cell: ({ value }: any) => {
      //     if (!value) return;
      //     console.log('aaaaaa', value)

      //     const values = value.split('%')

      //     return (
      //       <span>{values[0]} - {values[1]} Dias</span>
      //     )
      //   },
      // },
      {
        Header: "Responsável",
        accessor: "responsibleDataColumn",
        Cell: ({ value }: any) => {
          if (!value) return

          const values = value?.split('%');

          const name = values[0]
          const document = values[1]

          const professional = data.find((todo) => {
            return todo.professional?.name == name && todo.professional.document == document
          }).professional

          return (
            <>
              <div
                style={{
                  background: 'none', border: 'none',
                  cursor: 'pointer', textDecoration: 'under_line', color: '#1270FC',
                  textAlign: 'center'
                }}
                onClick={() => {
                  navigate('', { state: { professional: professional, hidesidebar: true } })
                  setModalProfessionalDetail(true)
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {professional?.name}

                  <span style={{ marginLeft: '3px' }}>
                    <IoEnterSharp size={16} color={'#1270FC'} />
                  </span>
                </div>
              </div>
            </>
          )
        },
      },
      {
        Header: "Profissional",
        accessor: "professionalDataColumn",
        Cell: ({ value }: any) => {
          if (!value) return

          const values = value?.split('%');

          const name = values[0]
          const document = values[1]

          const professional = data.find((todo) => {
            return todo.professional?.name == name && todo.professional.document == document
          }).professional

          return (
            <>
              <div
                style={{
                  background: 'none', border: 'none',
                  cursor: 'pointer', textDecoration: 'under_line', color: '#1270FC',
                  textAlign: 'center'
                }}
                onClick={() => {
                  navigate('', { state: { professional: professional, hidesidebar: true } })
                  setModalProfessionalDetail(true)
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {professional?.name}

                  <span style={{ marginLeft: '3px' }}>
                    <IoEnterSharp size={16} color={'#1270FC'} />
                  </span>
                </div>
              </div>
            </>
          )
        },
      },
    ],
    [data]
  );

  const defaultColumn = useMemo(
    () => ({
      // Define o filtro padrão para todas as colunas
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna mês de 0 a 11
    const year = date.getFullYear();

    const weekDays = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];

    const weekDayName = weekDays[date.getDay()];

    return `${day}/${month}/${year} - ${weekDayName}`;
  }

  const fetchPostConsultation = () => {
    let toastLoading = toast.loading("Carregando lista de atividades...", {
      className: "toast-loading",
    });

    api
      .get(`/todo/?contact_time=pre&answer_isnull=true`)
      .then(async (response) => {
        const arrayTodo = response.data as any[];

        const promises = arrayTodo.map(async (todo, index) => {
          let patient = todo.patient;
          let professional = todo.professional;

          const responsible = professional;
          const localStorageKey = `checkedTodo-${todo.id}`
          const localStorageValue = localStorage.getItem(localStorageKey)

          return {
            ...todo,
            patient,
            patientDataColumn: `${patient?.name}%${patient.last_name}%${patient.document}%${patient.phone}%${patient.email}`,
            professional,
            professionalDataColumn: `${professional?.name}%${professional.document}%${professional.phone}%${professional.email}%${professional.n_conselho}`,
            responsible,
            responsibleDataColumn: `${responsible?.name}%${responsible.document}%${responsible.phone}%${responsible.email}%${responsible.n_conselho}`,
            date: formatDate(todo.date),
            original_date: new Date(todo.date),
            check: {
              todoId: todo.id,
              isChecked: localStorageValue ? JSON.parse(localStorageValue).isChecked : false
            }
          }
        })

        const arrayTodoMapped = await Promise.all(promises);
        arrayTodoMapped.sort((a, b) => a.original_date - b.original_date);
        setData([...arrayTodoMapped]);
      })
      .catch((error) => {
        toast.error("Erro ao carregar pós consulta!");
        console.log('erro', error)
      })
      .finally(() => {
        toast.dismiss(toastLoading);
      });
  };

  useEffect(() => {
    fetchPostConsultation();
  }, []);

  const closeModal = () => {
    setModalPersonDetail(false);
    setModalProfessionalDetail(false);
    setIsOpen(false);
    setRegisterOpen(false)
  };

  const handleRegisterButtons = (value: string) => {
    const toatLoading = toast.loading("Atualizando status...)");
    api.patch(`/todo/${consultaSelected?.id}/`, { answer: value }).then((resp) => {
      toast.dismiss(toatLoading);
      toast.success("Registrado com sucesso!");
      fetchPostConsultation();
      closeModal();
    }).catch((error) => {
      toast.error("Erro ao atualizar status!");
      console.log('erro', error)
    }).finally(() => {
      toast.dismiss(toatLoading);
    });
  }

  return (<>
    <Modal
      isOpen={modalPersonDetail}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      style={{
        ...customStyles, content: {
          ...customStyles.content,
          width: '80%'
        }
      }}
    >
      <animated.div style={fadePersonDetail}>
        <HeaderWrapper>
          <HeaderTitle>Paciente</HeaderTitle>
          <IoCloseOutline
            size={22}
            onClick={closeModal}
            color="#919EAB"
            cursor={"pointer"}
          />
        </HeaderWrapper>
        <PatientProfile />
      </animated.div>
    </Modal>
    <Modal
      isOpen={modalProfessionalDetail}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      style={{
        ...customStyles, content: {
          ...customStyles.content,
          width: '80%'
        }
      }}
    >
      <animated.div style={fadeProfessionalDetail}>
        <HeaderWrapper>
          <HeaderTitle>Profissional</HeaderTitle>
          <IoCloseOutline
            size={22}
            onClick={closeModal}
            color="#919EAB"
            cursor={"pointer"}
          />
        </HeaderWrapper>
        <ProfessionalProfile />
      </animated.div>
    </Modal>
    <PageStructure overflow={'auto'}>
      <HeaderPage
        title="Pré Consulta"
      // buttonRight={
      //     // <CustomButton
      //     //     onClick={() => setIsOpen(true)}
      //     //     theme="addPrimary"
      //     // >
      //     //     Registrar Profissional
      //     // </CustomButton>
      // }
      />
      <div
        style={{ overflow: 'auto', paddingTop: '15px' }}
      >
        <CustomTableFilter
          columns={columns}
          data={data}
        />
      </div>
      {/* <Pagination
      totalPages={1}
      onPageChange={(pageNumber) => {
        
      }}
    /> */}
    </PageStructure>


    {/*////////////  MODAL PARA ENTRAR EM CONTATO //////////*/}

    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      style={{
        ...customStyles, content: {
          ...customStyles.content,
          width: '40%'
        }
      }}
    >
      <HeaderWrapper>
        <HeaderTitle>Entrar em contato</HeaderTitle>
        <IoCloseOutline
          size={22}
          onClick={closeModal}
          color="#919EAB"
          cursor={"pointer"}
        />
      </HeaderWrapper>
      <InfoContent>
        <PatientName>Paciente: {consultaSelected?.patient?.name}</PatientName>
        <DateContact><IoCalendarOutline size={16} />{consultaSelected?.date}</DateContact>
      </InfoContent>
      <ProfessionalName>Profissional: {consultaSelected?.professional?.name}</ProfessionalName>
      <MessageContent>
        <textarea className="input-message"
          rows={10}
          placeholder="Mensagem"
          value={message} onChange={(e) => setMessage(e.target.value)}
        >

        </textarea>
        <ContactButton
          onClick={sendMessage}
        >
          Entrar em contato
        </ContactButton>
      </MessageContent>
    </Modal>


    {/*////////////  MODAL PARA REGISTRAR //////////*/}

    <Modal
      isOpen={modalRegisterOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      style={{
        ...customStyles, content: {
          ...customStyles.content,
          width: '40%'
        }
      }}
    >
      <HeaderWrapper>
        <HeaderTitle>Registrar</HeaderTitle>
        <IoCloseOutline
          size={22}
          onClick={closeModal}
          color="#919EAB"
          cursor={"pointer"}
        />
      </HeaderWrapper>
      <RegisterContent>
        <StatusContent>
          <PatientName>Paciente: {consultaSelected?.patient?.name}</PatientName>
          <ButtonRegister onClick={() => handleRegisterButtons('Confirmado')} style={{ backgroundColor: "#10c242" }}>
            Confirmado
          </ButtonRegister>
          <ButtonRegister onClick={() => handleRegisterButtons('Sem resposta')} style={{ backgroundColor: "#575751", color: "#ffffff" }}>
            Sem resposta
          </ButtonRegister>
          <ButtonRegister onClick={() => handleRegisterButtons('Outros')}>
            Outros
          </ButtonRegister>
        </StatusContent>
      </RegisterContent>






    </Modal>
  </>
  );
};

export default PreConsultation;
