import { useLocation, useNavigate } from 'react-router-dom';
import { HorizontalWrapper, BoxWrapper, Logowrapper, MenuOptionsWrapper, MenuOptionWrapper, SideMenuWrapper } from "./style";
import BridgesLogo from "../../../assets/bridgesLogo.png";

import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import cardMenu from "../../../assets/card-side-menu.png";
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';

const menuOptions = [
    {
        icon: <CalendarViewMonthIcon />,
        text: "Consultas do dia",
        path: "/today",
        roles: ["gestor", "profissional", "recepcionista", "atendimento"]
    },
    {
        icon: <CalendarMonthIcon />,
        text: "Agenda",
        path: "/",
        roles: ["gestor", "profissional", "financeiro", "atendimento"]
    },
    {
        icon: <AccountBalanceWalletOutlinedIcon />,
        text: "Carteira",
        path: "/wallet",
        roles: ["gestor", "recepcionista", "financeiro"]
    },
    {
        icon: <PeopleOutlinedIcon />,
        text: "Profissionais",
        path: "/professionals",
        roles: ["gestor"]
    },
    {
        icon: <AccountCircleSharpIcon />,
        text: "Pacientes",
        path: "/patients",
        roles: ["gestor", "profissional", "atendimento"]
    },
    {
        icon: <ReplayRoundedIcon />,
        text: "Pré Consultas",
        path: "/preConsultation",
        roles: ["gestor", "atendimento"]
    },
    {
        icon: <AssessmentOutlinedIcon />,
        text: "Relatórios",
        path: "/control",
        roles: ["gestor"]
    },
];

const configureItems = [
    {
        icon: <SettingsIcon />,
        text: "Configurações",
        path: "/settings"
    },
    {
        icon: <HelpOutlineIcon />,
        text: "Ajuda e Suporte",
        path: "/support"
    },
];

const logout = () => {
    window.location.href = "/login";
    localStorage.removeItem("bridges.token");
};

export const SideMenu = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState<string>();

    const isActive = (path: string) => location.pathname === path;

    useEffect(() => {
        const token = localStorage.getItem("bridges.token");
        if (token) {
            const tokenData = jwtDecode(token) as any;
            setUserRole(tokenData.groups[0] || "");
        }
    }, []);

    const filteredMenuOptions = menuOptions.filter(option =>
        option.roles.includes(userRole || "")
    );

    return (
        <SideMenuWrapper>
            <Logowrapper>
                <img src={BridgesLogo} alt="Bridges Logo" />
            </Logowrapper>
            <MenuOptionsWrapper>
                {filteredMenuOptions.map((option, index) => (
                    <MenuOptionWrapper key={index} active={isActive(option.path)} onClick={() => navigate(option.path)}>
                        {option.icon}
                        <span>{option.text}</span>
                    </MenuOptionWrapper>
                ))}
            </MenuOptionsWrapper>
            <HorizontalWrapper />
            <MenuOptionsWrapper>
                {configureItems.map((option, index) => (
                    <MenuOptionWrapper key={index} active={isActive(option.path)}>
                        {option.icon}
                        <span>{option.text}</span>
                    </MenuOptionWrapper>
                ))}
            </MenuOptionsWrapper>
            <BoxWrapper>
                <img src={cardMenu} alt="Login" />
            </BoxWrapper>
            <MenuOptionWrapper theme="cancel" onClick={logout}>
                <LogoutIcon />
                <span>Sair</span>
            </MenuOptionWrapper>
        </SideMenuWrapper>
    );
};