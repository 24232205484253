import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
`;

interface InputWrapperProps {
    disabled?: boolean;
    disableBorder?: boolean;
    isTypeFile?: boolean;
}

export const InputWrapper = styled.div<InputWrapperProps>`
    display: flex;
    height: 54px;
    padding: ${props => props.isTypeFile ? '0' : '0px 14px'};
    align-items: center;
    gap: 8px;
    background-color: ${props => props.disabled ? '#F0F0F0' : '#fff'};
    cursor: ${props => props.disabled ? 'not-allowed' : 'auto'};
    border: ${props => props.disableBorder ? 'none' : '1px solid #919EAB33'};
    border-radius: 8px;
    align-self: stretch;
    width: 100%;
`

interface InputProps{
    typeStyle?: string;
}

export const Input = styled.input<InputProps>`
    border: none;
    background-color: #fff;
    display: ${props => props.typeStyle == 'file' ? 'none' : 'block'};
    ${props => props.typeStyle == 'checkbox' ? '' : 'width: 100%;'}

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: #F0F0F0;
    }
    
`

export const Label = styled.label`
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
`;

export const CheckBoxLabel = styled.label`
    display: flex;
    gap: 8px;
    border-radius: 5px;
    cursor: pointer;
`


export const ShowFileNameSpan = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 290px;
`

export const WrapperInputTitle = styled.div`
    display: flex;

    span{
        display: flex;
    }
`

export const AlertIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 16px;
    height: 16px;
    top: -2px;
    margin-left: 1rem;
    border-radius: 50%;
    background-color: #FFB84D;
    cursor: pointer;
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    transition: all 0.3s;
    animation: ${pulseAnimation} .3s ease-in-out infinite;

    &:hover {
        background-color: #FFCC99;
    }
`