import styled from "styled-components";

interface MultiOptionButtonListProps {
  isMarked: boolean;
  disabled?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

export const ButtonWrapper = styled.div<MultiOptionButtonListProps>`
  border: 1px solid
    ${(option) => (option.disabled ? "#d9d9d9" : "rgb(18, 112, 252)")};
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  color: ${(element) => (element.isMarked ? "white" : "gray")};
  background-color: ${(option) =>
    option.isMarked && option.disabled
      ? "#cccccc"
      : option.isMarked
      ? "rgb(18, 112, 252)"
      : "none"};
`;

export const TitleButton = styled.span``;

export const Title = styled.span``;

export const TitleWrapper = styled.div`
  color: #637381;
  font-weight: 500;
`;

export const ButtonWrapperList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`;
