import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
`

interface CircleProps {
  disabled?: boolean
}

export const Circle = styled.div<CircleProps>` 
  cursor: ${(props) => props.disabled ? 'auto' : 'pointer'};
  display: inline-flex; /* Para permitir ajustar o tamanho do ícone */
  align-items: center;
  justify-content: center;
  width: 64px; /* Ajuste conforme necessário para o tamanho desejado do círculo */
  height: 48px; /* Igual ao width para garantir um círculo */
  border-radius: 49px; /* Bordas arredondadas para criar um círculo */
  border: 1px solid ${(props) => props.disabled ? 'var(--disabled-icon-color)' : 'var(--primary-icon-color)'}; /* Cor e largura da borda */
`