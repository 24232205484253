import { IoCloseOutline } from "react-icons/io5";
import CustomButton from "../../../components/CustomButton";
import { ContentWrapper, FooterWrapper, HeaderTitle, HeaderWrapper, InfoWrapper, ModalWrapper, ObservationsWrapper, SeparatorBottom } from "./style";

interface ConfirmProps {
    closeModal: () => void;
    onConfirmAction: () => void;
}

export const Confirm = (props: ConfirmProps) => {
    return (
        <>
        <ModalWrapper size="small">
            <HeaderWrapper>
                <HeaderTitle>Atençao</HeaderTitle>
                <IoCloseOutline size={22} onClick={props.closeModal} color="#919EAB" cursor={"pointer"} />
            </HeaderWrapper>
            <ContentWrapper>
                <InfoWrapper>
                    <ObservationsWrapper>
                        Você tem certeza que deseja desmarcar este compromisso?

                        <SeparatorBottom />

                        Está é uma ação irreversível, atente-se.
                    </ObservationsWrapper>
                </InfoWrapper>
            </ContentWrapper>
            <FooterWrapper>
                <CustomButton theme="cancelDeleteAppointment" onClick={props.closeModal}>Cancelar</CustomButton>
                <CustomButton theme="deleteAppointment" onClick={props.onConfirmAction}>Tenho certeza, quero desmarcar.</CustomButton>
            </FooterWrapper>
        </ModalWrapper>
        </>
    );
}

export default Confirm;