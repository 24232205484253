export const PatologyList = [
  {
    title: "Ansiedade",
    value: "ansiedade",
  },
  {
    title: "Câncer",
    value: "cancer",
  },
  {
    title: "Circulatório",
    value: "circulatorio",
  },
  {
    title: "Colite",
    value: "colite",
  },
  {
    title: "Depressão",
    value: "depressao",
  },
  {
    title: "Diabetes",
    value: "diabetes",
  },
  {
    title: "Dislipidemia",
    value: "dislipidemia",
  },
  {
    title: "Dor de cabeça",
    value: "dor de cabeca",
  },
  {
    title: "Endócrino",
    value: "endocrino",
  },
  {
    title: "Gastrite",
    value: "gastrite",
  },
  {
    title: "Irritabilidade",
    value: "irritabilidade",
  },
  {
    title: "Herpes",
    value: "herpes",
  },
  {
    title: "Hepatite",
    value: "hepatite",
  },
  {
    title: "Hipertireoidismo",
    value: "hipertireoidismo",
  },
  {
    title: "Hipotireoidismo",
    value: "hipotireoidismo",
  },
  {
    title: "Hipoglicemia",
    value: "hipoglicemia",
  },
  {
    title: "Hipertensão",
    value: "hipertensão",
  },
  {
    title: "Osteoporose",
    value: "osteoporose",
  },
  {
    title: "Renal",
    value: "renal",
  },
  {
    title: "Rinite/Sinute",
    value: "rinitesinusite",
  },
  {
    title: "RGE",
    value: "rge",
  },
];
