const loadingContainer = {
  width: "2rem",
  display: "flex",
  justifyContent: "space-around"
};

const loadingCircle = {
  display: "block",
  width: "0.4rem",
  height: "0.4rem",
  backgroundColor: "#333333",
  borderRadius: "0.2rem"
};

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.2
    }
  },
  end: {
    transition: {
      staggerChildren: 0.2
    }
  }
};

const loadingCircleVariants = {
  start: {
    y: "0%"
  },
  end: {
    y: "100%"
  }
};

const loadingCircleTransition = {
  duration: 0.5,
  repeat: Infinity,
  repeatType: "reverse",
  ease: "easeInOut"
} as any;

export default {
  loadingCircleTransition,
  loadingCircleVariants,
  loadingContainerVariants,
  loadingCircle,
  loadingContainer
}