import styled from 'styled-components';

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioOption = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
`;

const Label = styled.span`
  margin-bottom: 15px;
`;

const RadioInput = styled.input`
  display: none;
  cursor: pointer;

`;

const CustomRadio = styled.span<{checked: boolean}>`
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 50%;
  border: 2px solid #8798A1;
  margin-right: 8px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;

  &:after {
    content: '';
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background-color: #3498db;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(${(props) => (props.checked ? '1' : '0')});
    transition: transform 0.3s ease-in-out;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const RadioLabel = styled.label<{ checked: boolean }>`
  color: ${(props) => (props.checked ? 'inherit' : 'inherit')};
  font-weight: ${(props) => (props.checked ? 'normal' : 'normal')};
  cursor: pointer;
`;

export const comp = {
  RadioContainer,
  Label,
  RadioOption,
  RadioInput,
  RadioLabel,
  CustomRadio
}
