import styled from 'styled-components';

export const WrapperPage = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    justify-content: center;

    @media (max-width: 1200px) {
        padding: 15px;
    }

    @media (max-width: 1024px) {
        padding: 10px;
    }

    @media (max-width: 768px) {
        padding: 5px;
    }
`;

export const WrapperHeader = styled.div`
    display: flex;
    justify-content: space-between;
    height: 20%;
`;

export const WrapperDetail = styled.div``;

export const Detail = styled.img``;

export const WrapperBottom = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    height: 20%;
`;

export const Title = styled.div`
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 40px;

    @media (max-width: 1200px) {
        font-size: 32px;
    }

    @media (max-width: 1024px) {
        font-size: 28px;
    }

    @media (max-width: 768px) {
        font-size: 24px;
    }

    @media (min-width: 1920px) {
        font-size: 48px;
    }
`;

export const InputWrapper = styled.div`
    position: relative;
    padding: 14px;
    border: 2px solid #909090;
    border-radius: 20px;
    margin-bottom: 22px;

    @media (max-width: 768px) {
        padding: 12px;
        border-radius: 15px;
    }

    @media (min-width: 1920px) {
        padding: 18px;
    }
`;

export const Input = styled.input`
    width: 100%;
    font-size: 15px;
    outline: none;
    border: none;

    @media (max-width: 768px) {
        font-size: 14px;
    }

    @media (min-width: 1920px) {
        font-size: 18px;
    }
`;

export const InputTitle = styled.div`
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 15px;

    @media (max-width: 1200px) {
        font-size: 24px;
    }

    @media (max-width: 768px) {
        font-size: 20px;
    }

    @media (min-width: 1920px) {
        font-size: 32px;
    }
`;

export const WrapperInputIcon = styled.div`
    display: flex;
`;

export const InputIcon = styled.div`
    cursor: pointer;
    font-size: 20px;
`;

export const WrapperImg = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 100px;

    img {
        width: 205px;

        @media (max-width: 768px) {
            width: 180px;
        }

        @media (min-width: 1920px) {
            width: 240px;
        }
    }
`;

export const WrapperRecovery = styled.div`
    display: flex;
    justify-content: end;
    margin-block: 35px;
    align-items: center;
`;

export const RecoveryLabel = styled.div`
    color: #1270fc;
    font-size: 16px;
    font-weight: 800;
    text-align: right;
    width: 100%;
    cursor: pointer;

    @media (max-width: 768px) {
        font-size: 14px;
    }

    @media (min-width: 1920px) {
        font-size: 18px;
    }
`;

export const Description = styled.div`
    font-size: 24px;
    font-weight: 400;
    color: #909090;
    margin-bottom: 45px;

    @media (max-width: 1200px) {
        font-size: 22px;
        margin-bottom: 40px;
    }

    @media (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 35px;
    }

    @media (min-width: 1920px) {
        font-size: 28px;
    }
`;

export const WrapperLogin = styled.div`
    max-width: 582px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 1920px) {
        max-width: 700px;
    }

    @media (max-width: 1080px) {
        padding-top: 100px;
    }
`;

export const WrapperMid = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 20px;

    @media (max-width: 1024px) {
        flex-direction: column;
        padding: 20px;
        align-items: center;
    }

    @media (min-width: 1920px) {
        padding: 0 100px;
    }
`;

export const WrapperRight = styled.div`
    display: flex;
    flex-direction: column;

    img {
        width: 100%;
        height: auto;
        max-width: 550px;

        @media (max-width: 1024px) {
            display: none;
        }

        @media (min-width: 1920px) {
            max-width: 650px;
        }
    }
`;
