import styled from "styled-components";

interface ButtonOptionListProps {
  isMarked: boolean;
  disabled?: boolean;
}

export const Wrapper = styled.div``;

export const OptionsWrapper = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 5px;
`;

export const Button = styled.div<ButtonOptionListProps>`
  border: 1px solid
    ${(option) => (option.disabled ? "#d9d9d9" : "rgb(18, 112, 252)")};
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  color: ${(options) => (options.isMarked ? "white" : "#bfbfbf")};
  transition: background-color 0.1s ease-in-out;
  background-color: ${(option) =>
    option.isMarked && option.disabled
      ? "#cccccc"
      : option.isMarked
      ? "rgb(18, 112, 252)"
      : "none"};
`;

export const TitleWrapper = styled.div`
  color: #637381;
  font-weight: 500;
  font-style: normal;
`;

export const TitleText = styled.span``;
