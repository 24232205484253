import styled from "styled-components";

export const ConfigHoursWrapper = styled.div`
  display: flex;
  flex-direction: column;
  div:first-child {
    margin-right: 5px;
  }
  border: 1px solid #f3f3f3;
  border-radius: 12px;
  padding: 10px;
  width: 40%;
`

export const HeaderTitle = styled.span`
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding: 0 0 3px 0;
    margin: 0 -10px;
    border-bottom: 1px solid #f3f3f3;
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const ConfigHoursContainer = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`

export const WeekendContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  margin-bottom: 5px;
`

export const WeekContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
`

export const CustomInputWrapper = styled.div`
  display: flex;
  padding: 5px;
`;

export const WrapperInput = styled.div`
    display: flex;
    gap: 10px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-height: 500px;
    gap: 16px;
    width: 100%;
    padding: 24px 0;
`


export const ProfileImageWrapper = styled.img`
    border-radius: 50%;
    width: 186px;
    height: 186px;
    overflow: hidden;
    /* border: 2px solid white; */
    /* box-shadow: 0px 0px 4px 5px rgba(0,0,0,0.1);     */
    img {
        object-fit: cover;
    }
`