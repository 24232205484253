import { useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import CustomLabel from "../../../../components/CustomLabel";
import { Tag } from "../../../../components/Tags";
import {
  AddressWrapper,
  AppointmentWrapper,
  FlexWrapper,
  ContentWrapper,
  HeaderTitle,
  HeaderWrapper,
  InfoWrapper,
  Separator,
  SeparatorBottom,
} from "../../../Event/Create/style";
import { Container } from "./style";
import {
  calcularDiferencaEmMinutos,
  formatIsoToDate,
  formatarNumeroTelefone,
  getTimeFromIsoDate,
} from "../../../../service/utils.service";
import { Circle } from "../../style";
import { FaWhatsapp } from "react-icons/fa";

interface ShowEventTabProps {
  closeModal: () => void;
  event: any;
}

const ShowEventTab = (props: ShowEventTabProps) => {
  const [form, setForm] = useState<any>({});
  const [sendingMessage, setSendingMessage] = useState(false);

  const preprocessBrazilianPhoneNumber = (phoneNumber: string): string => {
    const cleanedNumber = phoneNumber.replace(/\D/g, '');
    
    if (cleanedNumber.startsWith('55')) {
        return '+' + cleanedNumber;
    } else {
        return '+55' + cleanedNumber;
    }
}

  const sendMessage = async (extendedProps: any) => {
    if(sendingMessage) return

    setSendingMessage(true)

    const messageToSendToWhatsapp = `
      Oi! Para te conhecermos melhor, que tal preencher nosso formulário rapidinho?
    
      Clique no link:
      
      ${`${window.location.origin}/FirstConsultation/${extendedProps.preform.id}`}
      
      Estamos ansiosos para te ver!
    `;

    const patientNumber = preprocessBrazilianPhoneNumber(extendedProps.patient.phone)

    const whatsappURL = `https://wa.me/${extendedProps.patient.phone}/?text=${encodeURIComponent(
      messageToSendToWhatsapp
    )}`;

    window.open(whatsappURL, '_blank');
    
    setSendingMessage(false)
  }

  return (
    <>
      <HeaderWrapper>
        <HeaderTitle>Compromisso</HeaderTitle>
        <IoCloseOutline
          size={22}
          onClick={props.closeModal}
          color="#919EAB"
          cursor={"pointer"}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <InfoWrapper>
          <AppointmentWrapper>
            <CustomLabel
              label="Data"
              info={formatIsoToDate(props.event?.start)}
            />
            <CustomLabel
              label="Hora"
              info={`${getTimeFromIsoDate(props.event?.start)}h`}
            />
            <CustomLabel
              label="Duração"
              info={`${calcularDiferencaEmMinutos(
                new Date(props.event?.start).toISOString(),
                new Date(props.event?.end || props.event?.extendedProps?.end_time).toISOString()
              ).toString()} min`}
            />
            <CustomLabel
              label="Serviço"
              info={props.event?.extendedProps?.service?.name}
            />
            <CustomLabel label="Status Pagamento">
              <Tag
                type={
                  props.event?.extendedProps?.status?.name == "Pago"
                    ? "paid"
                    : "pending"
                }
                text={
                  props.event?.extendedProps?.status?.name == "Pago"
                    ? "Pago"
                    : "Em aberto"
                }
              />
            </CustomLabel>
          </AppointmentWrapper>
          <AddressWrapper>
            <CustomLabel
              label="Local"
              info={props.event?.extendedProps?.establishment?.alias}
            />
            <CustomLabel
              label="Observação"
              info={props.event?.extendedProps?.observation}
            />
          </AddressWrapper>
          <SeparatorBottom />
          <HeaderTitle>Envolvidos</HeaderTitle>
          <FlexWrapper>
            <Container>
              <InfoWrapper>
                <CustomLabel
                  label="Profissional"
                  info={props.event?.extendedProps?.professional?.name}
                />
                <CustomLabel
                  label="E-mail"
                  info={props.event?.extendedProps?.professional?.email}
                />
                <CustomLabel
                  label="Telefone"
                  info={formatarNumeroTelefone(
                    props.event?.extendedProps?.professional?.phone
                  )}
                />
              </InfoWrapper>
              {/* <Circle>
                    <BsSend color="var(--primary-icon-color)" size={20} />
                </Circle> */}
            </Container>
            <Separator />
            <Container>
              <InfoWrapper>
                <CustomLabel
                  label="Paciente"
                  info={props.event?.extendedProps?.patient?.name}
                />
                <CustomLabel
                  label="E-mail"
                  info={props.event?.extendedProps?.patient?.email}
                />
                <CustomLabel
                  label="Telefone"
                  info={formatarNumeroTelefone(
                    props.event?.extendedProps?.patient?.phone
                  )}
                />
              </InfoWrapper>
              <Circle 
                disabled={!(props.event?.extendedProps?.preform?.id)}
                onClick={() => {if(props.event?.extendedProps?.preform) sendMessage(props.event?.extendedProps)}}
              >
                <FaWhatsapp 
                  color={`var(${props.event?.extendedProps?.preform?.id ? '--primary-icon-color' : '--disabled-icon-color'})`} 
                  size={20}
                />
              </Circle>
            </Container>
          </FlexWrapper>
        </InfoWrapper>
        {/* <Separator />
    <AttachmentWrapper>
        <AttachmentHeader>
            <AttachmentTitle>
                Anexos
                <CustomButton theme="add">Adicionar</CustomButton>
            </AttachmentTitle>
        </AttachmentHeader>
        <AttachmentList>
            <EmptyAttachment>
                <RiAttachmentLine size={30} color="#919EAB" />
                <EmptyAttachamentText>Ainda não há arquivos</EmptyAttachamentText>
            </EmptyAttachment>
        </AttachmentList>
    </AttachmentWrapper> */}
      </ContentWrapper>
    </>
  );
};

export default ShowEventTab;
