import { jwtDecode } from 'jwt-decode';
import { AdminRoutes } from "./routes/AdminRoutes";
import { FinanceiroRoutes } from './routes/FinanceiroRoutes';
import { ProfessionalRoutes } from "./routes/ProfessionalRoutes";
import { PublicRoutes } from "./routes/PublicRoutes";
import { RecepcionistRoutes } from "./routes/ReceptionistRoutes";
import { ServiceRoutes } from './routes/ServiceRoutes';

export const HandleRoutes = () => {
  let logged = localStorage.getItem("bridges.token");
  let group;

  if (logged) {
    const tokenData = jwtDecode(logged) as any;
    group = tokenData.groups[0];
  }

  if (logged === undefined) {
    return null;
  }

  if (group == "gestor") 
    return <AdminRoutes />;

  if(group == "profissional")
    return <ProfessionalRoutes />

  if(group == "recepcionista")
    return <RecepcionistRoutes />

  if (group == "financeiro") 
    return <FinanceiroRoutes />;

  if (group == "atendimento") 
    return <ServiceRoutes />;
  
  return <PublicRoutes />;
};
