import { motion } from "framer-motion";
import animation from "./style"

export default function ThreeDotsWave() {
  return (
    <motion.div
      style={animation.loadingContainer}
      variants={animation.loadingContainerVariants}
      initial="start"
      animate="end"
    >
      <motion.span
        style={animation.loadingCircle}
        variants={animation.loadingCircleVariants}
        transition={animation.loadingCircleTransition}
      />
      <motion.span
        style={animation.loadingCircle}
        variants={animation.loadingCircleVariants}
        transition={animation.loadingCircleTransition}
      />
      <motion.span
        style={animation.loadingCircle}
        variants={animation.loadingCircleVariants}
        transition={animation.loadingCircleTransition}
      />
    </motion.div>
  );
}
