import styled from "styled-components";

interface ModalWrapperProps {
    size?: 'default' | 'large' | 'small';
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
    width: ${props => {
        switch (props.size) {
            case 'default':
                return '900px';
            case 'small':
                return '460px';
            default:
                return '1000px';
        }
    }};
    transition: all 0.3s ease-in-out;
`

interface TabProps {
    tabActive: boolean;
}

export const Tab = styled.div<TabProps>`
    position: relative; 
    bottom: 42px;
    border-top: 1px solid rgb(204, 204, 204);
    border-left: 1px solid rgb(204, 204, 204);
    border-right: 1px solid rgb(204, 204, 204);
    background: ${props => props.tabActive ? '#fff' : '#E3E3E3'};
    padding: 4px 12px;
    display: inline;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-right: 10px;
    cursor: pointer;
    :hover {
        cursor: pointer;
    }
    transition: all 0.3s ease-in-out;
`