import { ReactNode } from "react";
import { ContentWrapper, PageWrapper, RightContentWrapper } from "./style";
import { SideMenu } from "../v2/SideMenu";
import TopMenu from "../v2/HeaderMenu"; // Importando o TopMenu

interface PageStructureProps {
  title?: string;
  children: ReactNode;
  rightContent?: ReactNode;
  hidesidebar?: boolean;
  overflow?: string;
  topMenu?: ReactNode; // Propriedade adicional para TopMenu
}

export const PageStructure = ({
  title,
  children,
  rightContent,
  hidesidebar,
  overflow,
  topMenu
}: PageStructureProps) => {
  return (
    <PageWrapper hidesidebar={hidesidebar}>
      {!hidesidebar && <SideMenu />}
      
      {/* Renderiza o TopMenu, se fornecido */}
      {/* {!topMenu && <TopMenu />} */}

      <ContentWrapper hidesidebar={hidesidebar} style={{ overflow }}>
        {title && <h1>{title}</h1>}
        {children}
      </ContentWrapper>
      
      {rightContent && (
        <RightContentWrapper>{rightContent}</RightContentWrapper>
      )}
    </PageWrapper>
  );
};

export default PageStructure;
