import styled from 'styled-components';

export const Content = styled.div`
`

export const SubTitle = styled.div`
    font-size: 20px;
    font-weight: bold;
`

export const RefreshLabel = styled.div`
    color: #919EAB;
    font-size: 13px;
    margin-top: 5px;
`;

export const CenterWrapper = styled.div`
    height: 100%;
    margin-top: 40px;
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const LeftWrapper = styled.div`
    flex: 3;
    margin-right: 20px;
    width: 100%;
`;

export const RightWrapper = styled.div`
`;

export const SectionTitle = styled.div`
    font-size: 20px;
    font-weight: bold;
`;

export const OccupationsWrapper = styled.div`
    margin-top: 8px;
    margin-right: 5px;
    flex-direction: column;
    display: flex;
    gap: 5px;
    overflow: auto;
    height: 100%;
    &::-webkit-scrollbar {
    width: 0; /* Largura da barra de rolagem */
  }
`;

export const RadialWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
`;

export const LastServicesWrapper = styled.div`
    max-width: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ResumeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`

interface ButtonChartProps {
    isChecked: boolean;
}

export const ValuesContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

export const SideHistoryContent = styled.div`
    margin-left: 20px;
    padding-right: 10px;
    flex: 1;
`;

export const PageContent = styled.div`
    display: flex;
    flex-direction: row;
    height: calc(100% - 156px);
    margin-top: 15px;
`;


export const MovimentListWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 20px);
    overflow: auto;
    gap: 10px;
    padding-right: 10px;

`;

export const DateTitle = styled.div`
    text-align: center;
    color: #919EAB;
    font-size: 13px;
    margin-bottom: 5px;
`;

export const Button = styled.div`
    background-color: #1270FC;
    background-position: center;
    color: #FFFFFF;
    font-size: 12px;
    height: 35px;
    width: 250px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-right: 4px;
    cursor: pointer;
    gap: 4px;
    transition: background 0.4s;
    &:hover {
        background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
    }
    &:active {
        background-color: #6eb9f7;
        background-size: 100%;
        transition: background 0s;
    }
`;

export const SubTitleInfosWrapper = styled.div`  
`;

export const SubHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const TextButton = styled.div`
`;

export const IconButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const customStyles = {
    overlay: {
        zIndex: 9000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        overflow: 'none',
        transform: 'translate(-50%, -50%)',
        borderRadius: 16,
        transition: 'all 0.3s ease',
    },
};

export const DataWrapper = styled.div` 
    display: flex;
    flex-direction: column;
    overflow-x: auto;
`;

export const ChartWrapper = styled.div`
    margin-top: 20px;
`;

export const ButtonChart = styled.div<ButtonChartProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.isChecked ? "#1270FC" : ""};
    color: ${(props) => props.isChecked ? "#FFF" : ""};
    height: 100%;
    cursor: pointer;
    flex: 1;
    padding: 10px;
`;

export const ButtonChartWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border:1px solid #919EAB52;
    height: 30px;
`;

export const ScrollableHistory = styled.div`
    overflow: auto;
`;

export const DataHeaderWrapper = styled.div`
    gap: 10px;
    display: flex;
    flex-direction: column;
    flex: 2;
`;

export const HeaderData = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;

export const LeftContentHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 2;
    margin-right: 40px;
    align-items: center;
`;

export const RightContentHeader = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: end;
`