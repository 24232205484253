export function formatPhone(phone: string) {
    let phoneCleared = phone.replace(/\D/g, '');

    let phoneClearedFormatted = '';

    if (phoneCleared.length > 0) 
        phoneClearedFormatted += '(' + phoneCleared.substring(0, 2);
    
    if (phoneCleared.length > 2) 
        phoneClearedFormatted += ') ' + phoneCleared.substring(2, 3);
    
    if (phoneCleared.length > 3) 
        phoneClearedFormatted += ' ' + phoneCleared.substring(3, 7);
    
    if (phoneCleared.length > 7) 
        phoneClearedFormatted += '-' + phoneCleared.substring(7, 11);
    
    return phoneClearedFormatted;
}
