import {
  AppoimentTitle,
  AppointmentHeader,
  AvatarImage,
  BottomWrapper,
  CenterWrapper,
  DateLabel,
  DateWrapper,
  Description,
  DollarIcon,
  HourLabel,
  HourWrapper,
  Icon,
  PatientName,
  ProfessionalTags,
  WrapperContent
} from "./style";

import { useEffect, useState } from "react";
import { FaRegClock } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { LuCalendarDays } from "react-icons/lu";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import AnonymousProfilePhoto from "../../assets/ProfileAvatar.svg";
import { HeaderTitle, HeaderWrapper } from "../../modals/Event/Create/style";
import ShowEvent from "../../modals/Show";
import PatientProfile from "../../pages/Patients/Profile";
import { customStyles } from "../Shared";

interface AppointmentsProps {
  title: string;
  description: string;
  patients: string;
  dateString: string;
  hourString: string;
  professionalColor?: string;
  professionalName?: string;
  appointment?: any;
}

export const Appointments = ({
  title,
  description,
  patients,
  dateString,
  hourString,
  professionalColor,
  professionalName,
  appointment
}: AppointmentsProps) => {
  const [modaDetaillIsOpen, setDetailIsOpen] = useState(false);
  const [modalPersonDetail, setModalPersonDetail] = useState(false);
  const [avatarClicked, setAvatarClicked] = useState(false);
  const [dollarClicked, setDollarClicked] = useState(false);
  const [appointmentClicked, setAppointmentClicked] = useState(false);
  const [detailOpenTab, setDetailOpenTab] = useState('Compromisso');
  const navigate = useNavigate();

  useEffect(() => {
    setDetailOpenTab('Compromisso')
  }, [])

  const sendToPatientProfile = (patient: any) => {
    navigate('', { state: { patient: patient, hidesidebar: true } })
    setModalPersonDetail(true)
  }

  useEffect(() => {
    if (dollarClicked)
      setDetailOpenTab('Pagamento')
    else if (appointmentClicked)
      setDetailOpenTab('Compromisso')
    else if (avatarClicked)
      sendToPatientProfile(appointment?.extendedProps?.patient)

    if (!avatarClicked && (dollarClicked || appointmentClicked))
      setDetailIsOpen(true)

    setDollarClicked(false)
    setAppointmentClicked(false)
  }, [avatarClicked, dollarClicked, appointmentClicked])

  const closeModal = () => {
    setAvatarClicked(false)
    setDetailIsOpen(false);
    setModalPersonDetail(false);
  };

  const fadeDetail = useSpring({
    from: { opacity: 0 },
    to: { opacity: modaDetaillIsOpen ? 1 : 0 },
  });

  const fadePersonDetail = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalPersonDetail ? 1 : 0 },
  });

  return (
    <>
      <Modal
        isOpen={modaDetaillIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fadeDetail}>
          <ShowEvent closeModal={closeModal} event={appointment} defaultTab={detailOpenTab} />
        </animated.div>
      </Modal>

      <Modal
        isOpen={modalPersonDetail}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fadePersonDetail}>
          <HeaderWrapper>
            <HeaderTitle>Paciente</HeaderTitle>
            <IoCloseOutline
              size={22}
              onClick={closeModal}
              color="#919EAB"
              cursor={"pointer"}
            />
          </HeaderWrapper>
          <PatientProfile />
        </animated.div>
      </Modal>


      <WrapperContent onClick={() => { setAppointmentClicked(true) }}>
        <CenterWrapper>
          <AppointmentHeader>
            <ProfessionalTags backgroundColor={professionalColor || 'none'}>{professionalName}</ProfessionalTags>
            <div>
              <DollarIcon
                type={appointment?.extendedProps?.status?.name == "Pago" ? "paid" : "pending"}
                onClick={() => setDollarClicked(true)}
              >
                $
              </DollarIcon>
              <AvatarImage src={AnonymousProfilePhoto} alt="Avatar" onClick={() => setAvatarClicked(true)} />
            </div>
          </AppointmentHeader>
          <PatientName>{patients}</PatientName>
          <AppoimentTitle>{title}</AppoimentTitle>
          <Description>{description}</Description>
        </CenterWrapper>
        <BottomWrapper>
          <DateWrapper>
            <Icon>
              <LuCalendarDays color="#637381" />
            </Icon>
            <DateLabel>{dateString}</DateLabel>
          </DateWrapper>
          <HourWrapper>
            <Icon>
              <FaRegClock color="#637381" />
            </Icon>
            <HourLabel>{hourString}</HourLabel>
          </HourWrapper>
        </BottomWrapper>
      </WrapperContent>
    </>
  );
};
