import { jwtDecode } from "jwt-decode";

export const getGroup = () => {
  const token = localStorage.getItem("bridges.token");
  if (token) {
    const tokenData = jwtDecode(token) as any;
    return tokenData?.groups[0];
  }

  return null;
};

export const checkHasPermission = (permission: string) => {
  const token = localStorage.getItem("bridges.token");
  if (token) {
    const tokenData = jwtDecode(token) as any;
    return tokenData?.permissions.includes(permission);
  }

  return null;
};

export const getProfessionalId = () => {
  const token = localStorage.getItem("bridges.token");
  if (token) {
    const tokenData = jwtDecode(token) as any;
    return tokenData?.professional_id;
  }

  return null;
};

export const getName = () => {
  const token = localStorage.getItem("bridges.token");
  if (token) {
    const tokenData = jwtDecode(token) as any;
    return tokenData?.name;
  }

  return null;
};

export const getProfessionalPhoto = () => {
  const token = localStorage.getItem("bridges.token");
  if (token) {
    const tokenData = jwtDecode(token) as any;
    return tokenData?.professional_photo;
  }

  return null;
};

export const hasAdmin = () => {
  return getGroup() === "gestor";
};
