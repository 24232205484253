import React, { useState, useEffect } from 'react';
import { Button, Message, ModalContainer, Title, ButtonWrapper, InfoMessage } from "./style";
import TutorialGif from "../../assets/gif/video_tutorial.gif"

const SolicitacaoPermissao: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [permissionStatus, setPermissionStatus] = useState(Notification.permission);
    const [permissionBlocked, setPermissionBlocked] = useState(false);

    useEffect(() => {
        const checkPermission = () => {
            if (Notification.permission === 'denied') {
                setPermissionBlocked(true);
            } else {
                setPermissionBlocked(false);
            }
        };

        checkPermission();
    }, []);

    const handleRequestPermission = async () => {
        if ('Notification' in window) {
            const result = await Notification.requestPermission();
            setPermissionStatus(result);
            if (result === 'denied') {
                setPermissionBlocked(true);
            }
        }
    };

    // const showNotification = () => {
    //     if (permissionStatus === 'granted') {
    //         new Notification('Paciente pronto!', {
    //             body: 'Paciente Fracisto está pronto!',
    //             icon: 'https://clinicabridges.com.br/favicon.ico'
    //         });
    //     }
    // };

    return (
        <ModalContainer>
            <Title>Ative as Notificações</Title>
            <Message>
                Para receber atualizações importantes e notificações, por favor, habilite as notificações no seu navegador.
                Caso você já tenha habilitado, não se preocupe!
            </Message>
            {permissionBlocked && (
                <>
                <InfoMessage>
                    As permissões de notificações foram bloqueadas. Você pode redefinir isso nas configurações do navegador.
                    Clique no ícone de cadeado ao lado da URL para acessar as configurações de permissões.
                </InfoMessage>
                <InfoMessage>
                    <img src={TutorialGif} alt="Tutorial de como habilitar notificações" />
                </InfoMessage>
                </>
            )}
            <ButtonWrapper>
                <Button onClick={handleRequestPermission} disabled={permissionBlocked}>
                    Habilitar Notificações
                </Button>
                {/* <Button onClick={showNotification} disabled={permissionStatus !== 'granted'}>
                    Mostrar Notificação
                </Button> */}
                <Button onClick={onClose} style={{ background: '#f44336' }}>
                    Fechar
                </Button>
            </ButtonWrapper>
        </ModalContainer>
    );
};

export default SolicitacaoPermissao;
