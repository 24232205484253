export const SegmentList = [
  {
    title: "Dieta clínica",
    value: "dietaclinica",
  },
  {
    title: "Emagrecimento",
    value: "emagrecimento",
  },
  {
    title: "Hipertrofia",
    value: "hipertrofia",
  },
];
