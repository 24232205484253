import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const WrapperHeaderTable = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const WrapperIcons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;
