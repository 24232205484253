import { useEffect, useMemo, useState } from "react";
import CustomTable from "../../components/CustomTable";
import PageStructure from "../../components/PageStructure";
import { HeaderPage } from "../../components/Shared/HeaderPage";
import * as S from "./styles";
import api from "../../service/api";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { format } from "date-fns";
import { IoEyeOutline } from "react-icons/io5";
import { MdOutlineModeEdit } from "react-icons/md";
import { toast } from "react-toastify";

export const MedicalRecordHistory = () => {
  const [historyData, setHistoryData] = useState<any>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { patient } = location?.state as {
    patient?: any;
  };
  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "patient.name",
      },
      {
        Header: "Objetivo",
        accessor: "metrics.objective",
      },
      {
        Header: "Protocolo",
        accessor: "composition_body.protocol",
      },
      {
        Header: "Criado em",
        accessor: "created_at",
        Cell: ({ value }: { value: string }) => (
          <span>{format(new Date(value), "dd/MM/yyyy HH:mm")}</span>
        ),
      },
      {
        Header: "Atualizado em",
        accessor: "updated_at",
        Cell: ({ value }: { value: string }) => (
          <span>{format(new Date(value), "dd/MM/yyyy HH:mm")}</span>
        ),
      },
      {
        Header: "Profissional",
        accessor: "professional.name",
      },
      {
        Header: "Ações",
        accessor: "tag",
        Cell: (data: any) => (
          <S.WrapperIcons>
            <S.IconWrapper
              onClick={() => {
                navigate("/medicalRecord", {
                  state: {
                    patient: patient,
                    pageType: "view",
                    recordData: data.row.original,
                  },
                });
              }}
            >
              <IoEyeOutline size={20} color="var(--primary-icon-color)" />
            </S.IconWrapper>
            <S.IconWrapper
              onClick={() => {
                navigate("/medicalRecord", {
                  state: {
                    patient: patient,
                    pageType: "edit",
                    recordData: data.row.original,
                  },
                });
              }}
            >
              <MdOutlineModeEdit size={20} color="var(--primary-icon-color)" />
            </S.IconWrapper>
          </S.WrapperIcons>
        ),
      },
    ],
    []
  );

  const fetchPatientRecordHistory = async () => {
    const id = toast.loading("Carregando histórico..");
    await api
      .get(`/prontuarios/${patient.id}/historicos/`)
      .then((resp) => {
        setHistoryData(resp.data);
        toast.update(id, {
          render: "Histórico carregado com sucesso!",
          type: "success",
          autoClose: 2000,
          isLoading: false,
        });
      })
      .catch(() => {
        toast.update(id, {
          render: "Houve uma falha ao carregar o histórico",
          type: "error",
          autoClose: 2000,
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    fetchPatientRecordHistory();
  }, []);

  return (
    <PageStructure>
      <HeaderPage title="Histórico de prontuário" />
      <S.Wrapper>
        <S.WrapperHeaderTable>
          <CustomButton
            onClick={() =>
              navigate("/medicalRecord", {
                state: {
                  patient: patient,
                  pageType: "create",
                },
              })
            }
            theme="addPrimary"
          >
            Adicionar
          </CustomButton>
        </S.WrapperHeaderTable>
        <CustomTable
          columns={columns}
          columnMinWidths={{}}
          data={historyData}
          maxHeight="calc(100vh - 190px)"
          emptyText="Nenhum dado registrado"
        />
      </S.Wrapper>
    </PageStructure>
  );
};
