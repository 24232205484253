import AnonymousProfilePhoto from "../../../../assets/userAnonymous.jpeg";
import { AvatarContainer, AvatarImage, OnlineIndicator } from './style';

const ChatAvatar = ({ src, online }:{src?: string, online: boolean}) => {
  return (
    <AvatarContainer>
      <AvatarImage src={src || AnonymousProfilePhoto} alt="Avatar" />
      {online && <OnlineIndicator />}
    </AvatarContainer>
  );
};

export default ChatAvatar;