import styled from "styled-components";

export const SeparatorBottom = styled.div`
    border-bottom: 1px solid #E5E5E5;
`

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-top: 10px;
    border-bottom: 1px solid #E5E5E5;
`

export const HeaderTitle = styled.span`
    font-size: 20px;
    font-weight: 600;
`