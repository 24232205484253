import { ProgressBar } from "../ProgressBar";
import {
  Content,
  HeaderWrapper,
  Img,
  InfosWrapper,
  OccupationTitle,
  PersonalInfosWrapper,
  ProfessionalName,
  ProgressLabel,
  ProgressWrapper
} from "./style";

interface OccupationProps {
  occupation: string;
  professionalName: string;
  occupationValue: number;
  occupationFinalValue: number;
  isSelected: boolean;
  img?: string;
  onClick: () => void;
}

export const Ocuppation = ({
  occupation,
  professionalName,
  occupationValue,
  occupationFinalValue,
  isSelected,
  img,
  onClick,
}: OccupationProps) => {
  return (
    <Content isSelected={isSelected} onClick={onClick}>
      <HeaderWrapper>
        <InfosWrapper>
          {img && <Img src={img} />}
          <PersonalInfosWrapper>
            <OccupationTitle>{occupation}</OccupationTitle>
            <ProfessionalName>{professionalName}</ProfessionalName>
          </PersonalInfosWrapper>
        </InfosWrapper>
      </HeaderWrapper>
      <ProgressWrapper>
        <ProgressLabel>Meta de ocupação</ProgressLabel>
        <ProgressBar
          currentValue={occupationValue}
          finalValue={occupationFinalValue}
        />
      </ProgressWrapper>
    </Content>
  );
};
