export const getFirstAndLastDayOfMonth = (): { firstDay: string; lastDay: string } => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const currentDayOfMonth = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    // Resetar horas, minutos, segundos e milissegundos para evitar inconsistências
    firstDayOfMonth.setHours(0, 0, 0, 0);
    currentDayOfMonth.setHours(23, 59, 59, 999);

    const firstDay = firstDayOfMonth.toISOString().split("T");
    const lastDay = currentDayOfMonth.toISOString().split("T");

    return {
        firstDay: firstDay[0],
        lastDay: lastDay[0]
    };
}