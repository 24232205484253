import { IoCloseOutline } from "react-icons/io5";
import CustomInput from "../../components/CustomInput";
import {
  FooterWrapper,
  HeaderTitle,
  HeaderWrapper,
  InfoWrapper,
  ModalWrapper,
} from "../Event/Create/style";
import {
  PiIdentificationCardLight,
  PiPhone,
  PiUserCircleLight,
} from "react-icons/pi";
import CustomButton from "../../components/CustomButton";
import CustomDropdown from "../../components/CustomDropDown";
import { useState } from "react";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { FaRegCalendarCheck } from "react-icons/fa";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { ContentWrapper, InputWrapper } from "./style";
import CustomTextArea from "../../components/CustomTextArea";
import api from "../../service/api";
import formatReaisToFloat from "../../utils/formatReaisToFloat";
import formatCurrency from "../../utils/formatCurrencyBr";
import { toast } from "react-toastify";
import { MdOutlinePayment } from "react-icons/md";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { MdNumbers } from "react-icons/md";
import { MdOutlineDoneAll } from "react-icons/md";
import { jwtDecode } from "jwt-decode";

interface CreateTransactionHistory {
  closeModal: () => void;
  updateClients: () => void;
  fetchFunction: () => void;
  fetchWeeklyFunction?: () => void;
  sortChart: string;
}

export const CreateTransactionHistory = ({
  closeModal,
  updateClients,
  fetchFunction,
  fetchWeeklyFunction,
  sortChart,
}: CreateTransactionHistory) => {
  const [loading, setLoading] = useState(false);
  const [transactionOption, setTransactionOption] = useState("IN");
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [selectedOption, setSelectedOption] = useState("CREDIT");
  const [pixKey, setPixKey] = useState("");
  const [installmentsValue, setInstallmentsValue] = useState("1x");
  const [idStone, setIdStone] = useState("");

  const handleCreateHistory = async () => {
    let descriptionConc = '';

    const prePaymentInput = document.getElementById("checkBoxButton") as any;
    let prePaymentValue = false;

    if(prePaymentInput)
      prePaymentValue = prePaymentInput?.checked

    if (selectedOption == "CREDIT") {
      descriptionConc = description + "\n" + `Parcelado em: ${installmentsValue} `;

      if(!idStone) {
        toast.warning("ID do pagamento (Stone) não informado!");
        return
      } else
        descriptionConc += `Transação (Stone)'}: ${idStone}`
    }
    if (selectedOption == "PIX") descriptionConc = description + "\n" + `Comprovante de Pagamento: ${pixKey} `;


    const data = {
      title: title,
      value: formatReaisToFloat(value),
      date: date,
      mode: selectedOption,
      obs: descriptionConc == '' ? description : descriptionConc,
      type: transactionOption,
      is_pre_payment: prePaymentValue, 
    };

    let toastLoading = toast.loading("Registrando movimentação...", {
      className: "toast-loading",
    });

    await api
      .post("/payments/", data)
      .then(() => {
        toast.update(toastLoading, {
          render: "Movimentação registrada com sucesso!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        closeModal();
        fetchFunction();
        if (sortChart == "semanal") fetchWeeklyFunction?.();
      })
      .catch(() => {
        toast.update(toastLoading, {
          render: "Falha ao tentar registrar movimentação!",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });

        closeModal();
      });
  };

  return (
    <ModalWrapper size="large">
      <HeaderWrapper>
        <HeaderTitle>Registro de movimentação</HeaderTitle>
        <IoCloseOutline
          size={22}
          onClick={closeModal}
          color="#919EAB"
          cursor={"pointer"}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <InputWrapper>
          <CustomInput
            disabled={loading}
            label="Titulo"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Digite o nome do titulo"
            leftIcon={
              <MdDriveFileRenameOutline
                size={20}
                color="var(--primary-icon-color)"
              />
            }
          />
          <CustomInput
            label="Data"
            name="start_date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            type="date"
            leftIcon={
              <FaRegCalendarCheck size={16} color="var(--primary-icon-color)" />
            }
          />
          <CustomInput
            disabled={loading}
            label="Valor"
            onChange={(e) => setValue(formatCurrency(e.target.value))}
            value={value}
            name="value"
            placeholder="R$ 0,00"
            leftIcon={
              <AiOutlineDollarCircle
                size={20}
                color="var(--primary-icon-color)"
              />
            }
          />
        </InputWrapper>
        <InputWrapper>
          <CustomDropdown
            label="Método de pagamento"
            options={["CREDITO", "DEBITO", "PIX", "DINHEIRO"]}
            selectedOption={
              selectedOption == "CREDIT"
                ? "CREDITO"
                : selectedOption == "DEBIT"
                ? "DEBITO"
                : selectedOption == "CASH"
                ? "DINHEIRO"
                : "PIX"
            }
            setSelectedOption={(value) =>
              setSelectedOption(
                value == "CREDITO"
                  ? "CREDIT"
                  : value == "DEBITO"
                  ? "DEBIT"
                  : value == "DINHEIRO"
                  ? "CASH"
                  : "PIX"
              )
            }
            leftIcon={
              <MdOutlinePayment size={16} color="var(--primary-icon-color)" />
            }
          />
          {selectedOption == "PIX" && (
            <CustomInput
              disabled={loading}
              label="Comprovante de Pagamento"
              onChange={(e) => setPixKey(e.target.value)}
              value={pixKey}
              name="value"
              placeholder="Digite o código"
              leftIcon={
                <MdOutlineQrCodeScanner
                  size={20}
                  color="var(--primary-icon-color)"
                />
              }
            />
          )}

          {selectedOption == "CREDIT" && (
              <CustomDropdown
                label="Quantidade de parcelas"
                options={[
                  "1x",
                  "2x",
                ]}
                selectedOption={installmentsValue}
                setSelectedOption={(value) => setInstallmentsValue(value)}
                leftIcon={
                  <MdNumbers size={16} color="var(--primary-icon-color)" />
                }
              />
          )}
          <CustomDropdown
            label="Tipo de movimentação"
            options={["Entrada", "Pendencia", "Saída"]}
            selectedOption={
              transactionOption == "IN"
                ? "Entrada"
                : transactionOption == "PEND"
                ? "Pendência"
                : "Saída"
            }
            setSelectedOption={(value) =>
              setTransactionOption(
                value == "Entrada"
                  ? "IN"
                  : value == "Pendencia"
                  ? "PEND"
                  : "OUT"
              )
            }
            leftIcon={
              <MdOutlinePayment size={16} color="var(--primary-icon-color)" />
            }
          />

          {selectedOption != 'CASH' && selectedOption != 'PIX' && <CustomInput
            label="ID do pagamento (Stone)"
            value={idStone}
            onChange={e => setIdStone(e.currentTarget.value)}
            placeholder="Digite o identificador"
            leftIcon={
              <MdOutlineDoneAll size={16} color="var(--primary-icon-color)" />
            }
          />}

          {/* {selectedOption == 'PIX' &&
            <CustomInput
              label="Responsável pela transação"
              value={idStone}
              onChange={e => setIdStone(e.currentTarget.value)}
              placeholder="Digite o responsável"
              leftIcon={
                <MdOutlineDoneAll size={16} color="var(--primary-icon-color)" />
              }
            />
          } */}
        </InputWrapper>
        <InputWrapper>
          <CustomTextArea
            label="Descrição"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            name="observation"
            rows={5}
            placeholder="Observações"
          />
        </InputWrapper>
        <CustomInput
          label="Pré Pagamento"
          type="checkbox"
          disableBorder
        />
      </ContentWrapper>
      <FooterWrapper type="space-between">
        <CustomButton onClick={closeModal} disabled={loading} theme="cancel">
          Cancelar
        </CustomButton>
        <CustomButton
          disabled={loading}
          theme="register"
          onClick={() => handleCreateHistory()}
        >
          Registrar
        </CustomButton>
      </FooterWrapper>
    </ModalWrapper>
  );
};
