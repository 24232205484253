import styled from "styled-components";

export const SideMenuWrapper = styled.div`
    height: 100%;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 310px;
    gap: 40px;
`

export const LogoWrapper = styled.img`
    padding-top: 20px;
    width: 140px;
`

export const MenuItensWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
`;

export const TopWrapper = styled.div`
    align-items: center;
    gap: 40px;
    display: flex;
    flex-direction: column;
`

export const FooterWrapper = styled.div`
`;

export const InfosWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ImageUser = styled.img`
    border-radius: 6px;
    max-width: 32px;
`;

export const InfosUserWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 8px;
`;

export const Username = styled.label`
    font-size: 14px;
`;

export const Role = styled.label`
    font-size: 12px;
    color: #637381;
    padding-top: 3px;
`;

export const Icon = styled.img`
    cursor: pointer;
`;

export const IconsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    gap: 8px;
`;