import { ReactNode } from "react";
import { Info, Label, LabelWrapper } from "./style";

interface CustomLabelProps {
    label: string;
    info?: string;
    infoBold?: boolean;
    children?: ReactNode;
}

export const CustomLabel = ({label, info, children, infoBold}: CustomLabelProps) => {
    return (
       <LabelWrapper>
            <Label>{label}</Label>
            {info && <Info bold={infoBold || false}>{info}</Info>}
            {children && children}
       </LabelWrapper>
    )
}

export default  CustomLabel;