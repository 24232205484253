import axios from "axios";
import { toast } from "react-toastify";

let api = axios.create({
  baseURL: "https://api.bridges.conjosa.com.br/api",
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('bridges.token');
  
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

api.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('bridges.token');
    toast.warning('Sessão expirada, faça login novamente.', {
      onClose: () => {
        window.location.href = '/login';
      }
    });
    
  }
  return Promise.reject(error);
});

export default api;
