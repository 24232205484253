import styled from 'styled-components';

export const WrapperSearchInput = styled.div`
    width: 20%;
    border: 1px solid #b3b3b3;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 10px;
`;

export const SearchInput = styled.input`
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
`;

export const Icon = styled.div`
`

export const WrapperTable = styled.div`
    width: calc(100vw - 290px);
`;

export const WrapperIcons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
`;