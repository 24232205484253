import { useState } from "react";
import { ModalWrapper, Tab } from "./style"

interface ModalWrapperWithTabProps {
  tabs: string[];
  size?: 'default' | 'large' | 'small';
  onChangeTab: (tab: string) => void;
  children: React.ReactNode;
  defaultTab?: string;
}

const ModalWrapperWithTab = ({tabs, onChangeTab, size, children, defaultTab}: ModalWrapperWithTabProps) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab || tabs[0])

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    onChangeTab(tab);
  }
  
  return  (
    <ModalWrapper size={size}>
      {
        tabs.map((tab) => {
          return (
            <Tab 
              tabActive={selectedTab == tab}
              onClick={() => {handleTabClick(tab)}}
            >
              {tab}
            </Tab>
          )
        })
      }
      {children}
    </ModalWrapper>
  )
}


export default ModalWrapperWithTab