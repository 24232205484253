import { useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import CustomDropdown from "../../../../components/CustomDropDown";
import CustomLabel from "../../../../components/CustomLabel";
import { Tag } from "../../../../components/Tags";
import Modal from "react-modal";
import {
  FlexWrapper,
  ContentWrapper,
  HeaderTitle,
  HeaderWrapper,
  InfoWrapper,
  SeparatorBottom,
} from "../../../Event/Create/style";
import {
  Container,
  DollarIcon,
  PaymentContainer,
  PaymentContent,
  PaymentHeaderTitle,
  PaymentInfo,
  PaymentInfoDate,
  PaymentLabel,
  PaymentLabelReceipt,
  PaymentLabelWrapper,
  PaymentList,
  PaymentWrapper,
} from "./style";
import { customStyles } from "../../../../components/Shared";
import { animated, useSpring } from "react-spring";
import api from "../../../../service/api";
import PaymentEntry from "../../../Event/PaymentEntry";

interface PaymentTabProps {
  closeModal: () => void;
  setAppointments?: any;
  event: any;
}

interface Payment {
  date: string;
  title: string;
  value: string;
  mode: string;
  type: string;
  appointment: number;
  is_pre_payment: boolean;
  created_by: string;
}

interface AppointmentPayment {
  payments: Payment[];
  still: number;
  total_paid: number;
  value: number;
}

const PaymentTab = (props: PaymentTabProps) => {
  const [form, setForm] = useState<any>({});
  const [refresh, setRefresh] = useState(false);
  const [appointmentPayment, setAppointmentPayment] = useState(
    {} as AppointmentPayment
  );

  useEffect(() => {
    getAppointmentPayments();
  }, []);

  useEffect(() => {
    if (refresh) {
      props.setAppointments?.([]);
      getAppointmentPayments();
      setRefresh(false);
    }
  }, [refresh]);

  const formatCurrency = (value: string) => {
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    return value;
  };

  const getAppointmentPayments = async () => {
    const result = await api
      .get(`/appointments/${props.event.id}/payments/`)
      .finally(() => {
        setRefresh(false);
        setModalPaymentEntryIsOpen(false);
      });

    setAppointmentPayment(result.data);
  };

  const closePaymentEntryModal = () => {
    setModalPaymentEntryIsOpen(false);
  };

  const [modalPaymentEntryIsOpen, setModalPaymentEntryIsOpen] = useState(false);

  const fadePaymentEntry = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalPaymentEntryIsOpen ? 1 : 0 },
  });

  const handleInputChange = (key: any, value: any) => {
    setForm({ ...form, [key]: value });
  };

  const handleSelectedOption = (value: string) => {
    switch (value) {
      case "Lançar pagamento":
        setModalPaymentEntryIsOpen(true);
    }

    handleInputChange("total", value);
  };

  const handleDate = (date: string) => {
    const dateString = date;
    const formattedDateString = dateString.replace("T", " ");
    const dateToFormat = new Date(formattedDateString);
    const dataFormatted = dateToFormat.toLocaleDateString("pt-BR", {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    return dataFormatted;
  };

  const handlePaymentMode = (mode: string) => {
    switch (mode) {
      case "CASH":
        return "Dinheiro";
      case "CREDIT":
        return "Crédito";
      case "DEBIT":
        return "Débito";
      case "PIX":
        return "Pix";
      case "TRANSFER":
        return "Transferência";
    }
  };

  console.log(appointmentPayment?.payments);

  return (
    <>
      <Modal
        isOpen={modalPaymentEntryIsOpen}
        onRequestClose={closePaymentEntryModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fadePaymentEntry}>
          <PaymentEntry
            closeModal={() => {
              setModalPaymentEntryIsOpen(false);
            }}
            initialValue={appointmentPayment.still}
            event={props.event}
            doRefresh={setRefresh}
          />
        </animated.div>
      </Modal>

      <HeaderWrapper>
        <HeaderTitle>Pagamento</HeaderTitle>
        <IoCloseOutline
          size={22}
          onClick={props.closeModal}
          color="#919EAB"
          cursor={"pointer"}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <InfoWrapper>
          <FlexWrapper>
            <Container>
              <InfoWrapper>
                <PaymentLabelWrapper>
                  <PaymentLabel>Valor pago</PaymentLabel>
                  <PaymentInfo bold color="#128D57">
                    R${" "}
                    {formatCurrency(
                      (appointmentPayment.total_paid * 100 || "000").toString()
                    )}
                  </PaymentInfo>
                </PaymentLabelWrapper>

                <PaymentLabelWrapper>
                  <PaymentLabel paid={appointmentPayment.still <= 0}>
                    Valor restante
                  </PaymentLabel>
                  <PaymentInfo
                    bold
                    color="#B76E00"
                    paid={appointmentPayment.still <= 0}
                  >
                    R${" "}
                    {formatCurrency(
                      (appointmentPayment.still * 100 || "000").toString()
                    )}
                  </PaymentInfo>
                </PaymentLabelWrapper>
              </InfoWrapper>
            </Container>
            <Container>
              <InfoWrapper>
                <CustomLabel
                  label={`Valor ${
                    props.event?.extendedProps?.service?.name || "Total"
                  }`}
                  infoBold={true}
                  info={`R$ ${formatCurrency(
                    (appointmentPayment.value * 100 || "000").toString()
                  )}`}
                />
                <CustomLabel label="Status Pagamento">
                  <Tag
                    type={
                      props.event?.extendedProps?.status?.name == "Pago"
                        ? "paid"
                        : "pending"
                    }
                    text={
                      props.event?.extendedProps?.status?.name == "Pago"
                        ? "Pago"
                        : "Em aberto"
                    }
                  />
                </CustomLabel>
              </InfoWrapper>
            </Container>
            <Container>
              <InfoWrapper>
                <CustomDropdown
                  selectedOption={form?.total}
                  setSelectedOption={handleSelectedOption}
                  label="Ações"
                  dropDownType="payment"
                />
              </InfoWrapper>
            </Container>
          </FlexWrapper>
          <SeparatorBottom />
          <HeaderTitle>Pagamentos Realizados</HeaderTitle>
          <PaymentList>
            {appointmentPayment?.payments?.length ? (
              appointmentPayment?.payments?.map((payment, index) => {
                return (
                  <PaymentContainer>
                    <PaymentHeaderTitle>
                      {payment?.is_pre_payment
                        ? `Pré-pagamento #${index + 1}`
                        : `Pagamento #${index + 1}`}
                      {payment?.is_pre_payment ? (
                        <DollarIcon type="prepaid"> $ </DollarIcon>
                      ) : (
                        <DollarIcon type="paid"> $ </DollarIcon>
                      )}
                    </PaymentHeaderTitle>
                    <SeparatorBottom />
                    <Container>
                      <PaymentContent>
                        <PaymentWrapper>
                          <PaymentLabelWrapper>
                            <PaymentLabel>Valor</PaymentLabel>
                            <PaymentInfo
                              bold
                              color={
                                payment?.is_pre_payment ? "#4DBCFF" : "#128D57"
                              }
                            >
                              R${" "}
                              {formatCurrency(
                                (Number(payment.value) * 100).toString()
                              )}
                            </PaymentInfo>
                          </PaymentLabelWrapper>

                          <CustomLabel
                            label="Tipo"
                            info={handlePaymentMode(payment.mode)}
                          />
                        </PaymentWrapper>
                        <PaymentWrapper>
                          <PaymentLabelWrapper>
                            <PaymentLabel>Data</PaymentLabel>
                            <PaymentInfoDate>
                              {handleDate(payment.date)}
                            </PaymentInfoDate>
                          </PaymentLabelWrapper>
                        </PaymentWrapper>

                        <PaymentWrapper>
                          <PaymentLabelReceipt>
                            <PaymentLabel>Recebido por</PaymentLabel>
                            <PaymentInfoDate>
                              {payment.created_by}
                            </PaymentInfoDate>
                          </PaymentLabelReceipt>
                        </PaymentWrapper>
                      </PaymentContent>
                    </Container>
                  </PaymentContainer>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <DollarIcon
                  type="pending"
                  style={{ position: "static", width: "50px", height: "50px" }}
                >
                  {" "}
                  ${" "}
                </DollarIcon>
                Sem pagamentos até o momento.
              </div>
            )}
          </PaymentList>
        </InfoWrapper>
      </ContentWrapper>
    </>
  );
};

export default PaymentTab;
