import styled from 'styled-components';

const PageWrapper = styled.div`
  background-color: #F5F5F5;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 12px 0;
`

const ProfessionalMessageContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
`

const PatientMessageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`

const Message = styled.div`
  background: #fff;
  border-radius: 5px;
  border-top-right-radius: 0;
  box-shadow: 1px 1px 1px 1px #0003;
  flex-direction: column;
  float: left;
  margin: 3px 11px 3px 20px;
  min-width: 140px;
  padding: 6px 9px 8px;
  position: relative;
`

export const ChatAdvice = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`

export const LongLine = styled.div`
  width: 100%;
  height: 30px;
  border-bottom: 1px solid #666666;
  position: relative;
  bottom: 17px;
  z-index: 1;
`

export const Name = styled.span`
  text-align: center;
  background-color: #F5F5F5;
  bottom: 10px;
  position: absolute;
  padding: 0 32px;
  position: absolute;
  z-index: 9999;
  font-size: 15px;
  color: #666666;
`

export const comp = {
  PageWrapper,
  ProfessionalMessageContainer,
  PatientMessageContainer,
  Message,
  ChatAdvice,
  LongLine,
  Name
} 