import FullCalendar from "@fullcalendar/react";
import { useState } from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { MdOutlineKeyboardArrowRight, MdSchedule } from "react-icons/md";
import { toast } from "react-toastify";
import api from "../../service/api";
import { getGroup } from "../../service/token.service";
import {
  OptionIcon,
  OptionTitle,
  OptionWrapper,
  OptionsListWrapper,
  RightIcon,
  SubOption,
  SubOptionContainer,
  SubOptionsWrapper,
  Wrapper,
} from "./style";

type AnchorPoint = {
  x: number;
  y: number;
};

interface ShortcutProps {
  isShow: boolean;
  anchorPoint: AnchorPoint;
  eventId: string;
  options: any[];
  optionsSchedule: any[];
  events: any[];
  setEvents: React.Dispatch<React.SetStateAction<any[]>>;
  calendarRef: React.RefObject<FullCalendar>;
  refreshCalendarFunc: () => void;
}

export const ShortcutOptions = ({
  isShow,
  anchorPoint,
  eventId,
  options,
  optionsSchedule,
  events,
  setEvents,
  calendarRef,
  refreshCalendarFunc,
}: ShortcutProps) => {
  const [showActiveOptions, setShowActiveOptions] = useState(false);
  const [showActiveScheduleOptions, setShowActiveScheduleOptions] =
    useState(false);

  if (!isShow) {
    return null;
  }

  const onUpdateAppointmentStatus = (status: any) => {
    const toastLoading = toast.loading("Atualizando evento...", {
      className: "toast-loading",
    });

    api
      .patch(`/appointments/${eventId}/`, { status: status.id })
      .then((resp) => {
        const updatedEvents = events.map((item) =>
          item.id === eventId ? { ...item, status } : item
        );
        setEvents(updatedEvents);
        refreshCalendarFunc();
        toast.update(toastLoading, {
          render: "Status do evento atualizado com sucesso!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        toast.update(toastLoading, {
          render: "Falha ao tentar atualizar status do evento!",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      });
  };

  const onUpdateAppointmentScheduleStatus = (status: any) => {
    const toastLoading = toast.loading("Atualizando evento...", {
      className: "toast-loading",
    });

    api
      .patch(`/appointments/${eventId}/`, { appointment_status: status.id })
      .then((resp) => {
        const updatedEvents = events.map((item) =>
          item.id === eventId ? { ...item, status } : item
        );
        setEvents(updatedEvents);
        refreshCalendarFunc();
        toast.update(toastLoading, {
          render: "Status do evento atualizado com sucesso!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        toast.update(toastLoading, {
          render: "Falha ao tentar atualizar status do evento!",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      });
  };

  const menuOptions = [
    {
      title: "Consulta",
      onClick: () => {},
      showSubOptionHook: setShowActiveScheduleOptions,
      subOptionState: showActiveScheduleOptions,
      icon: <MdSchedule />,
      sub: optionsSchedule,
      updateFunction: onUpdateAppointmentScheduleStatus,
      role: ["profissional", "gestor", "atendente"],
    },
    {
      title: "Pagamento",
      onClick: () => {},
      showSubOptionHook: setShowActiveOptions,
      subOptionState: showActiveOptions,
      icon: <IoIosCheckmarkCircleOutline />,
      sub: options,
      updateFunction: onUpdateAppointmentStatus,
      role: ["financeiro", "gestor"],
    },
  ];

  return (
    <Wrapper style={{ top: anchorPoint.y, left: anchorPoint.x }}>
      <OptionsListWrapper>
        {menuOptions.map((item) => {
          if (!item.role.includes(getGroup())) return;
          return (
            <OptionWrapper
              key={item.title}
              onMouseEnter={() => item.showSubOptionHook(true)}
              onMouseLeave={() => item.showSubOptionHook(false)}
            >
              <OptionIcon>{item.icon}</OptionIcon>
              <OptionTitle onClick={item.onClick}>{item.title}</OptionTitle>
              {item.sub.length !== 0 && (
                <RightIcon>
                  <MdOutlineKeyboardArrowRight width={"40px"} />
                </RightIcon>
              )}
              {item.subOptionState && (
                <SubOptionsWrapper>
                  {item.sub.map((option: any) => (
                    <SubOptionContainer
                      key={option.id}
                      style={{ display: "flex" }}
                      onClick={() => item.updateFunction(option)}
                    >
                      {option.color && (
                        <div
                          style={{
                            width: "7px",
                            height: "7px",
                            padding: "6px",
                            position: "relative",
                            top: "2px",
                            background: `${option.color}60`,
                            borderRadius: "50%",
                            border: `1px solid ${option.color}`,
                            marginRight: "5px",
                          }}
                        ></div>
                      )}
                      <SubOption>{option.name}</SubOption>
                    </SubOptionContainer>
                  ))}
                </SubOptionsWrapper>
              )}
            </OptionWrapper>
          );
        })}
      </OptionsListWrapper>
    </Wrapper>
  );
};
