import { Title, Subtitle, Info, AlertIcons, InfoLabelWrapper} from "./style";
import React from 'react';

interface InfoLabelProps {
    label: string;
    value: string;
    tagStyle?: boolean;
    color?: string;
    foco?: boolean;
    children?: React.ReactNode; // Adiciona a propriedade children
    alert?: boolean;
}

export const InfoLabel: React.FC<InfoLabelProps> = ({ label, value, tagStyle, color, foco, children, alert }) => {
    return (
        <InfoLabelWrapper>
            <Title>
                {label}
                {alert && <AlertIcons>!</AlertIcons>}
            </Title>
            {!tagStyle && 
                <Subtitle focus={foco}>
                    {value}
                </Subtitle>
            }
            {tagStyle &&
                <Info color={color || '#D3D3D3'}>
                    {value}
                </Info>
            }
            {children}
        </InfoLabelWrapper>
    );
};
