import styled from "styled-components";


export const ActionsContent = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 10px;

svg{
    cursor: pointer;
    transition: all.3;
    color: green;
}

svg:hover{
    transform: scale(1.1);
}


`

export const RegisterContact = styled.button`
padding: 8px;
border: none;
border-radius: 10px;
color: #fff;
background-color: rgb(18, 112, 252);
cursor: pointer;
font-family: Verdana, Geneva, Tahoma, sans-serif;
transition: all.3s;
&:hover{
    transform: scale(0.9);
    background-color: rgb(28 72 137);
}
`

export const StatusContent = styled.div`
display: flex;
flex-direction: column;
width: 100%;
gap: 5px;
`

export const InfoContent = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width: 100%;
margin-top: 20px;


`


export const PatientName = styled.h2`
font-size: 16px;
color: #000;
font-weight: 600;

`

export const DateContact = styled.p`
display: flex;
font-size: 14px;
color: #000;
font-weight: 600;

svg{
    margin-right: 5px;
}

`

export const ProfessionalName = styled.p`
color: #000;
font-size: 14px;
font-weight: 600;




`



export const MessageContent = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
margin-top: 20px;
gap: 20px;

.input-message{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
}




`

export const ContactButton = styled.button`
padding: 12px;
border: none;
font-family: Verdana, Geneva, Tahoma, sans-serif;
border-radius: 10px;
color: #fff;
background-color: rgb(18, 112, 252);
cursor: pointer;
font-family: Verdana, Geneva, Tahoma, sans-serif;
transition: all.3s;
&:hover{
    transform: scale(0.9);
    background-color: rgb(28 72 137);
}




`

export const RegisterContent = styled.div`
width: 100%;
margin-top: 20px;
display: flex;
flex-direction: row;
gap: 5px;




`

export const ButtonRegister = styled.button`
padding: 10px;
border: none;
border-radius: 10px;
color: #fff;
background-color: rgb(18, 112, 252);
cursor: pointer;
font-family: Verdana, Geneva, Tahoma, sans-serif;
transition: all.3s;
&:hover{
    transform: scale(0.9);
    background-color: rgb(28 72 137);
}




`



interface StatusShapeProps {
    type: 'success' | 'pending' | 'danger' | 'default';
}

export const getStatusColor = (type: StatusShapeProps['type']) => {
    switch (type) {
        case 'success':
            return '#4CAF50'; // verde
        case 'pending':
            return '#FFC107'; // amarelo
        case 'danger':
            return '#F44336'; // vermelho
        default:
            return '#9E9E9E'; // cinza
    }
};

export const StatusShape = styled.div<StatusShapeProps>`
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    color: white;
    background-color: ${({ type }) => getStatusColor(type)};
    font-size: 12px;
    text-align: center;
  `;