import styled from "styled-components";
import { MdCheck } from "react-icons/md";

export const HomeContent = styled.div`
  display: flex;
`;

export const RightContentWrapper = styled.div<{ isCollapsed: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
  display: ${({ isCollapsed }) => (isCollapsed ? "none" : "flex")};
  width: 100%;
  padding-left: 10px;
`;

export const CenterContentWrapper = styled.div`
  flex: 4;
  width: 100%;
  height: 100%;
`;

export const TitleRightSection = styled.div`
  font-weight: bold;
  @media (max-width: 1270px) {
    text-align: center;
  }
`;

export const AppointmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 97vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
`;

export const ProfessionalTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  &::-webkit-scrollbar {
    width: 2px;
  }
  position: relative;
  height: 80px;
`;

interface CheckedIconProps {
  isChecked: boolean;
}

export const CheckedIcon = styled.div<CheckedIconProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 20;
  background-color: #000;
`;

export const ToggleButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;
