import styled from "styled-components";

interface ModalWrapperProps {
    size?: 'default' | 'large' | 'small';
}

interface SeparatorProps {
    isFinished: boolean;
}

export const Separator = styled.div<SeparatorProps>`
    width: 2px;
    background: ${props => props.isFinished ? '#3D8F40': '#E5E5E5'};
    /* flex: 1; */
    height: 40px;
    margin-top: 2px;
    transition: all 0.3s ease-in-out;
`

export const ModalStepper = styled.div<ModalWrapperProps>`
    display: flex;
    flex-direction: column;
    padding: 24px 15px 0 0;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
`

export const ModalWrapper = styled.div<ModalWrapperProps>`
    display: flex;
    flex-direction: column;
    width: ${props => {
        switch (props.size) {
            case 'default':
                return '810px';
            case 'small':
                return '460px';
            default:
                return '1000px';
        }
    }};
    padding: 0;
    justify-content: space-evenly;
    transition: all 0.3s ease-in-out;
`

interface TabProps {
    tabActive: boolean;
    isFinished: boolean;
}

export const Step = styled.div<TabProps>`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: white;
    background: ${props => props.tabActive ? '#1270FC' : props.isFinished ? '#3D8F40': '#d3d3d3'};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    :hover {
        cursor: pointer;
    }
    text-align: center;
    transition: all 0.3s ease-in-out;
`