import styled from "styled-components";

export const OutWrapper = styled.div`
  color: rgb(18, 112, 252);
  padding: 8px;
  border: 2px solid rgb(18, 112, 252);
  cursor: pointer;
  border-radius: 10px;
`;

export const Wrapper = styled.div``;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PaddingWrapper = styled.div`
  padding-left: 7px;
  padding-right: 5px;
`;

export const ContentWrapper = styled.div`
  padding: 10px;
  border-left: 1px solid rgb(18, 112, 252);
  border-right: 1px solid rgb(18, 112, 252);
  border-bottom: 1px solid rgb(18, 112, 252);
  border-radius: 0px 0px 10px 10px;
`;

export const TitleTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 5px;
`;

export const TitleIcon = styled.img`
  width: 20px;
  height: 20px;
`;
