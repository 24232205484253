import styled from "styled-components";
import { transparentize } from 'polished';

interface LabelTagsProps {
    color: string;
    bold?: boolean
}

export const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

export const Label = styled.span`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
`

export const Info = styled.span<LabelTagsProps>`
    font-size: 12px !important;
    font-style: normal;
    background-color: ${props => transparentize(0.75, props.color)};
    padding: 7px;
    border-radius: 5px;
    color: ${props => props.color} !important;
    text-align: center;
`;