import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { jwtDecode } from "jwt-decode";
import { useEffect, useRef, useState } from "react";
import { HiOutlineClock } from "react-icons/hi";
import Modal from "react-modal";
import { animated, useSpring } from "react-spring";
import CreateEvent from "../../modals/Event/Create";
import ShowEvent from "../../modals/Show";
import api from "../../service/api";
import { getGroup } from "../../service/token.service";
import { customStyles } from "../Shared";
import { ShortcutOptions } from "../ShortcutOptions";
import "./newStyle.css";
import { CalendarEventBlock, CalendarWrapper, DollarIcon } from "./style";
import { DateSelectArg } from "@fullcalendar/core";
import ReactDOM from "react-dom";

interface AppointmentStatus {
  status: string;
  color: string;
}

interface EventData {
  id: string;
  title: string;
  start: string;
  end?: string;
  end_time?: string;
  allDay?: boolean;
  paciente?: string;
  professional?: { name: string; id: string; color: string };
  establishment?: any;
  patient?: any;
  observation?: string;
  status?: AppointmentStatus;
  service?: any;
}

interface CustomCalendarProps {
  appointments: any;
  setAppointments: any;
  setIntervalDate: any;
  refreshFunction: () => void;
}

type AnchorPoint = {
  x: number;
  y: number;
};

const CustomCalendar = ({
  appointments,
  setAppointments,
  setIntervalDate,
  refreshFunction,
}: CustomCalendarProps) => {
  const [events, setEvents] = useState<EventData[]>([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modaDetaillIsOpen, setDetailIsOpen] = useState(false);
  const [eventSelected, setEventSelected] = useState<any>();
  const [anchorPoint, setAnchorPoint] = useState<AnchorPoint>({ x: 0, y: 0 });
  const [isShown, setIsShow] = useState(false);
  const [eventCalendarId, setEventCalendarId] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [optionsSchedule, setOptionsSchedule] = useState<any[]>([]);
  const calendarRef = useRef<FullCalendar>(null);
  const [eventClicked, setEventClicked] = useState<string | undefined>(
    undefined
  );
  const [userRole, setUserRole] = useState("");
  const [dollarClicked, setDollarClicked] = useState(false);

  const creationBlockedDuePastDate = useRef(false);

  const handleContextMenu = (event: any, eventId: string, creationBlocked: any) => {
    event.preventDefault();
    const windowHeight = window.innerHeight;
    const menuHeight = 100;

    if (
      event.target?.attributes?.["style"].textContent.includes(
        "background-color: rgb(156, 156, 156)"
      )
    ) {
      if(creationBlocked) return;

      setIsOpen(true);
      return;
    }

    if (event.pageY + menuHeight > windowHeight) {
      setAnchorPoint({ y: event.pageY - menuHeight, x: event.pageX });
      setEventCalendarId(eventId);
      setIsShow(true);
    } else {
      setAnchorPoint({ y: event.pageY, x: event.pageX });
      setEventCalendarId(eventId);
      setIsShow(true);
    }
  };

  useEffect(() => {
    setEvents(appointments);
  }, [appointments]);

  const renderEventContent = (eventInfo: any) => {
    return (
      <CalendarEventBlock>
        <div
          style={{ cursor: "pointer", height: "100%" }}
          className="content-event"
        >
          <div className="payment-content">
            <div className="box-event">
              <HiOutlineClock style={{ verticalAlign: "middle" }} />
              <span style={{ color: "#332e2f" }}>{eventInfo.timeText}</span>
            </div>
            <DollarIcon
              type={
                eventInfo.event.extendedProps.status?.name == "Pago"
                  ? "paid"
                  : "pending"
              }
              onClick={() => setDollarClicked(true)}
            >
              $
            </DollarIcon>
          </div>
          <div className="box-event">
            <span className="patient-name" style={{ color: "#332e2f" }}>
              {eventInfo?.event?.extendedProps?.patient?.name}
            </span>
          </div>
        </div>
      </CalendarEventBlock>
    );
  };

  const customButtons = {
    addEventButton: {
      text: "Adicionar Evento",
      click: () => {
        setIsOpen(true);
      },
    },
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeDetailModal = () => {
    setDetailIsOpen(false);
  };

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalIsOpen ? 1 : 0 },
  });

  const fadeDetail = useSpring({
    from: { opacity: 0 },
    to: { opacity: modaDetaillIsOpen ? 1 : 0 },
  });

  const handleClick = (event: any) => {
    if (isShown) {
      setIsShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
  });

  useEffect(() => {
    const token = localStorage.getItem("bridges.token");
    if (token) {
      const tokenData = jwtDecode(token) as any;
      setUserRole(tokenData.groups[0]);
    }
    api
      .get("/status/")
      .then((resp) => {
        setOptions(resp.data);
      })
      .catch((error) => {
        console.log("erro ao buscar status", error);
      });

    api
      .get("/appointment-status/")
      .then((resp) => {
        setOptionsSchedule(resp.data);
      })
      .catch((error) => {
        console.log("erro ao buscar appointment-status", error);
      });
  }, []);

  const handleDatesSet = (info: any) => {
    const startDate = info.view.currentStart;
    const endDate = info.view.currentEnd;

    console.log(
      "Data inicial do intervalo visível:",
      startDate.toISOString().split("T")[0]
    );
    console.log(
      "Data final do intervalo visível:",
      endDate.toISOString().split("T")[0]
    );
    setIntervalDate({
      start_date: startDate.toISOString().split("T")[0],
      end_date: endDate.toISOString().split("T")[0],
    });
  };

  const handleDateSelect = (selectInfo: any) => {
    const { start, end, view } = selectInfo;

    if (start < new Date()) {
      creationBlockedDuePastDate.current = true
      return;
    }

    setEventClicked(selectInfo.start.toISOString());
  };

  return (
    <>
      <CalendarWrapper id="eventContainer">
        <ShortcutOptions
          isShow={isShown}
          anchorPoint={anchorPoint}
          eventId={eventCalendarId}
          options={options}
          optionsSchedule={optionsSchedule}
          events={events}
          setEvents={setEvents}
          calendarRef={calendarRef}
          refreshCalendarFunc={refreshFunction}
        />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          shouldCloseOnOverlayClick={false}
          style={customStyles}
        >
          <animated.div style={fade}>
            <CreateEvent closeModal={closeModal} initialDate={eventClicked} />
          </animated.div>
        </Modal>
        <Modal
          isOpen={modaDetaillIsOpen}
          onRequestClose={closeDetailModal}
          shouldCloseOnOverlayClick={false}
          style={customStyles}
        >
          <animated.div style={fadeDetail}>
            <ShowEvent
              closeModal={closeDetailModal}
              event={eventSelected}
              setAppointments={setAppointments}
            />
          </animated.div>
        </Modal>
        <FullCalendar
          datesSet={handleDatesSet}
          ref={calendarRef}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
            interactionPlugin,
          ]}
          initialView="timeGridWeek"
          locale={ptBrLocale}
          themeSystem="bootstrap"
          headerToolbar={{
            left:
              getGroup() &&
              ["gestor", "profissional", "atendimento"].includes(getGroup())
                ? "addEventButton prev,next"
                : "prev,next",
            right: "timeGridDay,timeGridWeek",
          }}
          weekends={true}
          events={events}
          editable={false}
          select={(info) => {
            creationBlockedDuePastDate.current = false

            handleDateSelect(info)
          }}
          selectable={true}
          selectOverlap={false}
          selectMirror={true}
          eventOverlap={false}
          slotMinTime="06:00:00"
          dragScroll={false}
          eventDidMount={(arg) => {
            const color =
              arg.event?.extendedProps?.appointment_status?.color ||
              arg.event?.extendedProps?.status?.color ||
              "#9c9c9c";
            arg.el.style.backgroundColor = color;
            arg.el.style.borderColor = color;
            const eventId = arg.event.id;
            arg.el.addEventListener("contextmenu", (jsEvent) => {
              if (
                userRole == "gestor" ||
                userRole == "profissional" ||
                userRole == "atendimento"
              ) {
                handleContextMenu(jsEvent, eventId, creationBlockedDuePastDate.current);
              }
            });
          }}
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: "short",
          }}
          contentHeight={"auto"}
          dayMaxEvents={true}
          customButtons={customButtons}
          dayHeaderContent={(arg) => {
            const isMonthlyView = arg.view.type === "dayGridMonth";

            if (isMonthlyView) {
              return arg.date.toLocaleDateString("default", {
                weekday: "long",
              });
            } else {
              return (
                <div>
                  <div className="dayLocale">
                    {arg.date.toLocaleDateString("default", {
                      day: "2-digit",
                      month: "2-digit",
                    })}
                  </div>
                  <div className="dayString">
                    {arg.date.toLocaleDateString("default", {
                      weekday: "long",
                    })}
                  </div>
                </div>
              );
            }
          }}
          eventContent={renderEventContent}
          slotDuration={{
            hour: 1,
          }}
          initialDate={new Date()}
          eventClick={(info) => {
            setEventSelected(info.event.toPlainObject());
            setDetailIsOpen(true);
          }}
        />
      </CalendarWrapper>
    </>
  );
};

export default CustomCalendar;
