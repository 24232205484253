export const getFirstAndLastDayOfWeek = (): { firstDay: string; lastDay: string } => {
  const today = new Date();
  const currentDay = today.getDay();

  const firstDayOfWeek = new Date(today);
  firstDayOfWeek.setDate(today.getDate() - currentDay);

  const lastDayOfWeek = new Date(today);

  firstDayOfWeek.setHours(0, 0, 0, 0);
  lastDayOfWeek.setHours(23, 59, 59, 999);

  const firstDay = firstDayOfWeek.toISOString().split("T")[0];
  const lastDay = lastDayOfWeek.toISOString().split("T")[0];

  return {
    firstDay,
    lastDay,
  };
}