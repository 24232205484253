import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "../../pages/Home";
import PatientsPage from "../../pages/Patients";
import PatientProfile from "../../pages/Patients/Profile";
import Today from "../../pages/Today";

export const ProfessionalRoutes = () => {
    return (
        <>
        <BrowserRouter>
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/today" Component={Today} />
            <Route path="/patients" Component={PatientsPage} />
            <Route path="/patients/details" Component={PatientProfile} />
            <Route path="*" element={<Navigate to="/today" />} />
          </Routes>
        </BrowserRouter>
      </>
    )
}