import { BrowserRouter, Route, Routes } from "react-router-dom";
import PatientsPage from "../../pages/Patients";
import { Wallet } from "../../pages/Wallet";
import { Navigate } from "react-router-dom";
import Today from "../../pages/Today";
import PatientProfile from "../../pages/Patients/Profile";

export const RecepcionistRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/today" Component={Today} />
          <Route path="/wallet" Component={Wallet} />
          <Route path="*" element={<Navigate to="/today" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
