import Modal from "react-modal";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Home from "../../pages/Home";
import PatientsPage from "../../pages/Patients";
import ProfessionalsPage from "../../pages/Professionals";
import PatientProfile from "../../pages/Patients/Profile";
import ProfessionalProfile from "../../pages/Professionals/Profile";
import { Control } from "../../pages/Control";
import { Wallet } from "../../pages/Wallet";
import Today from "../../pages/Today";
import { Navigate } from "react-router-dom";
import PostConsultation from "../../pages/PostConsultation";
import PreConsultation from "../../pages/PreConsultation";

export const ServiceRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/today" Component={Today} />
          <Route path="/patients" Component={PatientsPage} />
          <Route path="/patients/details" Component={PatientProfile} />
          <Route path="/postConsultation" Component={PostConsultation} />
          <Route path="/preConsultation" Component={PreConsultation} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
