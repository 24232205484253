import styled from 'styled-components';

// Estilos do modal e fundo
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  background: #fff;
  padding: 20px;
  max-width: 800px;
  width: 100%;
  text-align: center;
`;

export const Title = styled.h2`
  color: #333;
  margin-bottom: 15px;
`;

export const Message = styled.p`
  color: #666;
  margin-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
`;

export const Button = styled.button`
  background: #1270fc;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s;

  &:hover {
    background: #0a5dbb;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;


export const InfoMessage = styled.p`
  color: #ff5722;
  margin-top: 10px;
  font-size: 14px;
`;
