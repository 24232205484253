import styled from 'styled-components';

const TagsPaymentType = {
    default: {
        color: '#919EAB',
        backgroundColor: '#F4F6F8',
    },
    paid: {
        color: '#118D57',
        backgroundColor: '#D3FCD2',
    },
    pending: {
        color: '#B76E00',
        backgroundColor: '#FFF5CC',
    }
}

export const WrapperContent = styled.div`
    background-color: #F9FAFB;
    border: 1px solid #DFE3E8;
    border-radius: 4px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    min-height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
`;

export const AppoimentTitle = styled.div`
    color: #212B36;
    font-size: 12px;
`;

export const Description = styled.div`
    font-size: 12px;
    color: #454F5B;
`;

export const PatientName = styled.span`
    font-size: 14px;
    color: #454F5B;
    font-weight: bold;
`;

export const CenterWrapper = styled.div`
    display: flex;
    gap: 2px;
    flex-direction: column;
`;

export const BottomWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DateWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
`;

export const DateLabel = styled.div`
    color: #637381;
    font-size: 14px;
    @media (max-width: 1270px) {
        font-size: 12px;
    }
`;

export const Icon = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
    @media (max-width: 1270px) {
        font-size: 14px;
    } 
`;

export const HourWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const HourLabel = styled.div`
    color: #637381;
    font-size: 14px;
    @media (max-width: 1270px) {
        font-size: 12px;
    }
`

interface ProfessionalTagsProps{
    backgroundColor: string;
}

export const ProfessionalTags = styled.span<ProfessionalTagsProps>`
    padding: 2px 6px;
    background-color: ${props => props.backgroundColor};
    text-transform: capitalize;
    align-self: flex-start;
    color: white;
    font-weight: bold;
    border-radius: 2px;
    transition: opacity 0.3s;
    font-size: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    min-width: 35px;
`

export interface DolarIconProps {
    type: keyof typeof TagsPaymentType;
}

export const DollarIcon = styled.span<DolarIconProps>`
    width: 20px;
    height: 20px;
    color: ${({ type }) => TagsPaymentType[type].color};
    font-size: 10px;
    font-weight: bold;
    border: 1px solid ${({ type }) => TagsPaymentType[type].color};
    background-color: ${({ type }) => TagsPaymentType[type].backgroundColor};
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 3px;
    margin-left: 5px;
    transition: box-shadow 0.3s ease;

    &:hover{
        box-shadow: 0 0 2px 2px ${({ type }) => TagsPaymentType[type].color + '55'}; 
    }
`

export const AppointmentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    div{
        display: flex;
        flex-direction: row;
    }
` 

export const AvatarImage = styled.img`
    width: 20px;
    height: 20px;
    object-fit: cover;
    position: relative;
    bottom: 3px;
    margin-left: 5px;
    border-radius: 50%;
    &:hover{
        box-shadow: 0 0 2px 2px #8E8D8D55; 
    }
`;
