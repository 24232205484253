import { IoCloseOutline } from "react-icons/io5";
import {
    HeaderTitle,
    HeaderWrapper,
} from "../Event/Create/style";

import { useEffect, useState } from "react";

import { 
    ContentDaysWrapper, 
    ContentWrapper, 
    DollarIcon, 
    ModalWrapper, 
    MonthContainer, 
    MonthTitleContainer, 
    MonthWrapper, 
    PaymentHeaderTitle, 
    PaymentInfo, 
    PaymentLabelWrapper,
} from "./style";

import ShowDays from "./components/ShowDays";
import { GetMonthMatrix } from "../../utils/GetDaysOfMonth";

interface Days {
    "Domingo"?: {
        date: string,
        available_slots: string[];
    }[],
    "Segunda"?: {
        date: string,
        available_slots: string[];
    }[],
    "Terça"?: {
        date: string,
        available_slots: string[];
    }[],
    "Quarta"?: {
        date: string,
        available_slots: string[];
    }[],
    "Quinta"?: {
        date: string,
        available_slots: string[];
    }[],
    "Sexta"?: {
        date: string,
        available_slots: string[];
    }[],
    "Sabado"?: {
        date: string,
        available_slots: string[];
    }[],
}

enum Meses {
    Janeiro = 1,
    Fevereiro = 2,
    Março = 3,
    Abril = 4,
    Maio = 5,
    Junho = 6,
    Julho = 7,
    Agosto = 8,
    Setembro = 9,
    Outubro = 10,
    Novembro = 11,
    Dezembro = 12
}

interface NextAvailableSlot {
    month: string,
    days: Days
}

interface DayInfo {
    dayInISO: string;
    dayNumber: number;
    isInMonth: boolean;
    dayName: string;
    month: number;
    availableSlots?: string[];
}

interface AvailableSlotsProps {
    closeModal: () => void;
    nextAvailableSlots: NextAvailableSlot[];
    setFormData: React.Dispatch<any>;
    setIsFreeHoursModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const AvailableSlots = ({ closeModal, nextAvailableSlots, setFormData, setIsFreeHoursModalOpen }: AvailableSlotsProps) => {
    const [months, setMonths] = useState([] as {name: string, totalFreeSlot: number, year: string}[])
    const [days, setDays] = useState({} as DayInfo[][])
    const [monthClicked, setMonthClicked] = useState('')
    const [showMonths, setShowMonths] = useState(true) 
    const [form, setForm] = useState({
        name: "",
        document: "",
        email: "",
        phone: "",
        address: "",
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const monthsMapped = nextAvailableSlots.map((slot) => {
            let totalAvailableSlots = 0;
            let year = '';
            for (let key in slot.days) {
                const keyConvert = key as 'Domingo' | 'Segunda' | 'Terça' | 'Quarta' | 'Quinta' | 'Sexta' | 'Sabado';
                const weekDayArray = slot.days[keyConvert]
                
                weekDayArray?.forEach(weekDay => {
                    totalAvailableSlots = weekDay.available_slots.length + totalAvailableSlots;
                    year = weekDay.date.split('-')[0] 
                })
            }

            return {
                name: slot.month,
                year: year,
                totalFreeSlot: totalAvailableSlots
            }
        })

        setMonths(monthsMapped)
    }, [])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };
    
    const getMonthFromEnum = (month: any) => Meses[month] 

    const handleMonthClick = (month: {name: string; totalFreeSlot: number, year: string}) => {
        const monthClicked = nextAvailableSlots.find(slot => slot.month == month.name)?.days
        
        const monthNumber = getMonthFromEnum(month.name);
        const monthMatrix = GetMonthMatrix(parseInt(month.year), parseInt(monthNumber));

        for (let key in monthClicked) {
            const keyConvert = key as 'Domingo' | 'Segunda' | 'Terça' | 'Quarta' | 'Quinta' | 'Sexta' | 'Sabado';
            const weekDayArray = monthClicked[keyConvert]
            
            weekDayArray?.forEach(weekDay => {
                const dayNumber = parseInt(weekDay.date.split('-')[2])

                for(let i = 0; i < monthMatrix.length; i++){
                    const indexObjectDayInMatrix = monthMatrix[i].findIndex(monthDay => {
                        return (monthDay.dayNumber == dayNumber) && monthDay.isInMonth;
                    }) 

                    if(indexObjectDayInMatrix < 0) continue

                    monthMatrix[i][indexObjectDayInMatrix].availableSlots = weekDay.available_slots;
                }
            })
        }

        setDays(monthMatrix)
        setMonthClicked(month.name)
        setShowMonths(false)
    }

    function adicionarUmaHora(strHora: any) {
        const [hora, minuto] = strHora.split(':');
        const data = new Date();
        data.setHours(parseInt(hora, 10));
        data.setMinutes(parseInt(minuto, 10)); 
        data.setHours(data.getHours() + 1);
        const novaHora = `${String(data.getHours()).padStart(2, '0')}:${String(data.getMinutes()).padStart(2, '0')}`;
        return novaHora;
    }

    const handleHourClicked = (day: DayInfo, hour: string) => {
        const actualDate = new Date();
        let date = new Date();
        date = new Date(day.dayInISO.split('T')[0] + 'T00:00:00');
        date.setHours(actualDate.getHours());
        date.setMinutes(actualDate.getMinutes());

        const hourT = hour.split(":")[0];
        const minutes = hour.split(":")[1];
        date.setHours(parseInt(hour));
        date.setMinutes(parseInt(minutes));

        setFormData((prevFormData: any) => ({
            ...prevFormData,
            ['time']: hour,
            start_date: date.toISOString().split('T')[0],
            start_time: date?.toISOString() || '',
            end_time: adicionarUmaHora(`${hourT}:${minutes}`),
        }));

        setIsFreeHoursModalOpen(false);
    }

    return (
        <ModalWrapper size="large">
            {showMonths && <>
                <HeaderWrapper>
                    <HeaderTitle>Mês de atendimento</HeaderTitle>
                    <IoCloseOutline
                        size={22}
                        onClick={closeModal}
                        color="#919EAB"
                        cursor={"pointer"}
                    />
                </HeaderWrapper>
                    <ContentWrapper>
                    {
                        months.map((month) => {
                            return (
                                <> 
                                    <MonthWrapper onClick={() => {handleMonthClick(month)}}>
                                        <PaymentHeaderTitle>
                                            <DollarIcon type="paid"> {month.totalFreeSlot} </DollarIcon>
                                        </PaymentHeaderTitle>

                                        <MonthContainer>
                                            <MonthTitleContainer>
                                                <PaymentLabelWrapper>
                                                    <PaymentInfo>
                                                        {month.name}
                                                    </PaymentInfo>
                                                </PaymentLabelWrapper>
                                            </MonthTitleContainer>
                                        </MonthContainer>
                                    </MonthWrapper>
                                </>
                            )
                        })
                    }               
                    </ContentWrapper>     
            </>
            }

            {
                !showMonths && <>
                    <HeaderWrapper>
                        <HeaderTitle>Horários disponíveis por dia de {monthClicked}</HeaderTitle>
                        <IoCloseOutline
                            size={22}
                            onClick={closeModal}
                            color="#919EAB"
                            cursor={"pointer"}
                        />
                    </HeaderWrapper>
                    <ContentDaysWrapper>
                        {
                            days.map((day, index) => {
                                if(!(days[index].find(day => day.isInMonth == true))) return

                                return (
                                    <div style={{display: 'flex', gap: '14px'}}>
                                        {
                                            day.map((objectDay, index) => {
                                                if(objectDay?.availableSlots?.length)
                                                    return (
                                                        <ShowDays handleHourClicked={(hour) => handleHourClicked(objectDay, hour)} dayToRender="Domingo" day={objectDay}/>
                                                    )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </ContentDaysWrapper>
                </>
            }
        </ModalWrapper>
    );
};

export default AvailableSlots;
