import {
  AmountContent,
  BottomContent,
  Content,
  HeaderContent,
  Title,
} from "./style";

interface ContentMoneyProps {
  title: string;
  img: any;
  amount: string;
}

export const ContentMoney = ({ title, img, amount }: ContentMoneyProps) => {
  return (
    <Content>
      <HeaderContent>
        <img src={img} />
      </HeaderContent>
      <BottomContent>
        <Title>{title}</Title>
        <AmountContent>{"R$ " + amount}</AmountContent>
      </BottomContent>
    </Content>
  );
};
