import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Control } from "../../pages/Control";
import FirstConsultation from "../../pages/FirstConsultation";
import Home from "../../pages/Home";
import { Wallet } from "../../pages/Wallet";

export const FinanceiroRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/control" Component={Control} />
          <Route
            path="/firstConsultation/:token"
            Component={FirstConsultation}
          />
          <Route path="/wallet" Component={Wallet} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
