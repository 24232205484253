import styled from "styled-components";

export const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
`

export const Label = styled.span`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
`

interface InfoProps {
    bold?: boolean
}

export const Info = styled.span<InfoProps>`
    font-size: 16px;
    font-style: normal;
    font-weight: ${props => props.bold ? 700 : 400};
`