import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-direction: "row";
    justify-content: space-between;
`;

export const LeftWrapper = styled.div`
    display: flex;
    gap: 20px;
`;

export const RightWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const WrapperImage = styled.div`
`;

export const WrapperInfos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const AmountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: 500;
    font-size: 17px;
`;

export const WrapperButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

export const ActionTitle = styled.div`
    color: #637381;
    font-weight: 500;
`;

export const PaymentTitle = styled.div`
    font-weight: 500;
    font-size: 17px;
    white-space: break-spaces;
`;

export const PaymentSubTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    white-space: break-spaces;
`;

export const DateWrapper = styled.div`
    font-size: 13px;
    color: #919EAB;
`;

export const ButtonIcon = styled.div`
    font-size: 23px;
    cursor: pointer;
`;

export const Divider = styled.div`
    border: 1px solid #DFE3E8;
    height: 35%;
    margin-left: 10px;
    margin-right: 10px;
`;

export const DateTitleWrapper = styled.div`
`;

export const DateTitle = styled.div`
`;

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const DebitAmountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: 500;
    font-size: 17px;
    color: #B71D18;
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const PaymentMethodWrapper = styled.div`
    display: flex;
    justify-content: end;
    align-items: end;
`;

export const PaymentMethod = styled.div`
    text-align: center;
    background-color: #1270FC;
    padding: 5px;
    color: white;
    border-radius: 5px;
    font-size: 12px;
    width: 90px;
`;

export const ValueWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;