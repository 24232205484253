import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import bridgesLogo from "../../assets/bridges.png";
import loginImage from "../../assets/login-image.png";
import {
  Description,
  Input,
  InputTitle,
  InputWrapper,
  Title,
  WrapperMid,
  WrapperLogin,
  WrapperPage,
  WrapperImg,
  WrapperRight
} from "./style";
import { RectangularButton } from "../../components/RectangularButton";
import api from "../../service/api";

export const RecoverPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleRecovery = () => {
    if (!validateEmail(email)) {
      toast.error("Por favor, insira um e-mail válido");
      return;
    }

    setIsLoading(true);
    api.post("/recover-password/", { email })
      .then(() => {
        toast.success("Instruções de recuperação de senha enviadas para seu e-mail.");
        navigate("/login");
      })
      .catch(() => {
        toast.error("Erro ao enviar e-mail de recuperação. Tente novamente.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <WrapperPage>
      <WrapperMid>
        <WrapperLogin>
          <WrapperImg>
            <img src={bridgesLogo} alt="Bridges Logo" />
          </WrapperImg>
          <Title>Insira seu e-mail</Title>
          <Description>Enviaremos um link para o seu e-mail para que você possa redefinir sua senha.</Description>
          <InputTitle>E-mail</InputTitle>
          <InputWrapper>
            <Input
              placeholder="Digite seu e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputWrapper>
          <RectangularButton
            onClick={handleRecovery}
            disabled={isLoading || !email || !validateEmail(email)}
            text={isLoading ? "Enviando..." : "Enviar"}
          />
        </WrapperLogin>
        <WrapperRight>
          <img src={loginImage} alt="Login" />
        </WrapperRight>
      </WrapperMid>
    </WrapperPage>
  );
};
