import styled from 'styled-components';

interface Occupationprops {
    isSelected: boolean;
}

export const Content = styled.div<Occupationprops>`
    border: ${(props) => props.isSelected ? "2px solid #1270FC" : "2px solid #d9d9d9" };
    transition: border-color 0.1s ease-out 0.1s;
    border-radius: 8px;
    padding: 10px;
    min-height: auto;
    display: flex;
    gap: 15px;
    flex-direction: column;
    cursor: pointer;
`;

export const HeaderWrapper = styled.div`
    display: flex;
`;

export const ProgressWrapper= styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    flex: 1;
`;

export const InfosWrapper = styled.div`
    display: flex;
    gap: 15px;
    margin-top: 10px;
    align-items: center;
    flex: 6;

    img {
        width: 40px;
    }
`;

export const Img = styled.img`
    border-radius: 40px;
`;

export const PersonalInfosWrapper = styled.div`
`;

export const OccupationTitle = styled.div`
    color: #637381;
`;

export const ProfessionalName = styled.div`
    margin-top: 7px;
    font-weight: 600;
    color: #454F5B;
`;

export const ButtonWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    margin-top: 12px;
    padding-left: 10px;
    padding-right: 10px;
`;

export const Icon = styled.div`
    font-size: 28px;
    color: #1270FC;
    cursor: pointer;
`;

export const ProgressLabel = styled.div`
    width: 100%;
    text-align: start;
    color: #919EAB;
    font-size: 14px;
`;