import { useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { InfoLabel } from "../../../components/InfoLabel";
import { Title } from "../../../components/InfoLabel/style";
import { customStyles } from "../../../components/Shared";
import { Tag } from "../../../components/Tags";
import formatCurrency from "../../../utils/formatCurrencyBr";
import {
    AverageInfoWrapper,
    DisplayBlock,
    EndWrapper,
    ExamsHeader,
    ExamsListItem,
    ExamsListWrapper,
    ExamsProfessionalCardInfo,
    Hour,
    HourAndValueWrapper,
    InfoWrapper,
    InitialAndEndTimeWrapper,
    InitialWrapper,
    ListItem,
    Value,
} from "./style";
import Modal from "react-modal";
import { animated, useSpring } from "react-spring";
import { HeaderTitle, HeaderWrapper } from "../../Patients/Profile/style";
import { IoCloseOutline } from "react-icons/io5";
interface ProfessionalInfos {
    ctime: Date;
    appointment?: any;
    paymentStatusClicked?: () => void;
    clientClicked?: () => void;
    appointmentClicked?: () => void;
    prontoClicked?: () => void;
    prontoLoading?: boolean;
}

export const ProfessionalInfos = ({ ctime, appointment, paymentStatusClicked, clientClicked, appointmentClicked, prontoClicked, prontoLoading }: ProfessionalInfos) => {
    const [modaDetaillIsOpen, setModaDetaillIsOpen] = useState(false)
    const [appointmentClickedState, setAppointmentClickedState] = useState(false)
    const [examsClickedState, setExamsClickedState] = useState(false)

    useEffect(() => {
        if(examsClickedState && appointmentClickedState)
            openExamsList()
        else
            if(appointmentClickedState)
                appointmentClicked?.()

        setAppointmentClickedState(false)
        setExamsClickedState(false)
    }, [appointmentClickedState, examsClickedState])

    const fadeDetail = useSpring({
        from: { opacity: 0 },
        to: { opacity: modaDetaillIsOpen ? 1 : 0 },
    });

    const openExamsList = () => {
        setModaDetaillIsOpen(true)
    }

    return (
        <>
            <Modal
                isOpen={modaDetaillIsOpen}
                onRequestClose={() => setModaDetaillIsOpen(false)}
                shouldCloseOnOverlayClick={true}
                style={customStyles}
            >
                <animated.div style={fadeDetail}>
                    <ExamsHeader>
                        <HeaderWrapper>
                            <HeaderTitle>Exames</HeaderTitle>
                            <IoCloseOutline
                                size={22}
                                onClick={() => setModaDetaillIsOpen(false)}
                                color="#919EAB"
                                cursor={"pointer"}
                            />
                        </HeaderWrapper>

                        <ExamsListWrapper>
                            {
                                appointment?.exams.map((exam: any, index: number) => {
                                    return (
                                        <ExamsListItem key={index} isEven={index%2==0}>
                                            {exam.name}
                                        </ExamsListItem>
                                    )
                                })
                            }
                        </ExamsListWrapper>
                    </ExamsHeader>
                </animated.div>
            </Modal>
            <HourAndValueWrapper>
                <Hour color={appointment.status.color}>{appointment?.start.split(/[-T:]/)[3] + ':' + appointment?.start.split(/[-T:]/)[4]}</Hour>
                <Value>
                    <InfoLabel label="Valor" value={formatCurrency((appointment.service.price * 100 || '000').toString())} />
                </Value>
                <CustomButton onClick={() => prontoClicked?.()} theme="pacientePronto" disabled={prontoLoading}>
                    Pronto
                </CustomButton>

            </HourAndValueWrapper>

            <InfoWrapper>
                <AverageInfoWrapper style={{ paddingBottom: '4px' }}>
                    <DisplayBlock onClick={() => clientClicked?.()}>
                        <InfoLabel
                            foco={true}
                            label="Paciente"
                            value={appointment?.paciente}
                            alert={appointment?.patient?.has_pendency}
                        />
                    </DisplayBlock>
                    <DisplayBlock onClick={() => clientClicked?.()}>
                        <InfoLabel label="Cliente" value={appointment?.patient.tag?.name || 'Normal'} tagStyle color={appointment?.patient.tag?.color} />
                    </DisplayBlock>
                </AverageInfoWrapper>
                <InitialAndEndTimeWrapper onClick={() => setAppointmentClickedState(true)}>
                    <InitialWrapper>
                        <ListItem />
                        <ExamsProfessionalCardInfo>
                            <div>
                                <InfoLabel
                                    label="Inicio da Consulta"
                                    value={
                                        "De " +
                                        new Date(appointment?.start).toLocaleString("pt-BR", {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        })
                                    }
                                />
                            </div>

                            {appointment?.exams.length > 0 && <DisplayBlock onClick={() => setExamsClickedState(true)}>
                                <InfoLabel label="Exames" value={appointment?.exams.length} tagStyle color={'#1270FC'} />
                            </DisplayBlock>}
                        </ExamsProfessionalCardInfo>
                    </InitialWrapper>

                    <EndWrapper>
                        <ListItem />
                        <div>
                            <InfoLabel
                                label="Fim da Consulta"
                                value={
                                    "Até " +
                                    new Date(appointment?.end).toLocaleString("pt-BR", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })
                                }
                            />
                        </div>
                    </EndWrapper>
                </InitialAndEndTimeWrapper>

                <AverageInfoWrapper onClick={() => appointmentClicked?.()}>
                    <DisplayBlock>
                        <InfoLabel label="Consulta" value={appointment?.service.name} />
                    </DisplayBlock>
                    <DisplayBlock>
                        <InfoLabel label="Profissional" value={appointment.professional.name} />
                    </DisplayBlock>
                </AverageInfoWrapper>

                <AverageInfoWrapper style={{ alignItems: 'center' }} onClick={() => paymentStatusClicked?.()}>
                    <Title>
                        Pagamento
                    </Title>
                    <DisplayBlock>
                        <Tag
                            type={appointment?.status.name == "Pago" ? "paid" : "pending"}
                            text={appointment?.status.name == "Pago" ? "Pago" : appointment?.status.name == "Pagamento Parcial" ? 'Parcial' : 'Em aberto'}
                        />
                    </DisplayBlock>
                </AverageInfoWrapper>
            </InfoWrapper>
        </>
    );
};
