import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const Wrapper = styled(Button)`
    background-color: #1270FC;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    text-transform: capitalize;
    
    &:hover {
        background-color:#3585fd;
        transition: background-color 0.2s ease-in-out;
    }
`