import styled from 'styled-components';

interface ButtonTransactionProps {
    isChecked: boolean;
}

export const WrapperTransactionButtons = styled.div `
    display: flex;
    flex-direction: row;
    border: 1px solid #919EAB52;
    border-radius: 4px;
    height: 50px;
    margin-top: 5px;
    
`;

export const ButtonTransactionType = styled.div<ButtonTransactionProps>`
    flex: 1;
    background-color: ${(prop) => prop.isChecked ? "#1270FC" : ""};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 15px;
    font-weight: 500;
    color: ${(prop) => prop.isChecked ? "white" : ""};
    cursor: pointer;    
`;

export const TransactionButtonsTitle = styled.div`
    color: #637381;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
`;

export const Img = styled.img`
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding: 24px 0;
`;

export const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 10px;
`;