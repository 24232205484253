import styled from "styled-components";

export const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
`;

export const Title = styled.label`
  font-weight: 600;
  text-align: center;
`;

export const ErrorMessage = styled.label`
  text-align: center;
`;

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-weight: 400;
`;

export const Image = styled.img`
  width: 90px;
`;

export const ErrorListWrapper = styled.div`
  margin-top: 10px;
  text-align: center;
  gap: 5px;
`;
