import { ContentMoney } from "../../components/ContentMoney";
import PageStructure from "../../components/PageStructure";
import { HeaderPage } from "../../components/Shared/HeaderPage";
import { RefreshLabel, SubTitle } from "../Control/style";
import {
  Button,
  ButtonChart,
  ButtonChartWrapper,
  ChartWrapper,
  DataHeaderWrapper,
  DataWrapper,
  DateTitle,
  HeaderData,
  IconButton,
  LeftContentHeader,
  MovimentListWrapper,
  PageContent,
  RightContentHeader,
  SideHistoryContent,
  SubHeaderWrapper,
  SubTitleInfosWrapper,
  TextButton,
  ValuesContent,
  customStyles,
} from "./syle";
import MoneyReceive from "../../assets/money-recive.svg";
import MoneySend from "../../assets/money-send.svg";
import PendencyMoney from "../../assets/pendency-money.svg";
import { HistoryMoviment } from "../../components/HistoryMoviments";
import { useEffect, useRef, useState } from "react";
import { mock } from "./mock";
import { groupTransactionsByDate } from "../../utils/groupTransactionsByDate";
import { DropdownItemNew } from "../../components/CustomDropDown/style";
import { FaPlus, FaRegCalendarCheck } from "react-icons/fa6";
import { CreateTransactionHistory } from "../../modals/CreateTransactionHistory";
import Modal from "react-modal";
import { Profit } from "../../components/Charts/Profit";
import ProfitMonths from "../../components/Charts/ProfitMonths";
import { ProfitWeekly } from "../../components/Charts/ProftWeekly";
import api from "../../service/api";
import { getFirstAndLastDayOfWeek } from "../../utils/getFirstAndLastDayOfWeek";
import { getFirstAndLastDayOfMonth } from "../../utils/getFirstAndLastDayOfMonth";
import formatCurrency from "../../utils/formatCurrencyBr";
import { formatDecimalValues } from "../../utils/formatDecimalValues";
import CustomInput from "../../components/CustomInput";
import { HiMagnifyingGlass, HiOutlineUserGroup } from "react-icons/hi2";
import { MenuButton } from "../../components/SideMenu/MenuButton";
import CustomDropdown from "../../components/CustomDropDown";
import { IoFilterOutline } from "react-icons/io5";
import { ListEmptyLabel, ListEmptyWrapper } from "../Today/style";
import { LogoWrapper } from "../../components/SideMenu/style";
import { AiOutlineInbox } from "react-icons/ai";

interface Transaction {
  id: number;
  title: string;
  type: string;
  description: string;
  amountValue: string;
  date: string;
}

const options = [
  'Selecione...',
  'Similaridade',
  'Profissional',
  'Paciente',
  'Estabelecimento',
  'Serviço',
  'Observação',
  'Valor'
]

interface PaymentData {
  date: string;
  in: number;
  out: number;
  pend: number;
  payments: any[];
}

interface CondensedData {
  date: string[];
  in: number[];
  out: number[];
  pend: number[];
}

const monthNames = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];

function processPaymentData(data: PaymentData[]): CondensedData {
  const dates: string[] = [];
  const inValues: number[] = [];
  const outValues: number[] = [];
  const pendValues: number[] = [];

  if (data.length < 60) {
    data.forEach(d => {
      const [day, month, year] = d.date.split('/');
      const formattedDate = `${parseInt(day)} de ${monthNames[parseInt(month) - 1]}`;
      dates.push(formattedDate);
      inValues.push(d.in);
      outValues.push(d.out);
      pendValues.push(d.pend);
    });
  } else {
    const monthlyData: { [key: string]: { in: number; out: number; pend: number } } = {};

    data.forEach(d => {
      const [day, month, year] = d.date.split('/');
      const monthYear = `${month}/${year}`;

      if (!monthlyData[monthYear]) {
        monthlyData[monthYear] = { in: 0, out: 0, pend: 0 };
      }
      monthlyData[monthYear].in += d.in;
      monthlyData[monthYear].out += d.out;
      monthlyData[monthYear].pend += d.pend;
    });

    for (const monthYear in monthlyData) {
      const [month, year] = monthYear.split('/');
      const formattedMonthYear = `${monthNames[parseInt(month) - 1]}/${year}`;
      dates.push(formattedMonthYear);
      inValues.push(monthlyData[monthYear].in);
      outValues.push(monthlyData[monthYear].out);
      pendValues.push(monthlyData[monthYear].pend);
    }
  }

  return {
    date: dates,
    in: inValues,
    out: outValues,
    pend: pendValues
  };
}

export const Wallet = () => {
  const [transactionsByDate, setTransactionsByDate] = useState<{[date: string]: Transaction[]}>({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sortChart, setSortChart] = useState("");
  const [receiveValue, setReceiveValue] = useState("");
  const [outValue, setOutValue] = useState("");
  const [pendencyValue, setPendencyValue] = useState("");
  const [filterValue, setFilterValue] = useState("Similaridade");
  const [monthChartData, setMonthChartData] = useState<any[]>([]);
  const [labels, setLabels] = useState<any[]>([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const paymentsBackup = useRef<any>(null);

  const fetchHistory = () => {
    let days = getFirstAndLastDayOfMonth();
    if (startDate) days.firstDay = startDate;
    if (endDate) days.lastDay = endDate;

    api.get(`/payments?start_date=${days.firstDay}&end_date=${days.lastDay}`).then((resp) => {
      const mappedReturn = groupTransactionsByDate(resp.data);
      paymentsBackup.current = mappedReturn || {};  
      setTransactionsByDate(mappedReturn);
    });
  };

  useEffect(() => {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    setEndDate(formatDate(today));
    setStartDate(formatDate(sevenDaysAgo));
    // fetchMonthData();
  }, []);

  useEffect(() => {
    if (paymentsBackup.current) {
      handleFilter('');
    }

    fetchMonthData()
    fetchHistory()
  }, [startDate, endDate]);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const fetchMonthData = () => {
    let days = getFirstAndLastDayOfMonth();
    if (startDate) days.firstDay = startDate;
    if (endDate) days.lastDay = endDate;

    api.get(`/dashboard/payments/${days.firstDay}/${days.lastDay}/`).then(async (resp) => {
      setReceiveValue(formatDecimalValues(resp.data.total_in));
      setOutValue(formatDecimalValues(resp.data.total_out));
      setPendencyValue(formatDecimalValues(resp.data.total_pend));

      const paymentData = processPaymentData(resp.data.results)

      const monthChartData = [
        { name: "Entradas", data: paymentData.in },
        { name: "Saidas", data: paymentData.out },
        { name: "Pendencias", data: paymentData.pend },
      ];

      setMonthChartData(monthChartData);
      setLabels(paymentData.date);
    });
  };

  const translateToKeyInObject = (option: string) => {
    switch (option) {
      case "Profissional":
        return "professional";
      case "Paciente":
        return "patient";
      case "Estabelecimento":
        return "establishment";
      case "Serviço":
        return "service";
      case "Observação":
        return "obs";
      case "Valor":
        return "value";
      default:
        return "";
    }
  };

  const containsSearchTerm = (obj: any, searchTerm: string, searchInto?: string): boolean => {
    for (let key in obj) {
      const keyToFilter = translateToKeyInObject(filterValue)!;

      if (filterValue !== "Similaridade" && key !== "appointment" && searchInto !== keyToFilter) {
        if (key !== keyToFilter) continue;
      }

      if (typeof obj[key] === "string" && obj[key].toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }

      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (containsSearchTerm(obj[key], searchTerm, key)) {
          return true;
        }
      }
    }

    return false;
  };

  const handleFilter = (searchTerm: string) => {
    if (!paymentsBackup.current) {
      return;
    }

    if (searchTerm === '' && startDate === '' && endDate === '') {
      setTransactionsByDate(paymentsBackup.current);
      fetchMonthData();
      return;
    }

    if(searchTerm === ''){
      setTransactionsByDate(paymentsBackup.current);
      return
    }

    let filteredKeys = {} as any;

    Object.keys(paymentsBackup.current).forEach(item => {

      const transactions = paymentsBackup.current[item].filter((transaction: Transaction) => {
        const transactionDate = new Date(transaction.date);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;

        // const isWithinDateRange = (!start || transactionDate >= start) && (!end || transactionDate <= end);
        const matchesSearchTerm = containsSearchTerm(transaction, searchTerm);

        return matchesSearchTerm;
      });

      if (transactions.length > 0) {
        filteredKeys[item] = transactions;
      }
    });

    setTransactionsByDate(filteredKeys);

    fetchMonthData();
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <CreateTransactionHistory
          closeModal={() => setModalIsOpen(false)}
          updateClients={() => {}}
          fetchFunction={fetchHistory}
          sortChart={sortChart}
        />
      </Modal>
      <PageStructure>
        <HeaderPage
          title="Carteira"
          buttonRight={
            <Button onClick={() => setModalIsOpen(true)}>
              <IconButton>
                <FaPlus />
              </IconButton>
              <TextButton>Registrar movimentação</TextButton>
            </Button>
          }
        />
        <SubHeaderWrapper>
          <LeftContentHeader>
            <SubTitleInfosWrapper>
              <SubTitle>Lucros no período</SubTitle>
            </SubTitleInfosWrapper>
            <HeaderData>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ margin: '0 8px' }}>de</span>
              <div style={{ width: '140px', height: '30px'}}>
                <CustomInput
                  name="start_date"
                  type="date"
                  value={startDate}
                  inputStyle={{height: '30px'}}
                  max={new Date().toISOString().split('T')[0]}
                  // max={nextAvailableSlots?.[nextAvailableSlots.length-1]?.days[] ? new Date(nextAvailableSlots?.[nextAvailableSlots.length-1]?.date).toISOString().split('T')[0] : undefined}
                  onChange={e => setStartDate(e.currentTarget.value)}
                />
              </div>
              <span style={{ margin: '0 8px' }}>até</span>
              <div style={{ width: '140px', height: '30px'}}>
                <CustomInput
                  name="start_date"
                  type="date"
                  value={endDate}
                  inputStyle={{height: '30px'}}
                  max={new Date().toISOString().split('T')[0]}
                  onChange={e => setEndDate(e.currentTarget.value)}
                />
              </div>
            </div>
            </HeaderData>
          </LeftContentHeader>
          <RightContentHeader>
            <CustomInput
              onChange={(e) => handleFilter(e.currentTarget.value)}
              name=""
              disableBorder
              placeholder="Pesquisar..."
              leftIcon={
                <HiMagnifyingGlass size={20} color="var(--primary-icon-color)" />
              }
            />
            <div style={{ width: "40%" }}>
              <CustomDropdown
                disableBorder
                setSelectedOption={(value) => {
                  setFilterValue(value);
                }}
                selectedOption={filterValue}
                options={options}
                allowCreate={false}
                leftIcon={
                  <IoFilterOutline size={16} color="var(--primary-icon-color)" />
                }
              />
            </div>
          </RightContentHeader>
        </SubHeaderWrapper>
        <PageContent>
          <DataHeaderWrapper>
            <DataWrapper>
              <ValuesContent>
                <ContentMoney
                  title="Entradas"
                  amount={receiveValue}
                  img={MoneyReceive}
                />
                <ContentMoney
                  title="Saidas"
                  amount={outValue}
                  img={MoneySend}
                />
                <ContentMoney
                  title="Pendências"
                  amount={pendencyValue}
                  img={PendencyMoney}
                />
              </ValuesContent>
              <ChartWrapper>
                <Profit data={monthChartData} labelsExtern={labels}/>
              </ChartWrapper>
            </DataWrapper>
          </DataHeaderWrapper>
          <SideHistoryContent>
            <SubTitle>Movimentações</SubTitle>
            <MovimentListWrapper>
              {Object.keys(transactionsByDate).length ? Object.keys(transactionsByDate).map((item) => {
                return (
                  <div key={item}>
                    <DateTitle>{item}</DateTitle>
                    <HistoryMoviment date={transactionsByDate[item]} />
                  </div>
                );
              })
              :
              <ListEmptyWrapper style={{ marginTop: '10px' }}>
                <AiOutlineInbox style={{ fontSize: "24px", color: "#ccc" }} />
                <ListEmptyLabel> Sem registros no período! </ListEmptyLabel>
              </ListEmptyWrapper>
            }
            </MovimentListWrapper>
          </SideHistoryContent>
        </PageContent>
      </PageStructure>
    </>
  );
};
