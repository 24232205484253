import { format } from "date-fns"; // Importe o format de date-fns
import { useEffect, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoEyeOutline } from "react-icons/io5";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { toast } from "react-toastify";
import CustomButton from "../../components/CustomButton";
import CustomTable from "../../components/CustomTable";
import PageStructure from "../../components/PageStructure";
import { IconWrapper, customStyles } from "../../components/Shared";
import { HeaderPage } from "../../components/Shared/HeaderPage";
import CreateProfessional from "../../modals/CreateProfessional";
import api from "../../service/api";
import { Icon, SearchInput, WrapperSearchInput } from "./style";

export const ProfessionalsPage = () => {
  const navigate = useNavigate();

  const [data, setData] = useState<any>([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [professionalValue, setProfessionalValue] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "CPF",
        accessor: "document",
      },
      {
        Header: "Profissional desde",
        accessor: "created_at",
        Cell: ({ value }: { value: string }) => (
          <span>{format(new Date(value), "dd/MM/yyyy HH:mm")}</span>
        ),
      },
      {
        Header: "Telefone",
        accessor: "phone",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Ações",
        accessor: "",
        Cell: (data: any) => {
          return (
            <IconWrapper
              onClick={() =>
                navigate("details", {
                  state: { professional: data.row.original },
                })
              }
            >
              <IoEyeOutline size={20} color="var(--primary-icon-color)" />
            </IconWrapper>
          );
        },
      },
    ],
    []
  );

  const fetchProfessionals = () => {
    let toastLoading = toast.loading("Carregando profissionais...", {
      className: "toast-loading",
    });

    api
      .get("/professionals")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        toast.error("Erro ao carregar profissionais!");
      })
      .finally(() => {
        toast.dismiss(toastLoading);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      let toastLoading = toast.loading("Carregando profissional...", {
        className: "toast-loading",
      });

      api
        .get(`/professionals/?name=${professionalValue}`)
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          toast.error("Erro ao carregar profissional!");
        })
        .finally(() => {
          toast.dismiss(toastLoading);
        });
    }, 500);

    return () => clearTimeout(timeout);
  }, [professionalValue]);

  useEffect(() => {
    fetchProfessionals();
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalIsOpen ? 1 : 0 },
  });

  return (
    <PageStructure>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fade}>
          <CreateProfessional
            closeModal={closeModal}
            updateClients={fetchProfessionals}
          />
        </animated.div>
      </Modal>
      <HeaderPage
        title="Profissionais"
        buttonRight={
          <CustomButton onClick={() => setIsOpen(true)} theme="addPrimary">
            Registrar Profissional
          </CustomButton>
        }
      />
      <WrapperSearchInput>
        <SearchInput
          placeholder="Pesquise pelo nome do profissional"
          onChange={(e) => setProfessionalValue(e.target.value)}
        />
        <Icon>
          <FiSearch />
        </Icon>
      </WrapperSearchInput>
      <CustomTable columns={columns} columnMinWidths={{}} data={data} maxHeight="none"/>
      {/* <Pagination
        totalPages={1}
        onPageChange={(pageNumber) => {
          
        }}
      /> */}
    </PageStructure>
  );
};

export default ProfessionalsPage;
