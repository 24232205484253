import React, { useState } from 'react';
import { comp } from "./style";

interface TextObject { 
  id: number; 
  messageToShow: string; 
  questionId?: number 
}

interface RadioButtonsProps {
  options: TextObject[];
  questionId?: number;
  onChangeParent: (option: {optionSelectedText: TextObject, questionId?: number}) => void;
  showChooseLabel?: string; 
}

const RadioButtons: React.FC<RadioButtonsProps> = ({ options, onChangeParent, questionId, showChooseLabel }) => {
  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  const handleOptionChange = (option: TextObject) => {
    setSelectedOption(option.id);
    onChangeParent({
      questionId: questionId,
      optionSelectedText: option,
    });
  };
  
  return (
    <comp.RadioContainer>
    {showChooseLabel && <comp.Label>{showChooseLabel}</comp.Label>}

    {options.map((option, index) => (
      <comp.RadioOption key={index} onClick={() => {handleOptionChange(option)}}>
        <comp.RadioInput
          type="radio"
          id={String(option.id)}
          name="radioOptions"
          value={option.id}
          onChange={() => {
          }}
        />
        <comp.CustomRadio 
          checked={selectedOption === option.id}
        />
        <comp.RadioLabel checked={selectedOption === option.id}>
          {option.messageToShow}
        </comp.RadioLabel>
      </comp.RadioOption>
    ))}
  </comp.RadioContainer>
  );
};

export default RadioButtons;
