import {
  Bar,
  Bg,
  Content,
  FinalValue,
  Progress,
  Value,
  ValuesWrapper
} from "./style";

interface ProgressBarProps {
  currentValue: number;
  finalValue: number;
}

export const ProgressBar = ({ currentValue, finalValue }: ProgressBarProps) => {
  return (
    <Content>
      <ValuesWrapper>
        {/* <InitialValue>0</InitialValue> */}
        <FinalValue>{finalValue}</FinalValue>
      </ValuesWrapper>
      <Bar>
        <Value currentValue={currentValue}>{currentValue}</Value>
        <Progress currentValue={currentValue} />
        <Bg />
      </Bar>
    </Content>
  );
};
