import { useParams } from "react-router-dom";
import { comp } from "./style";
import MessageBalloon from "./components/MessageBalloon";
import React, { useEffect, useRef, useState } from "react";
import RadioButtons from "../../components/RadioButtons";
import ChatAvatar from "./components/ChatAvatar";
import api from "../../service/api";
import { lastQuestion, firstQuestion } from './questions'

enum Profile{
  doctor,
  patient,
}

interface ConsultationQuestions {
  id: number;
  questions: {
      id: number;
      answers: {
        id: number; 
        messageToShow: string; 
        questionId?: number 
      }[];
      question: string;
      preform: number;
  }[];
}

interface TextObject{ 
  questionId?: number;
  optionSelectedText: Answer;
}

interface Answer { 
  id: number; 
  messageToShow: string; 
  questionId?: number 
  question?: number;
}

interface Message{
  from: Profile;
  content: React.ReactNode;
  messageId?: number;
  isTyping: boolean;
  hourSended: Date;
  answers?: React.ReactNode;
  questionId?: number;
  failState?: boolean;
  lastQuestion?: boolean;
  isQuitting?: boolean;
}

export const FirstConsultation = () => {
  const { token } = useParams();
  const [messages, setMessages] = useState([] as Message[])
  const [answersGiven, setAnswersGiven] = useState([] as TextObject[])
  const [professionalName, setProfessionalName] = useState('')
  const [clientName, setClientName] = useState('')
  const [client, setClient] = useState({} as any)
  const [avatarImageSrc, setAvatarImageSrc] = useState('')
  const answersGivenRef = useRef([] as TextObject[])
  const messagesRef = useRef(messages);
  const questionsRef = useRef({} as ConsultationQuestions);
  const step = useRef(0);
  const showChooseLabel = 'Escolha uma alternativa'

  const fetchQuestions = async () => {
    await api.get(`/preform/${token}/`).then((response) => {
      setProfessionalName(response.data?.professional?.name)
      setClientName(response.data?.client?.name?.split(' ')[0])
      setClient(response.data?.client)
      setAvatarImageSrc(response.data?.professional?.photo)
      const questions = response.data?.preform?.questions

      questions.forEach((question: any, index: number) => {
        const answersMapped = question.answers.map((answer: any) => {
          return {
            id: answer.id,
            messageToShow: answer.answer,
            questionId: answer.question 
          }
        })

        questions[index].answers = answersMapped;
      })

      questionsRef.current = {
        ...response.data,
        questions: [
          ...firstQuestion,
          ...questions
        ]
      };
    });
  }

  useEffect(() => {
    answersGivenRef.current = [];
    messagesRef.current = [];
    questionsRef.current = {} as ConsultationQuestions;

    fetchQuestions()
  }, [])
  
  useEffect(() => {
    if(!clientName || !professionalName || !avatarImageSrc) return

    setMessages([
      {
        from: Profile.doctor,
        content: `Olá ${clientName}, sou Dr. ${professionalName} e vamos fazer uma pré-consulta para que eu possa entender mais do seu momento atual. Tudo bem?`,
        isTyping: true,
        hourSended: new Date()
      }
    ]);

    const timeout = setTimeout(() => {
      messagesRef.current[messagesRef.current.length-1].isTyping = false;

      setMessages([
        ...messagesRef.current,
        {
          from: Profile.doctor,
          content: 'A pré-consulta vai ser da seguinte forma. Vou te fazendo algumas perguntas e irá aparecer opções objetivas para você descrever para mim a situação do seu momento atual.',
          isTyping: true,
          hourSended: new Date()
        },
      ])

      setTimeout(() => {
        messagesRef.current[messagesRef.current.length-1].isTyping = false;
  
        setMessages([
          ...messagesRef.current,
          {
            from: Profile.doctor,
            content: "Aqui vai uma demonstração. Assim que você escolher uma das opções, nossa pré consulta vai começar.",
            answers: <RadioButtons showChooseLabel={showChooseLabel} options={firstQuestion[0].answers} questionId={firstQuestion[0].id} onChangeParent={handleAnswerClicked}/>,
            isTyping: true,
            hourSended: new Date(),
            questionId: -1,
          },
        ])

        setTimeout(() => {
          messagesRef.current[messagesRef.current.length-1].isTyping = false;
    
          setMessages([
            ...messagesRef.current,
          ])
        }, 3000)
      }, 2000)
    }, 1000)

    return () => {clearTimeout(timeout)}
  }, [clientName, professionalName, avatarImageSrc])

  useEffect(() => {
    messagesRef.current = messages;
    let element = document.getElementById("scrolledChat") as any;

    if (!element) return;

    element.scrollTop = element?.scrollHeight;
  }, [messages]);

  useEffect(() => {
    answersGivenRef.current = answersGiven;
  }, [answersGiven]);

  const showNextQuestionAfterAnswer = (answer: TextObject) => {
    setMessages([
      ...messagesRef.current,
      {
        from: Profile.patient,
        content: answer.optionSelectedText.messageToShow,
        isTyping: false,
        hourSended: new Date(),
      },
      {
        from: Profile.doctor,
        content: questionsRef.current.questions[step.current+1].question,
        answers: <RadioButtons showChooseLabel={showChooseLabel} options={questionsRef.current.questions[step.current+1].answers} onChangeParent={handleAnswerClicked} questionId={questionsRef.current.questions[step.current+1].id}/>,
        isTyping: true,
        hourSended: new Date(),
        questionId: questionsRef.current.questions[step.current+1].id
      },
    ]);

    setTimeout(() => {
      messagesRef.current[messagesRef.current.length-1].isTyping = false;

      setMessages([
        ...messagesRef.current,
      ])
    }, 1000)

    step.current = step.current >= questionsRef.current.questions.length-1 ? step.current : step.current+1
  }

  const showNextQuestion = () => {
    setMessages([
      ...messagesRef.current,
      {
        from: Profile.doctor,
        content: questionsRef.current.questions[step.current+1].question,
        answers: <RadioButtons showChooseLabel={showChooseLabel} options={questionsRef.current.questions[step.current+1].answers} onChangeParent={handleAnswerClicked} questionId={questionsRef.current.questions[step.current+1].id}/>,
        isTyping: true,
        hourSended: new Date(),
        questionId: questionsRef.current.questions[step.current+1].id
      },
    ]);

    setTimeout(() => {
      messagesRef.current[messagesRef.current.length-1].isTyping = false;

      setMessages([
        ...messagesRef.current,
      ])
    }, 1000)

    step.current = step.current >= questionsRef.current.questions.length-1 ? step.current : step.current+1
  }

  const handleAnswerClicked = (answer: TextObject) => {
    const messagesBackup = [...messagesRef.current]

    if(answersGivenRef.current.find(answerGiven => answerGiven.questionId === answer.questionId) !== undefined){ 
      if(answer.questionId !== -1){
        const messageIndex = messagesRef.current.findIndex(message => message.questionId == answer.questionId)
        const messageIdToPatch = messagesRef.current[messageIndex+1].messageId;
        api.patch(`/user/preforms/${messageIdToPatch}/`, {
          "user": client?.id,
          "question": answer.questionId,
          "answer": answer.optionSelectedText.id
        })
        .then(() => {
          const index = answersGivenRef.current.findIndex(answerGiven => answerGiven.questionId === answer.questionId);
          answersGivenRef.current[index] = answer;
          
          setAnswersGiven([...answersGivenRef.current])
          const messageIndex = messagesRef.current.findIndex((message) => message.questionId === answer.questionId);
          messagesRef.current[messageIndex+1].content = answer.optionSelectedText.messageToShow;
          if(messagesRef.current[messageIndex+1]?.failState) messagesRef.current[messageIndex+1].failState = false

          // if(step.current >= questionsRef.current.questions.length-1) {
          //   setMessages([
          //     ...messagesRef.current,
          //     {
          //       from: Profile.doctor,
          //       content: lastQuestion[0].question,
          //       answers: <RadioButtons showChooseLabel={showChooseLabel} options={lastQuestion[0].answers} questionId={lastQuestion[0].id} onChangeParent={handleAnswerClicked}/>,  
          //       isTyping: true,
          //       hourSended: new Date(),
          //       lastQuestion: true,
          //     }
          //   ])

          //   setTimeout(() => {
          //     messagesRef.current[messagesRef.current.length-1].isTyping = false;
        
          //     setMessages([
          //       ...messagesRef.current,
          //     ])
          //   }, 1000)
          // }else{
          //   showNextQuestion();
          // }
        })
        .catch(() => {
          const index = answersGivenRef.current.findIndex(answerGiven => answerGiven.questionId === answer.questionId);
          answersGivenRef.current[index] = answer;
          
          setAnswersGiven([...answersGivenRef.current])
          const messageIndex = messagesRef.current.findIndex((message) => message.questionId === answer.questionId);
          messagesRef.current[messageIndex+1].content = answer.optionSelectedText.messageToShow;
          messagesRef.current[messageIndex+1].failState = true;
          setMessages([...messagesRef.current])
        })
      }

      return
    }

    setAnswersGiven([...answersGivenRef.current, answer]);

    if(step.current >= questionsRef.current.questions.length-1) {
      api.post('/user/preforms/', {
        "user": client?.id,
        "question": answer.questionId,
        "answer": answer.optionSelectedText.id
      })
      .then((res) => {
        setMessages([
          ...messagesRef.current,
          {
            from: Profile.patient,
            content: answer.optionSelectedText.messageToShow,
            messageId: res?.data?.id || 0, 
            isTyping: false,
            hourSended: new Date(),
          },
          {
            from: Profile.doctor,
            content: lastQuestion[0].question,
            answers: <RadioButtons showChooseLabel={showChooseLabel} options={lastQuestion[0].answers} questionId={lastQuestion[0].id} onChangeParent={handleAnswerClicked}/>,  
            isTyping: true,
            hourSended: new Date(),
            lastQuestion: true,
          }
        ])

        setTimeout(() => {
          messagesRef.current[messagesRef.current.length-1].isTyping = false;
    
          setMessages([
            ...messagesRef.current,
          ])
        }, 1000)
      })
      .catch(() => {
        setMessages([
          ...messagesBackup,
          {
            from: Profile.patient,
            content: answer.optionSelectedText.messageToShow,
            isTyping: false,
            hourSended: new Date(),
            failState: true
          },
        ]);
      })

      return
    } 

    if(answer.questionId !== -1){
      api.post('/user/preforms/', {
        "user": client?.id,
        "question": answer.questionId,
        "answer": answer.optionSelectedText.id
      })
      .then((res) => {
        setMessages([
          ...messagesRef.current,
          {
            from: Profile.patient,
            content: answer.optionSelectedText.messageToShow,
            messageId: res?.data?.id || 0,
            isTyping: false,
            hourSended: new Date(),
          },
          {
            from: Profile.doctor,
            content: questionsRef.current.questions[step.current+1].question,
            answers: <RadioButtons showChooseLabel={showChooseLabel} options={questionsRef.current.questions[step.current+1].answers} onChangeParent={handleAnswerClicked} questionId={questionsRef.current.questions[step.current+1].id}/>,
            isTyping: true,
            hourSended: new Date(),
            questionId: questionsRef.current.questions[step.current+1].id
          },
        ]);
    
        setTimeout(() => {
          messagesRef.current[messagesRef.current.length-1].isTyping = false;
    
          setMessages([
            ...messagesRef.current,
          ])
        }, 1000)
    
        step.current = step.current >= questionsRef.current.questions.length-1 ? step.current : step.current+1
      })
      .catch(() => {
        setMessages([
          ...messagesBackup,
          {
            from: Profile.patient,
            content: answer.optionSelectedText.messageToShow,
            isTyping: false,
            hourSended: new Date(),
            failState: true
          },
        ]);
      })

      return
    }

    showNextQuestionAfterAnswer(answer)
  }

  const handleLastMessage = () => {
        setMessages([
      ...messagesRef.current,
      {
        from: Profile.doctor,
        content: `Foi um prazer, ${clientName}! Até breve!`,
        isTyping: true,
        hourSended: new Date(),
      }
    ]);

    setTimeout(() => {
      messagesRef.current[messagesRef.current.length-1].isTyping = false;

      setMessages([
        ...messagesRef.current,
      ])
    }, 1000)


    setTimeout(() => {
      messagesRef.current[messagesRef.current.length-1].isQuitting = true;

      setMessages([
        ...messagesRef.current,
      ])
    }, 2000)
  }

  return (
    <comp.PageWrapper id='scrolledChat'>
      {
        messages.map((message, index) => {
          return (
            <div key={index}>
              {
                index === 0 &&
                <comp.ChatAdvice> 
                  <comp.Name> {`${professionalName} entrou no Chat`} </comp.Name>  
                  <comp.LongLine /> 
                </comp.ChatAdvice>
              }
              {
                message.from === Profile.doctor ?
                  <div>
                    <comp.ProfessionalMessageContainer>
                      <ChatAvatar 
                        online={true}
                        src={avatarImageSrc}
                      />
                      <MessageBalloon
                        isTyping={message.isTyping}
                        title={`Dr. ${professionalName}`}
                        notch="left"
                        answers={message.answers}
                        content={<div>{message.content}</div>}
                        hourSended={message.hourSended}
                        lastQuestion={message?.lastQuestion}
                        onLastQuestionButtonClick={handleLastMessage}
                      />
                    </comp.ProfessionalMessageContainer>
                  </div>
                :
                <div>
                  <comp.PatientMessageContainer>
                    <MessageBalloon
                      isTyping={false}
                      notch="right"
                      title={clientName}
                      content={<div>{message.content}</div>}
                      hourSended={message.hourSended}
                      failState={message?.failState}
                      onRetrySendMessage={() => {
                        const answerToRetry =  answersGivenRef.current.find(answersGiven => answersGiven.questionId === messages[index-1].questionId)!
                        handleAnswerClicked(answerToRetry)
                      }}
                    />
                  </comp.PatientMessageContainer>
                </div>
              }
              {
                message.isQuitting &&
                <comp.ChatAdvice> 
                  <comp.Name> {`${professionalName} Saiu do Chat`} </comp.Name>  
                  <comp.LongLine /> 
                </comp.ChatAdvice>
              }
            </div>
          )
        })
      }
    </comp.PageWrapper>
  );
};


export default FirstConsultation;
