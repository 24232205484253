import styled from "styled-components";

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;
  & button{
    margin: 0 4px;
  }
  margin-top: 15px;
`

export const PaginationButton = styled.button<{$isActive?: boolean}>`
  width: 30px;
  height: 30px;
  color: ${props => props.$isActive ? '#fff' : '#000'};
  background-color: ${props => props.$isActive ? '#1270FC' : '#C4CDD5'};
  border: ${props => props.$isActive ? '1px solid #212B36' : 'none'};
  border-radius: 4px;
  font-weight: 700;
  transition-duration: 0.4s;
  cursor: pointer;
  &:hover {
    color: ${props => props.$isActive ? 'rgba(255, 255, 255, 1)' : '#1270FC'};
    box-shadow: 0 5px 15px rgba(18, 112,	252, .2);
  }
`

export const ExpandPages = styled.span`
  color: #1270FC;
  cursor: pointer;
  & :hover {
    box-shadow: 0 5px 15px rgba(18, 112,	252, .2);
  }
`