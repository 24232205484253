interface DayInfo {
    dayInISO: string;
    dayNumber: number;
    isInMonth: boolean;
    dayName: string;
    month: number;
    availableSlots?: string[];
}

export function GetMonthMatrix(year: number, month: number): DayInfo[][] {
    const matrix: DayInfo[][] = [];
    const firstDayOfMonth = new Date(year, month - 1, 1);
    const startingDay = firstDayOfMonth.getDay(); 
    const daysInMonth = new Date(year, month, 0).getDate(); 

    let currentDate = new Date(year, month - 1, 1 - startingDay); 

    for (let i = 0; i < 6; i++) {  
        const week: DayInfo[] = [];

        for (let j = 0; j < 7; j++) { 
            const dayNumber = currentDate.getDate();
            const dayInISO = currentDate.toISOString();
            const isInMonth = currentDate.getMonth() + 1 === month && dayNumber <= daysInMonth;
            const dayName = getDayName(currentDate.getDay()); 

            week.push({ dayInISO, dayNumber, isInMonth, dayName, month: currentDate.getMonth()+1});
            currentDate.setDate(currentDate.getDate() + 1); 
        }

        matrix.push(week); 
    }

    return matrix;
}

function getDayName(dayIndex: number): string {
    const days = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado'];
    return days[dayIndex];
}