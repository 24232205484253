import styled from "styled-components";

export const SideMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 250px;
`;

export const Logowrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 10px;

    img {
        width: 130px; /* Diminuído para se ajustar ao novo layout */
        height: 32px; /* Corrigido 'height' e ajustado tamanho */
    }
`;

export const MenuOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 2px;
    padding: 6px 0; /* Padding reduzido */
`;

interface MenuOptionProps {
    active?: boolean;
    theme?: 'default' | 'cancel';
}

export const MenuOptionWrapper = styled.div<MenuOptionProps>`
    width: 90%;
    max-width: 180px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: ${props => (props.theme === 'cancel' ? '10px' : '40px')};
    padding-left: 16px;
    gap: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: ${props => 
        props.active 
            ? (props.theme === 'cancel' ? '#ff8082' : '#1270fc')
            : (props.theme === 'cancel' ? '#ff8082' : 'white')
    };
    color: ${props => (props.active ? 'white' : (props.theme === 'cancel' ? 'white' : '#B2B2B2'))};

    &:hover {
        background-color: ${props => (props.theme === 'cancel' ? '#ff8082' : '#1270fc')};
        color: white;
    }
`;


export const HorizontalWrapper = styled.div`
    width: 80%; /* Largura reduzida para alinhar com o menu */
    border: none;
    border-top: 1px solid #d8d8d8; /* Espessura de linha ajustada */
    margin: 8px auto; /* Margem reduzida */
`;

export const BoxWrapper = styled.div`
    width: 100%;
    max-width: 140px; /* Ajuste para garantir que o conteúdo se encaixe no menu */
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px auto;
    margin-bottom: 20px;

    img {
        width: 100%; /* Garante que a imagem ocupe o espaço completo do BoxWrapper */
        height: auto; /* Mantém a proporção da imagem */
        max-width: 140px; /* Limita a largura máxima da imagem */
    }
`;

