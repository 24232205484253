import ApexCharts from "apexcharts";
import { eachDayOfInterval, endOfMonth, format } from "date-fns";
import { useEffect, useRef } from "react";
import { formatDecimalValues } from "../../../utils/formatDecimalValues";

interface SeriesProps {
  name: string;
  data: number[];
}

interface Profit {
  data: SeriesProps[];
  labelsExtern?: string[];
}

export const Profit = ({ data, labelsExtern }: Profit) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const currentDate = new Date();
    const lastDayOfMonth = endOfMonth(currentDate);
    const daysOfMonth = eachDayOfInterval({
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: lastDayOfMonth,
    });
    const labels = daysOfMonth?.map((day) => format(day, "dd MMM"));
    const options = {
      series: data,
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
        defaultLocale: "br",
        locales: [
          {
            name: "br",
            options: {
              months: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
              ],
              shortMonths: [
                "Jan",
                "Fev",
                "Mar",
                "Abr",
                "Mai",
                "Jun",
                "Jul",
                "Ago",
                "Set",
                "Out",
                "Nov",
                "Dez",
              ],
            },
          },
        ],
      },
      xaxis: {
        labels: {
          offsetX: 10,
          offsetY: 0
        }
      },
      dataLabels: {
        enabled: false,
        formatter: function (value: any) {
          return "R$ " + formatDecimalValues(value.toFixed(2));
        },
      },
      markers: {
        size: 5,
        colors: ["#00b300", "#eb0800", "#ff8000"],
        strokeColor: "#000",
        strokeWidth: 1,
      },
      colors: ["#00b300", "#eb0800", "#ff8000"],
      fill: {
        type: "solid",
        colors: ["transparent"],
      },
      stroke: {
        curve: "straight",
      },
      labels: labelsExtern || labels,
      yaxis: {
        opposite: true,
        labels: {
          formatter: function (value: any) {
            return "R$ " + formatDecimalValues(value?.toFixed(2));
          },
        },
      },
      legend: {
        horizontalAlign: "left",
      },
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.setLocale("br");
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data]);

  return <div ref={chartRef} />;
};
