import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../../assets/check.png";
import AnonymousProfilePhoto from "../../../assets/userAnonymous.jpeg";
import CustomButton from "../../../components/CustomButton";
import CustomLabel from "../../../components/CustomLabel";
import CustomTable from "../../../components/CustomTable";
import PageStructure from "../../../components/PageStructure";
import Pagination from "../../../components/Pagination";
import { HeaderPage } from "../../../components/Shared/HeaderPage";
import { LogoWrapper } from "../../../components/SideMenu/style";
import api from "../../../service/api";
import {
  formatarCPF,
  formatarNumeroTelefone,
} from "../../../service/utils.service";
import {
  ProfileActionsWrapper,
  ProfileHeader,
  ProfileHeaderTop,
  ProfileImageWrapper,
  ProfileInfoWrapper,
} from "../../Patients/Profile/style";
import { ListEmptyLabel, ListEmptyWrapper } from "../../Today/style";
import { HeaderTitle, HeaderWrapper } from "./style";
import { hasAdmin } from "../../../service/token.service";

export const ProfessionalProfile = () => {
  const location = useLocation();
  const { professional, hidesidebar } = location.state as { professional: any, hidesidebar: boolean };
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    fetchAppointmentHistoric()
  }, [])

  const fetchAppointmentHistoric = () => {
    let toastLoading = toast.loading("Carregando consultas...", {
      className: "toast-loading",
    });

    api
      .get(`/professional/appointment/${professional.id}/`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        toast.error("Erro ao carregar as consultas!");
      })
      .finally(() => {
        toast.dismiss(toastLoading);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Local",
        accessor: "establishment.alias",
      },
      {
        Header: "Paciente",
        accessor: "patient.name",
      },
      {
        Header: "Consulta",
        accessor: "service.name",
      },
      {
        Header: "Status do pagamento",
        accessor: "status.name",
      },
      {
        Header: "Inicio da consulta",
        accessor: "start_time",
      },
      {
        Header: "Fim da consulta",
        accessor: "end_time",
      },
    ],
    []
  );

  return (
    <PageStructure hidesidebar={hidesidebar}>
      <HeaderPage title="Profissional" />
      <ProfileHeader>
        <ProfileHeaderTop>
          <ProfileImageWrapper
            src={professional?.photo || AnonymousProfilePhoto}
            alt="Foto de perfil"
          />
          <ProfileActionsWrapper>
            <CustomButton disabled={!hasAdmin()} theme="success">Editar</CustomButton>
            {/* <CustomButton disabled={true} theme="cancel">Bloquear Profissional</CustomButton> */}
          </ProfileActionsWrapper>
        </ProfileHeaderTop>
        <ProfileInfoWrapper>
          <CustomLabel label="Nome" info={professional?.name} />
          <CustomLabel label="CPF" info={formatarCPF(professional?.document)} />
          <CustomLabel label="Profissonal" info="Empresário" />
          <CustomLabel label="Email" info={professional?.email} />
          <CustomLabel
            label="Telefone"
            info={formatarNumeroTelefone(professional?.phone)}
          />
        </ProfileInfoWrapper>
      </ProfileHeader>
      
      <HeaderWrapper>
          <HeaderTitle>Consultas realizadas</HeaderTitle>
      </HeaderWrapper>
      { data?.length > 0 ?
        <> 
          <CustomTable maxHeight={'calc(100vh - 496px)'} columns={columns} columnMinWidths={{}} data={data} />
          <Pagination
            totalPages={1}
            onPageChange={(pageNumber) => {
              
            }}
          />
        </>
        :
          <ListEmptyWrapper style={{marginTop: '10px'}}>
            <LogoWrapper src={Logo} alt="Logo" />
            <ListEmptyLabel> Sem consultas anteriores! </ListEmptyLabel>
          </ListEmptyWrapper>
      }
    </PageStructure>
  );
};

export default ProfessionalProfile;
