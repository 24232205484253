import {
  ActionTitle,
  AmountWrapper,
  ButtonIcon,
  Content,
  DateTitle,
  DateTitleWrapper,
  DateWrapper,
  DebitAmountWrapper,
  Divider,
  LeftWrapper,
  MainWrapper,
  PaymentMethod,
  PaymentMethodWrapper,
  PaymentTitle,
  PaymentSubTitle,
  RightWrapper,
  ValueWrapper,
  WrapperButtons,
  WrapperImage,
  WrapperInfos,
} from "./style";
import ReciveImg from "../../assets/money-recive.svg";
import SendImg from "../../assets/money-send.svg";
import PendencyMoney from "../../assets/pendency-money.svg";
import { TbReceipt } from "react-icons/tb";
import { FiEye } from "react-icons/fi";
import { formatDate } from "../../utils/formatDate";
import { formatDecimalValues } from "../../utils/formatDecimalValues";

interface Transaction {
  id: number;
  title: string;
  type: string;
  description: string;
  value: string;
  createdDate: string;
}

interface HistoryMoviment {
  date: any;
}

export const HistoryMoviment = ({ date }: HistoryMoviment) => {
  const formatTransactionDate = (date: string) => {
    const splitedDateString = date.split("T");
    const formatBar = formatDate(splitedDateString[0]);

    return formatBar + " às " + splitedDateString[1];
  };

  return (
    <MainWrapper>
      {date.map((item: any) => (
        <Content>
          <LeftWrapper>
            <WrapperImage>
              {item.type == "IN" && <img src={ReciveImg} />}
              {item.type == "OUT" && <img src={SendImg} />}
              {item.type == "PEND" && <img src={PendencyMoney} />}
            </WrapperImage>
            <WrapperInfos>
              <ActionTitle>
                {item.title ||
                  "#" +
                    item.appointment.id +
                    " " +
                    item.appointment.service?.name +
                    " - " +
                    item.appointment.patient.name +
                    " - " +
                    item.appointment.professional.name}
              </ActionTitle>
              <PaymentTitle>{item.obs}</PaymentTitle>
              <PaymentSubTitle>Recebido por {item?.created_by || 'Sem nome'}</PaymentSubTitle>
              <DateWrapper>{formatTransactionDate(item.date)}</DateWrapper>
            </WrapperInfos>
          </LeftWrapper>
          <RightWrapper>
            {item.type == "IN" && (
              <ValueWrapper>
                <PaymentMethodWrapper>
                  <PaymentMethod>
                    {item.mode == "CREDIT"
                      ? "CRÉDITO"
                      : item.mode == "DEBIT"
                      ? "DÉBITO"
                      : item.mode == "CASH"
                      ? "DINHEIRO"
                      : "PIX"}
                  </PaymentMethod>
                </PaymentMethodWrapper>
                <AmountWrapper>
                  R$ {formatDecimalValues(item.value)}
                </AmountWrapper>
              </ValueWrapper>
            )}

            {item.type == "PEND" && (
              <ValueWrapper>
                <PaymentMethodWrapper>
                  <PaymentMethod>
                    {item.mode == "CREDIT"
                      ? "CRÉDITO"
                      : item.mode == "DEBIT"
                      ? "DÉBITO"
                      : item.mode == "CASH"
                      ? "DINHEIRO"
                      : "PIX"}
                  </PaymentMethod>
                </PaymentMethodWrapper>
                <AmountWrapper>
                  R$ {formatDecimalValues(item.value)}
                </AmountWrapper>
              </ValueWrapper>
            )}

            {item.type == "OUT" && (
              <ValueWrapper>
                <PaymentMethodWrapper>
                  <PaymentMethod>
                    {item.mode == "CREDIT"
                      ? "CRÉDITO"
                      : item.mode == "DEBIT"
                      ? "DÉBITO"
                      : item.mode == "CASH"
                      ? "DINHEIRO"
                      : "PIX"}
                  </PaymentMethod>
                </PaymentMethodWrapper>
                <DebitAmountWrapper>
                  -R$ {formatDecimalValues(item.value)}
                </DebitAmountWrapper>
              </ValueWrapper>
            )}

            {/* <Divider />
            <WrapperButtons>
              <ButtonIcon>
                <TbReceipt color={"#1270FC"} />
              </ButtonIcon>
              <ButtonIcon>
                <FiEye color={"#1270FC"} />
              </ButtonIcon>
            </WrapperButtons> */}
          </RightWrapper>
        </Content>
      ))}
    </MainWrapper>
  );
};
