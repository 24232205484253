import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #f2f2f2;
    z-index: 999;
    position: absolute;
    border-radius: 5px;
    box-shadow: 2px 1px 5px -1px rgba(0,0,0,0.18);
    -webkit-box-shadow: 2px 1px 5px -1px rgba(0,0,0,0.18);
    -moz-box-shadow: 2px 1px 5px -1px rgba(0,0,0,0.18);
    transition: background-color ease-in-out 1s;
    display: flex;
`;

export const OptionsListWrapper = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

export const OptionTitle = styled.div`
    cursor: pointer;
`;

export const OptionWrapper = styled.div`
    display: flex;
    gap: 5px;
    min-width: 120px;
    max-width: 120px;
`;

export const OptionIcon = styled.div`
`

export const SubOptionsWrapper = styled.div`
    background-color: #f2f2f2;
    z-index: 999;
    position: absolute;
    border-radius: 5px;
    width: 190px;
    box-shadow: 2px 1px 5px -1px rgba(0,0,0,0.18);
    -webkit-box-shadow: 2px 1px 5px -1px rgba(0,0,0,0.18);
    -moz-box-shadow: 2px 1px 5px -1px rgba(0,0,0,0.18);
    transition: background-color ease-in-out 1s;
    display: flex;
    flex-direction: column;
    gap: 10px;
    left: 130px;
`

export const SubOptionContainer = styled.div`
    padding: 6px 10px;
    cursor: pointer;
    &:hover {
        background-color: #e0e0e0; 
    }
`

export const SubOption = styled.div`
`;


export const RightIcon = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`;