import { ReactNode, useEffect, useRef, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { FcCancel } from "react-icons/fc";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { IoFlagOutline } from "react-icons/io5";
import { PiMoneyLight, PiNewspaperClippingThin } from "react-icons/pi";
import CustomInput from "../CustomInput";
import LabelTags from "../LabelTags";
import { Wrapper } from "../Shared";
import {
  CheckBox,
  DropdownButton,
  DropdownItem,
  DropdownItemNew,
  DropdownList,
  DropdownListPayments,
  DropdownWrapper,
  ElementTitle,
  OptionShape,
  SideElementWrapper,
} from "./style";
import { FaCheck } from "react-icons/fa";

interface CustomDropDownMultiselectProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  options?: any[];
  selectedOption: string | null;
  setSelectedOption: (option: any) => void;
  leftIcon?: ReactNode;
  dropDownType?: "default" | "payment";
  allowCreate?: boolean;
  createLabel?: string;
  onCreate?: () => void;
  sideElement?: JSX.Element;
  disableBorder?: boolean;
  searchInput?: boolean;
  placeHolder?: string;
  selectAutomatic?: boolean;
  onSearchInputChange?: (value: string) => void;
}

const CustomDropDownMultiselect = ({
  leftIcon,
  label,
  options,
  dropDownType,
  selectedOption,
  setSelectedOption,
  allowCreate,
  createLabel,
  onCreate,
  sideElement,
  disableBorder,
  searchInput,
  placeHolder,
  onSearchInputChange,
  selectAutomatic = true
}: CustomDropDownMultiselectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [containerClicked, setContainerClicked] = useState(false);
  const [inputClick, setInputClick] = useState(false);
  const [optionClicked, setOptionClicked] = useState(false);

  const [optionsChoose, setOptionsChoose] = useState<any[]>([])
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedOption(optionsChoose)
  }, [optionsChoose])

  const handleOptionSelect = (option: string) => {
    if (optionsChoose.find((optionChoose) => option === optionChoose)) {
      setOptionsChoose(optionsChoose.filter((optionChoose) => optionChoose !== option));
    } else {
      setOptionsChoose([...optionsChoose, option]);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (containerClicked && inputClick) {
      setContainerClicked(false);
      setInputClick(false);
      return;
    }

    if (containerClicked && optionClicked) {
      // setIsOpen(false);
      setContainerClicked(false);
      setInputClick(false);
      setOptionClicked(false);
    }

    setContainerClicked(false);
  }, [isOpen, containerClicked]);

  // Novo useEffect para selecionar automaticamente a opção se houver apenas uma
  useEffect(() => {
    if (options?.length === 1 && selectAutomatic) {
      const singleOption = options[0];
      setSelectedOption(singleOption);
      setIsOpen(false); // Fecha o dropdown se necessário
    }
  }, [options]);

  return (
    <Wrapper>
      {label && <span>{label}</span>}
      <DropdownWrapper
        ref={dropdownRef}
        type={dropDownType || "default"}
        disableBorder={disableBorder}
        onClick={() => { setContainerClicked(true); setIsOpen(!isOpen) }}
      >
        <DropdownButton type={dropDownType || "default"}>
          {optionsChoose.map((optionChoose, index) => {
            return (
              <OptionShape key={index}>
                {optionChoose?.name}
              </OptionShape>
            )
          })}
          {selectedOption || placeHolder || <span>{optionsChoose.length == options?.length ? '' : 'Selecione'} </span>}
        </DropdownButton>
        {(!dropDownType || dropDownType === "default") && (
          <DropdownList open={isOpen}>
            {searchInput && <CustomInput
              onChange={(event) => { onSearchInputChange?.(event.currentTarget.value) }}
              onClick={() => setInputClick(true)}
              name=""
              disableBorder
              placeholder="Pesquisar..."
              leftIcon={
                <HiMagnifyingGlass size={20} color="var(--primary-icon-color)" />
              }
            />}
            {allowCreate && (
              <DropdownItemNew onClick={onCreate}>
                {createLabel || "Criar novo"}
              </DropdownItemNew>
            )}
            {options &&
              options?.map((option, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => {
                    handleOptionSelect(option);
                    setOptionClicked(true);
                  }}
                >
                  <CheckBox active={!!optionsChoose.find(optionChoose => optionChoose == option)}>
                    <FaCheck size={12} color="white"/>
                  </CheckBox>
                  <SideElementWrapper>
                    <LabelTags
                      info={option.tag?.name}
                      color={option.tag?.color}
                    />
                  </SideElementWrapper>
                  <ElementTitle>
                    {option.name || option.tag || option}
                  </ElementTitle>
                </DropdownItem>
              ))}
          </DropdownList>
        )}
        <BsChevronDown className="dropdown-icon" />
      </DropdownWrapper>
    </Wrapper>
  );
};

export default CustomDropDownMultiselect;
