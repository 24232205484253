import { CircularProgress } from "@mui/material";
import { Wrapper } from "./style";

interface RectangularButton {
  text: string;
  isLoading?: boolean;
  onClick?: any;
  disabled: boolean;
}

export const RectangularButton = ({
  text,
  isLoading,
  onClick,
  disabled
}: RectangularButton) => {
  return (
    <Wrapper variant="contained" disabled={disabled} onClick={onClick}>
      {isLoading ? <CircularProgress size={24} color="inherit" /> : text}
    </Wrapper>
  );
};
