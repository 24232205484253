import { useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { PiMoneyLight, PiCreditCard } from "react-icons/pi";
import { GiPayMoney } from "react-icons/gi";
import { RiBillLine } from "react-icons/ri";
import CustomButton from "../../../components/CustomButton";
import CustomDropdown from "../../../components/CustomDropDown";
import {
  AppointmentWrapper,
  ContentWrapper,
  FooterWrapper,
  HeaderTitle,
  HeaderWrapper,
  InfoWrapper,
  ModalWrapper,
} from "./style";
import CustomInput from "../../../components/CustomInput";
import api from "../../../service/api";
import { toast } from "react-toastify";
import { MdOutlineDoneAll } from "react-icons/md";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

interface RescheduleProps {
  closeModal: () => void;
  initialValue: number;
  event: any;
  doRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PaymentEntry = (props: RescheduleProps) => {
  const [value, setValue] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [paymentMethod, setPaymentMethod] = useState({
    name: "Crédito",
    id: "CREDIT",
  } as { name: string; id: string });
  const [installments, setInstallments] = useState("1x");
  const [alreadyPaid, setAlreadyPaid] = useState(false);
  const [originalInitialValue, setOriginalInitialValue] = useState("");
  const [idStone, setIdStone] = useState("");
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [receiptBase64, setReceiptBase64] = useState<any>(null);

  useEffect(() => {
    if (
      props.initialValue <= 0 ||
      props.event.extendedProps.status.name == "Cancelado"
    )
      setAlreadyPaid(true);
    handleChange(
      (props.initialValue > 0 ? props.initialValue * 100 : "000").toString()
    );

    setOriginalInitialValue(
      "R$ " +
        formatCurrency(
          (props.initialValue > 0 ? props.initialValue * 100 : "000")
            .toString()
            .replace(/[^0-9]/g, "")
        )
    );
    const paymentEntry = document.getElementById("input-payment-entry") as any;

    if (!paymentEntry) return;

    paymentEntry.focus();
  }, []);

  const currencyToNumber = (currency: string) => {
    const currencyNumber = currency.split(" ")[1];
    const formatted = currencyNumber.replace(/\./g, "").replace(",", ".");

    return Number(formatted);
  };

  const savePaymentEntry = () => {
    const toastLoading = toast.loading("Lançando Pagamento...", {
      className: "toast-loading",
    });

    const pixProofInput = document.getElementById("pixProof") as any;
    const prePaymentInput = document.getElementById("checkBoxButton") as any;

    let obs = '';
    let prePaymentValue = false;

    if(prePaymentInput)
      prePaymentValue = prePaymentInput?.checked

    if (paymentMethod.id == "PIX" && pixProofInput?.value)
      obs = `Comprovante (PIX): ${pixProofInput?.value} `;
    else if (paymentMethod.id == "CREDIT"){
      if(installments)
        obs = `Parcelado em: ${installments} `;  

      if(!idStone) {
        toast.update(toastLoading, {
          render: "ID do pagamento (Stone) não informado!.",
          type: "warning",
          isLoading: false,
          autoClose: 3000,
        });
        return;
      } else
        obs += `Transação (Stone)'}: ${idStone}`
    } else if (paymentMethod.id == "DEBIT"){
      if(!idStone) {
        toast.update(toastLoading, {
          render: "ID do pagamento (Stone) não informado!.",
          type: "warning",
          isLoading: false,
          autoClose: 3000,
        });
        return;
      } else
        obs += `Transação (Stone)'}: ${idStone}`
    }

    if(idStone)
      obs += `Transação (Stone): ${idStone} `

    const formData = new FormData();

    formData.append('value', currencyToNumber(value).toString());
    formData.append('date', new Date().toISOString());
    formData.append('mode', paymentMethod.id);
    formData.append('appointment', Number(props.event.id).toString());
    formData.append('type', 'IN');
    formData.append('obs', obs || '');
    formData.append('is_pre_payment', prePaymentValue.toString());

    if (receiptBase64) {
      formData.append('attachment', selectedImage);
    }
    
    api
      .post("/payments/", formData)
      .then(() => {
        toast.update(toastLoading, {
          render: "Pagamento lançado com sucesso!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });

        props.doRefresh(true);

        searchParams.set("refresh", "true");
    
        navigate({
          pathname: location.pathname,
          search: searchParams.toString()
        }, { replace: true });
      })
      .catch(() => {
        toast.update(toastLoading, {
          render: "Erro no lançamento.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      });
  };

  const handleChange = (value: string) => {
    setValue("R$ " + formatCurrency(value.replace(/[^0-9]/g, "")));
  };

  const formatCurrency = (value: string) => {
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    return value;
  };

  const handleFileUpload = (event: any) => {
    const arquivo = event.target.files[0];

    const fileSizeInMB = arquivo.size / 1024 / 1024;
    if (fileSizeInMB > 5) {
      toast.warn("O arquivo não deve ultrapassar o limite de 5MB!", {
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }

    const reader = new FileReader();
    setSelectedImage(arquivo)

    reader.onload = () => {
      const result = reader.result as any

      setReceiptBase64(result);
    };

    reader.readAsDataURL(arquivo);
  };

  return (
    <>
      <ModalWrapper size="small">
        <HeaderWrapper>
          <HeaderTitle>Lançar pagamento</HeaderTitle>
          <IoCloseOutline
            size={22}
            onClick={props.closeModal}
            color="#919EAB"
            cursor={"pointer"}
          />
        </HeaderWrapper>
        <ContentWrapper>
          <InfoWrapper>
            <AppointmentWrapper>
              <CustomInput
                label="Valor"
                id="input-payment-entry"
                disabled={alreadyPaid}
                value={value}
                onChange={(e) => {
                  if (
                    currencyToNumber(
                      "R$ " +
                        formatCurrency(e.target.value.replace(/[^0-9]/g, ""))
                    ) >= currencyToNumber(originalInitialValue)
                  ) {
                    handleChange(originalInitialValue);
                    return;
                  }


                  handleChange(e.currentTarget.value);
                }}
                leftIcon={
                  <PiMoneyLight size={16} color="var(--primary-icon-color)" />
                }
              />
              <CustomDropdown
                label="Método de pagamento"
                options={[
                  { name: "Crédito", id: "CREDIT" },
                  { name: "Débito", id: "DEBIT" },
                  { name: "Pix", id: "PIX" },
                  { name: "Dinheiro", id: "CASH" },
                  { name: "Transferência", id: "TRANSFER" },
                ]}
                selectedOption={paymentMethod.name}
                setSelectedOption={(value) => {
                  setPaymentMethod(value);
                }}
                leftIcon={
                  <GiPayMoney size={16} color="var(--primary-icon-color)" />
                }
              />
              {(paymentMethod?.id == "PIX" || paymentMethod?.id == "TRANSFER") && (
                <CustomInput
                  label="Comprovante de Pagamento"
                  id="pixProof"
                  placeholder="Digite o comprovante"
                  leftIcon={
                    <RiBillLine size={16} color="var(--primary-icon-color)" />
                  }
                />
              )}

              {(paymentMethod?.id == "PIX" || paymentMethod?.id == "TRANSFER") && (
                <>
                  <CustomInput
                    label="Selecione o comprovante"
                    type="file"
                    disableBorder
                    inputId="receiptInputUpload"
                    onChange={handleFileUpload}
                    accept=".pdf, image/*"
                    fileSelected={selectedImage?.name}
                  />
                </>
              )}

              {paymentMethod?.id == "CREDIT" && (
                <>
                  <CustomDropdown
                    label="Parcelas"
                    options={[
                      "1x",
                      "2x",
                    ]}
                    selectedOption={installments}
                    setSelectedOption={(value) => {
                      setInstallments(value);
                    }}
                    leftIcon={
                      <PiCreditCard size={16} color="var(--primary-icon-color)" />
                    }
                  />
                </>
              )}

              {paymentMethod?.id != 'CASH' && paymentMethod?.id != 'PIX' && paymentMethod?.id != 'TRANSFER' &&
                <CustomInput
                  label="ID do pagamento (Stone)"
                  value={idStone}
                  onChange={e => setIdStone(e.currentTarget.value)}
                  placeholder="Digite o identificador"
                  leftIcon={
                    <MdOutlineDoneAll size={16} color="var(--primary-icon-color)" />
                  }
                />
              }

              {/* {paymentMethod?.id == 'PIX' &&
                <CustomInput
                  label="Responsável pela transação"
                  value={idStone}
                  onChange={e => setIdStone(e.currentTarget.value)}
                  placeholder="Digite o responsável"
                  leftIcon={
                    <MdOutlineDoneAll size={16} color="var(--primary-icon-color)" />
                  }
                />
              } */}
            </AppointmentWrapper>
            <CustomInput
              label="Pré Pagamento"
              type="checkbox"
              disableBorder
            />
          </InfoWrapper>
        </ContentWrapper>
        <FooterWrapper>
          <CustomButton theme="cancel" onClick={props.closeModal}>
            Cancelar
          </CustomButton>
          <CustomButton
            theme="success"
            onClick={savePaymentEntry}
            disabled={alreadyPaid}
          >
            Salvar
          </CustomButton>
        </FooterWrapper>
      </ModalWrapper>
    </>
  );
};

export default PaymentEntry;
