import { IoCloseOutline } from "react-icons/io5";
import CustomButton from "../../../components/CustomButton";
import api from "../../../service/api";
import Modal from 'react-modal';
import { ContentWrapper, FooterWrapper, HeaderTitle, HeaderWrapper, InfoWrapper, ModalWrapper, ObservationsWrapper } from "./style";
import { useEffect, useState } from "react";
import Confirm from "../Confirm";
import { customStyles } from "../../../components/Shared";
import { animated, useSpring } from "react-spring";
import RadioButtons from "../../../components/RadioButtons";
import { toast } from "react-toastify";

interface CancelProps {
    closeModal: () => void;
    appointment: any;
    setAppointments: any;
}

interface TextObject{ 
    optionSelectedText: {
        id: number,
        messageToShow: string
    };
}

export const Cancel = (props: CancelProps) => {
    const [justificationGiven, setJustificationGiven] = useState({} as {id: number, justification: string})
    const [justifications, setJustifications] = useState([] as any[]);
    const [disableConfirmButton, setDisableConfirmButton] = useState(true)
    const [modalConfirmCancelIsOpen, setModalConfirmCancelIsOpen] = useState(false);

    useEffect(() => {
        getJustify()
    }, [])

    const getJustify = () => {
        const toastLoading = toast.loading("Obtendo justificativas...", {
            className: "toast-loading"
        })        

        api.get('/reasons/?type=C').then((content) => {
            const data = content.data
            
            const justificationsMapped = data.map((justification: any) => {
                return {
                    id: justification.id,
                    messageToShow: justification.reason,
                }
            })

            setJustifications(justificationsMapped)

            toast.update(toastLoading, {
                render: "Escolha uma alternativa",
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });        
        }).catch((error) => {
            toast.update(toastLoading, {
                render: "Erro ao obter justificativas.",
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        })
    }

    const cancelAppointment = () => {
        api.patch(`/appointments/${props.appointment.id}/`, {status: 4, reason: justificationGiven.id, obs: `Motivo: ${justificationGiven.justification}`}).then((response) => {
            toast.success('Desmarque realizado com sucesso!');
            props.setAppointments([]);
            props.closeModal();
        }).catch((error: any) => {
            toast.error('Erro ao desmarcar o compromisso!');
        });
    }

    const fade = useSpring({
        from: { opacity: 0 },
        to: { opacity: modalConfirmCancelIsOpen ? 1 : 0 },
    });

    const handleAnswerClicked = (answer: TextObject) => {
        setJustificationGiven({
            id: answer.optionSelectedText.id,
            justification: answer.optionSelectedText.messageToShow
        })
        setDisableConfirmButton(false)
    }

    return (
        <ModalWrapper size="small">
            <Modal
                isOpen={modalConfirmCancelIsOpen}
                onRequestClose={() => setModalConfirmCancelIsOpen(false)}
                shouldCloseOnOverlayClick={false}
                style={customStyles}
            >
                <animated.div style={fade}>
                <Confirm closeModal={() => {setModalConfirmCancelIsOpen(false)}} onConfirmAction={cancelAppointment}/>
                </animated.div>
            </Modal>
            <HeaderWrapper>
                <HeaderTitle>Desmarcar compromisso</HeaderTitle>
                <IoCloseOutline size={22} onClick={props.closeModal} color="#919EAB" cursor={"pointer"} />
            </HeaderWrapper>
            <ContentWrapper>
                <InfoWrapper>
                    <ObservationsWrapper>
                        <RadioButtons options={justifications} onChangeParent={handleAnswerClicked} showChooseLabel="Justificativa"/>
                    </ObservationsWrapper>
                </InfoWrapper>
            </ContentWrapper>
            <FooterWrapper>
                <CustomButton theme="cancelDeleteAppointment" onClick={props.closeModal}>Cancelar</CustomButton>
                <CustomButton theme="deleteAppointment" disabled={disableConfirmButton} onClick={() => {setModalConfirmCancelIsOpen(true)}}>Desmarcar compromisso</CustomButton>
            </FooterWrapper>
        </ModalWrapper>
    );
}

export default Cancel;