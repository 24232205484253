import { useEffect, useState } from "react";
import { FaRegCalendarCheck } from "react-icons/fa";
import { IoMdTime } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import Modal from "react-modal";
import { animated, useSpring } from "react-spring";
import { toast } from "react-toastify";
import CustomButton from "../../../components/CustomButton";
import CustomInput from "../../../components/CustomInput";
import RadioButtons from "../../../components/RadioButtons";
import { customStyles } from "../../../components/Shared";
import api from "../../../service/api";
import AvailableSlots from "../../AvailableSlots";
import {
  AppointmentWrapper,
  ContentWrapper,
  FooterWrapper,
  HeaderTitle,
  HeaderWrapper,
  InfoWrapper,
  ModalWrapper,
  ObservationsWrapper,
} from "./style";

interface RescheduleProps {
  closeModal: () => void;
  appointment: any;
}

interface TextObject{ 
  optionSelectedText: {
      id: number,
      messageToShow: string
  };
}

interface NextAvailableSlot {
  month: string,
  days: {
    "Domingo"?: {
      date: string,
      available_slots: string[];
    }[],
    "Segunda"?: {
      date: string,
      available_slots: string[];
    }[],
    "Terça"?: {
      date: string,
      available_slots: string[];
    }[],
    "Quarta"?: {
      date: string,
      available_slots: string[];
    }[],
    "Quinta"?: {
      date: string,
      available_slots: string[];
    }[],
    "Sexta"?: {
      date: string,
      available_slots: string[];
    }[],
    "Sabado"?: {
      date: string,
      available_slots: string[];
    }[],
  }
}

export const Reschedule = (props: RescheduleProps) => {
  const [justificationGiven, setJustificationGiven] = useState({} as {id: number, justification: string})
  const [justifications, setJustifications] = useState([] as any[]);
  const [disableConfirmButton, setDisableConfirmButton] = useState(true)
  const timing: string[] = ["1 hora", "30 min"];
  const actualDate = new Date();
  const actualTime = new Date().toISOString().slice(11, 16);
  actualDate.setHours(parseInt(actualTime.split(":")[0]));
  actualDate.setMinutes(parseInt(actualTime.split(":")[1]));
  const endTime = new Date(actualDate);
  endTime.setMinutes(endTime.getMinutes() + 15);
  const [isFreeHoursModalOpen, setIsFreeHoursModalOpen] = useState(false);
  const [nextAvailableSlots, setNextAvailableSlots] = useState<
    NextAvailableSlot[]
  >([]);

  const [formData, setFormData] = useState<any>({
    time: new Date().toISOString().slice(11, 16),
    start_date: new Date().toISOString().slice(0, 10),
    start_time: actualDate.toISOString(),
    time_initial: new Date().toISOString().slice(11, 16),
    end_time: new Date(Date.now() + 3600000).toISOString().slice(11, 16),
  });

  const toggleFreeHoursModal = () => {
    setIsFreeHoursModalOpen(!isFreeHoursModalOpen);
  };

  const fadeFreeHours = useSpring({
    from: { opacity: 0 },
    to: { opacity: isFreeHoursModalOpen ? 1 : 0 },
  });

  const fetchFreeHours = (url: string) => {
    api.get(url).then((response) => {
      setNextAvailableSlots(response.data);
    });
  };

  useEffect(() => {
    getJustify()
    fetchFreeHours(`/next-available-slots/${props.appointment.extendedProps.professional.id}/`);
  }, [])

  const getJustify = () => {
      const toastLoading = toast.loading("Obtendo justificativas...", {
          className: "toast-loading"
      })        

      api.get('/reasons/?type=R').then((content) => {
          const data = content.data
          
          const justificationsMapped = data.map((justification: any) => {
              return {
                  id: justification.id,
                  messageToShow: justification.reason,
              }
          })

          setJustifications(justificationsMapped)

          toast.update(toastLoading, {
              render: "Escolha uma alternativa",
              type: 'success',
              isLoading: false,
              autoClose: 3000
          });        
      }).catch((error) => {
          toast.update(toastLoading, {
              render: "Erro ao obter justificativas.",
              type: 'error',
              isLoading: false,
              autoClose: 3000
          });
      })
  }

  function adicionarUmaHora(strHora: any) {
    const [hora, minuto] = strHora.split(':');
    const data = new Date();
    data.setHours(parseInt(hora, 10));
    data.setMinutes(parseInt(minuto, 10)); 
    data.setHours(data.getHours() + 1);
    const novaHora = `${String(data.getHours()).padStart(2, '0')}:${String(data.getMinutes()).padStart(2, '0')}`;
    return novaHora;
  }

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    if (name === "start_date") {
      let date = new Date();
      if(value != ''){
        date = new Date(value + "T00:00:00");
        date.setHours(actualDate.getHours());
        date.setMinutes(actualDate.getMinutes());
      }

      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [name]: value,
        start_time: date?.toISOString() || '',
        
      }));
    } else if (name === "time") {
      const date = new Date(formData.start_date + "T00:00:00");
      const hour = value.split(":")[0];
      const minutes = value.split(":")[1];
      date.setHours(parseInt(hour));
      date.setMinutes(parseInt(minutes));
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [name]: value,
        start_time: date?.toISOString() || '',
        end_time: adicionarUmaHora(value),
      }));
    } else {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  function calcularEndDate(start_time: string, end_time:string) {
    const startDate = new Date(start_time);
    const startHour = startDate.getHours();
    const startMinute = startDate.getMinutes();

    const endHour = parseInt(end_time.split(':')[0]);
    const endMinute = parseInt(end_time.split(':')[1]);

    const date = new Date(start_time);
    date.setHours(endHour);
    date.setMinutes(endMinute);

    if (startHour >= 23) {
      date.setDate(date.getDate() + 1);
    }

    return date.toISOString();
  }
  
  function validarDatas(dataInicio: string, dataFinal: string) {
    const inicio = new Date(dataInicio);
    const final = new Date(dataFinal);
    if (final <= inicio) {
        toast.error("Horários inválidos. horário final menor ou igual que o horário inicial");
        return false;
    }
    const diferenca = final.getTime() - inicio.getTime();
    const diferencaMinutos = Math.abs(diferenca / (1000 * 60));
    if (diferencaMinutos < 30) {
        toast.error("Não é possível marcar consultas com duração até 30 minutos");
        return false;
    }
    return true;
  }

  const saveAppointment = () => {
    // handleDuration(formData.duration);
    if(!validarDatas(formData.start_time, calcularEndDate(formData.start_time,formData.end_time))) return

    const formDataToSend = new FormData();
    
    formDataToSend.append("start_time", formData.start_time);
    formDataToSend.append("end_time", calcularEndDate(formData.start_time,formData.end_time));
    formDataToSend.append("reason", justificationGiven.id.toString());
    formDataToSend.append("obs", `Motivo: ${justificationGiven.justification}`);
    
    api
      .patch(`/appointments/${props.appointment.id}/`, formDataToSend)
      .then((response) => {
        toast.success("Compromisso remarcado com sucesso!");
        props.closeModal();
      })
      .catch((error: any) => {
        toast.error(
          "Erro ao remarcar compromisso!\r\nVerifique todos os campos"
        );
      });
  };

  const calculateEndDate = (duration: string) => {
    const date = new Date(formData.start_time);
    if (duration.includes("min")) {
      date.setMinutes(date.getMinutes() + parseInt(duration.split(" ")[0]));
    }
    if (duration.includes("h"))
      date.setHours(date.getHours() + parseInt(duration.split(" ")[0]));
    return date.toISOString();
  };

  const handleDuration = (duration: string) => {
    setFormData({
      ...formData,
      duration,
      end_time: calculateEndDate(duration),
    });
  };

  const handleAnswerClicked = (answer: TextObject) => {
    setJustificationGiven({
        id: answer.optionSelectedText.id,
        justification: answer.optionSelectedText.messageToShow
    })
    setDisableConfirmButton(false)
}

  return (
    <>
      <ModalWrapper size="small">
        <Modal
          isOpen={isFreeHoursModalOpen}
          onRequestClose={toggleFreeHoursModal}
          shouldCloseOnOverlayClick={false}
          style={customStyles}
        >
          <animated.div style={fadeFreeHours}>
            <AvailableSlots
              closeModal={toggleFreeHoursModal}
              nextAvailableSlots={nextAvailableSlots}
              setFormData={setFormData}
              setIsFreeHoursModalOpen={setIsFreeHoursModalOpen}
            />
          </animated.div>
        </Modal>
        <HeaderWrapper>
          <HeaderTitle>Remarcar Compromisso</HeaderTitle>
          <IoCloseOutline
            size={22}
            onClick={props.closeModal}
            color="#919EAB"
            cursor={"pointer"}
          />
        </HeaderWrapper>
        <ContentWrapper>
          <InfoWrapper>
            <AppointmentWrapper>
              <CustomInput
                label="Data"
                name="start_date"
                type="date"
                value={formData.start_date}
                min={new Date().toISOString().split('T')[0]}
                onChange={handleInputChange}
                leftIcon={
                  <FaRegCalendarCheck
                    size={16}
                    color="var(--primary-icon-color)"
                  />
                }
              />
              <CustomInput
                label="Hora Inicio"
                name="time"
                value={formData.time}
                onChange={handleInputChange}
                placeholder="06:00"
                type="time"
                leftIcon={
                  <IoMdTime size={16} color="var(--primary-icon-color)" />
                }
              />
              <CustomInput
                label="Hora Fim"
                name="end_time"
                value={formData.end_time}
                onChange={handleInputChange}
                placeholder="06:00"
                type="time"
                min={"20:00"}
                max={"23:00"}
                required
                leftIcon={
                  <IoMdTime size={16} color="var(--primary-icon-color)" />
                }
              />
            </AppointmentWrapper>
            <CustomButton theme="addPrimary" disabled={nextAvailableSlots.length <= 0} onClick={() => setIsFreeHoursModalOpen(true)} style={{marginBottom: '10px'}}>
              Ver horários livres
            </CustomButton>
            <ObservationsWrapper>
              <RadioButtons options={justifications} onChangeParent={handleAnswerClicked} showChooseLabel="Justificativa"/> 
            </ObservationsWrapper>
          </InfoWrapper>
        </ContentWrapper>
        <FooterWrapper>
          <CustomButton theme="cancel" onClick={props.closeModal}>
            Cancelar
          </CustomButton>
          <CustomButton theme="success" onClick={saveAppointment} disabled={disableConfirmButton}>
            Salvar
          </CustomButton>
        </FooterWrapper>
      </ModalWrapper>
    </>
  );
};

export default Reschedule;
