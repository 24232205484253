import { useRef } from "react";
import { AvailableHourShape, Container, ContentDaysWrapper, ContentWrapper, DollarIcon, FreeHoursContainer, HoursContainer, ItalicLabel, ModalWrapper, MonthContainer, MonthTitleContainer, MonthWrapper, PaymentContainer, PaymentContent, PaymentHeaderTitle, PaymentInfo, PaymentLabel, PaymentLabelWrapper, PaymentWrapper, SeparatorBottom } from "../../style";

interface objectDay {
    dayInISO: string,
    dayNumber: number,
    isInMonth: boolean,
    dayName: string,
    month: number,
    availableSlots?: string[]
}


interface AvailableSlotsProps {
    day: objectDay;
    dayToRender: string
    handleHourClicked: (hour: string) => void;
}

export const ShowDays = ({ day, dayToRender, handleHourClicked }: AvailableSlotsProps) => {

    return (
        <>
            <PaymentContainer style={{marginTop: '20px', opacity: day.isInMonth ? 1 : 0.4}} 
                isInMonth={day.isInMonth}
                haveAvailableHours={day.availableSlots ? day.availableSlots.length > 0 : false}
            >
                <PaymentHeaderTitle>
                    {/* {(day.isInMonth ? (day.availableSlots?.length || false) : false)  && <DollarIcon type={"paid"}> {day?.availableSlots?.length} </DollarIcon>} */}
                </PaymentHeaderTitle>

                <Container>
                    <PaymentContent>
                        <PaymentWrapper>
                            <PaymentLabelWrapper>
                                {/* <PaymentLabel>
                                    DIA
                                </PaymentLabel> */}
                                <PaymentInfo>
                                    {day.dayNumber}
                                </PaymentInfo>
                            </PaymentLabelWrapper>
                        </PaymentWrapper>
                    </PaymentContent>
                    <PaymentContent>
                        <PaymentWrapper>
                            <PaymentLabelWrapper>
                                <PaymentLabel>
                                    {day.dayName}
                                </PaymentLabel>
                                <ItalicLabel>
                                    {day.month}/{day.dayInISO.split('-')[0]}
                                </ItalicLabel>
                            </PaymentLabelWrapper>
                        </PaymentWrapper>
                    </PaymentContent>
                </Container>

                {(day.isInMonth ? (day.availableSlots?.length || false) : false) && <> 
                    <HoursContainer>
                        <div style={{fontSize: '12px'}}>
                            Horários ({day?.availableSlots?.length})
                        </div>
                        <div style={{flex: 1, background: '#666', borderRadius: '12px', marginLeft: '4px', height: '1px'}}/>
                    </HoursContainer>

                    <FreeHoursContainer>
                        {
                            day?.availableSlots?.length ? (
                                day?.availableSlots?.map(slot => {
                                    return (
                                        <AvailableHourShape onClick={() => handleHourClicked(slot)}>{slot}</AvailableHourShape>
                                    )
                                })
                            )
                            :
                            (
                                <AvailableHourShape>Sem horário</AvailableHourShape>
                            )

                        }
                    </FreeHoursContainer>
                </>
                }
            </PaymentContainer>
        </>
    );
};

export default ShowDays;
