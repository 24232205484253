import { transparentize } from 'polished';
import styled, { keyframes } from 'styled-components'


const pulseAnimation = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
`;

export const InfoLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
` 

export const AlertIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: -20px;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #FFB84D;
    cursor: pointer;
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    transition: all 0.3s;
    animation: ${pulseAnimation} .3s ease-in-out infinite;

    &:hover {
        background-color: #FFCC99;
    }`

export const Title = styled.div`
    font-weight: 400;
    color: #333333;
    font-size: 13px;
    position: relative;
    display: table;
`

interface SubtitleProps {
    focus?: boolean;
}

export const Subtitle = styled.div<SubtitleProps>`
    font-family: 'Jost';
    color: ${props => props.focus ? '#333333' : '#333333'};
    font-weight: ${props => props.focus ? '500' : '300'};
    font-size: ${props => props.focus ? '16px' : '12px'};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
`

interface LabelTagsProps {
    color: string;
    bold?: boolean
}

export const Info = styled.span<LabelTagsProps>`
    margin-top: 3px;
    font-size: 12px !important;
    font-style: normal;
    background-color: ${props => transparentize(0.75, props.color)};
    padding: 3px 10px;
    border-radius: 5px;
    color: ${props => props.color} !important;
    text-align: center;
`;