import { Icon } from "../SideMenu/style";
import { useEffect, useState } from "react";
import { ExpandPages, PaginationButton, PaginationContainer } from "./style";

import arrowLeft from "../../assets/arrowLeft.svg";
import arrowRight from "../../assets/arrowRight.svg";

const MAX_PAGINATION_BUTTONS = 5;

interface PaginationProps {
  totalPages: number;
  onPageChange: (pageClicked: number) => void;
};

export const Pagination = (props: PaginationProps) => {
  const isTooManyPages = props.totalPages > MAX_PAGINATION_BUTTONS;
  
  const [pagesToShow, setPagesToShow] = useState<number[]>([]);
  const [pageActive, setPageActive] = useState(1);
  const [pageControl, setPageControl] = useState<'showMore' | 'showLess' | ''>('');
  const [isInitiating, setIsInitiating] = useState(true);
  const [isAbleToShowMore, setIsAbleToShowMore] = useState(false);
  const [isAbleToShowLess, setIsAbleToShowLess] = useState(false);

  useEffect(() => {
    let initialPagination = [];
    for (let i = 1; i <= (isTooManyPages ? MAX_PAGINATION_BUTTONS : props.totalPages); i++) {
      initialPagination.push(i);
    }

    if(isTooManyPages){
      initialPagination[initialPagination.length-1] = props.totalPages;
      setIsAbleToShowMore(true);
    } 

    setPagesToShow(initialPagination);
    setIsInitiating(false);
    setIsAbleToShowLess(false);
  }, [props.totalPages])

  useEffect(() => {
    if(isInitiating) return
    if(!isTooManyPages) return
    if(pageControl === '') return;

    if(pageControl === 'showMore'){
      setIsAbleToShowLess(true);
      const newPages =  newPagesToShow(pageControl);
  
      setPagesToShow([...newPages]);
      changePageLocaleAndPropagate(pagesToShow[pagesToShow.length-2]+1)
      setPageControl('');

      if(newPages[newPages.length-1] - newPages[newPages.length-2] <= 1) setIsAbleToShowMore(false);
      else setIsAbleToShowMore(true);
    }

    if(pageControl === 'showLess'){
      setIsAbleToShowMore(true)
      const newPages = newPagesToShow(pageControl)

      setPagesToShow([...newPages]);      
      changePageLocaleAndPropagate(pagesToShow[1]-1);
      setPageControl('');

      if(newPages[1] - newPages[0] <= 1) setIsAbleToShowLess(false);
      else setIsAbleToShowLess(true);
    }
  }, [pageControl, pagesToShow])

  useEffect(() => {
    if(!isTooManyPages) return
    if(isInitiating) return

    if(pageActive === props.totalPages){
      const newPagesToShow = pagesToShow.map((_, index) => {
        if(pagesToShow.length-1 === index)
          return props.totalPages;

        if(index === 0)
          return 1;

        return (props.totalPages - 4) + index;
      })

      setPagesToShow([...newPagesToShow]);

      setIsAbleToShowLess(true);
      setIsAbleToShowMore(false);
    }

    if(pageActive === 1){
      const newPagesToShow = pagesToShow.map((_, index) => {
        if(pagesToShow.length-1 === index)
          return props.totalPages;

        if(index === 0)
          return 1;

        return index+1;
      })

      setPagesToShow([...newPagesToShow]);
      setIsAbleToShowLess(false);
      setIsAbleToShowMore(true);
    }
  }, [pageActive])

  const changePageLocaleAndPropagate = (pageNumber: number) => {
    setPageActive(pageNumber); 
    props.onPageChange(pageNumber)
  }

  const arrowClickHandler = (type: 'add' | 'sub') => {
    if(type == 'sub' && !(pageActive == 1)){
      changePageLocaleAndPropagate(pageActive-1)
      if(!pagesToShow.includes(pageActive-1)) setPageControl('showLess')
    }
    
    if(type == 'add' && !(pageActive == props.totalPages)){
      changePageLocaleAndPropagate(pageActive+1)
      if(!pagesToShow.includes(pageActive+1)) setPageControl('showMore')
    }
  }

  const newPagesToShow = (type: 'showMore' | 'showLess' | '') => {
    const newPagesToShow = pagesToShow.map((page, index) => {
      if(pagesToShow.length-1 === index)
        return page;

      if(index === 0)
        return 1;

      if(type == 'showMore'){
        if(pagesToShow[pagesToShow.length-1] - pagesToShow[pagesToShow.length-2] <= 2)
          return page + 1;
  
        if(pagesToShow[pagesToShow.length-1] - pagesToShow[pagesToShow.length-2] <= 3)
          return page + 2;
  
        return page + 3;
      }else{
        if(pagesToShow[1] - pagesToShow[0] <= 2)
          return page - 1;

        if(pagesToShow[1] - pagesToShow[0] <= 3)
          return page - 2;

        return page - 3;
      }
    })

    return newPagesToShow;
  }

  return <PaginationContainer>
    <Icon src={arrowLeft} onClick={() => arrowClickHandler('sub')}/>
      {
        pagesToShow.map((value, index) => {
          let pageNumber = value
          
          if(index === pagesToShow.length-1)
            pageNumber = props.totalPages

          return (
            <>
              {isTooManyPages && index === pagesToShow.length-1 && isAbleToShowMore  && <ExpandPages key={'showMore'} onClick={() => setPageControl('showMore')}>...</ExpandPages>}

              <PaginationButton
                key={index}
                $isActive={pageActive === pageNumber ? true : false}
                onClick={() => {changePageLocaleAndPropagate(pageNumber)}}
                >
                {pageNumber}
              </PaginationButton>

              {isAbleToShowLess && index === 0 && <ExpandPages key={'showLess'} onClick={() => setPageControl('showLess')}>...</ExpandPages>}
            </>
          )
        })
      }
    <Icon src={arrowRight} onClick={() => arrowClickHandler('add')}/>
  </PaginationContainer>
}

export default Pagination;