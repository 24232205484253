export const subtrairUmDiaComHorario = (dataString: string) => {

    const partes = dataString.split("T");
    const dataParte = partes[0];
    const horaParte = partes[1];

    const fusoHorario = dataString.substring(dataString.length - 6);

    const data = new Date(dataParte);

    data.setDate(data.getDate() - 1);

    const ano = data.getFullYear();
    const mes = (data.getMonth() + 1).toString().padStart(2, "0");
    const dia = data.getDate().toString().padStart(2, "0");
    const hora = horaParte.substring(0, 2);
    const minuto = horaParte.substring(3, 5);
    const segundo = horaParte.substring(6, 8);

    return `${ano}-${mes}-${dia}T${hora}:${minuto}:${segundo}${fusoHorario}`;
  }