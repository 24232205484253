import { useEffect, useState } from "react";
import Modal from "react-modal";
import { animated, useSpring } from "react-spring";
import CustomButton from "../../components/CustomButton";
import ModalWrapperWithTab from "../../components/ModalWrapperWithTab";
import { customStyles } from "../../components/Shared";
import { getGroup } from "../../service/token.service";
import { subtrairUmDiaComHorario } from "../../utils/subOneDayInDate";
import Cancel from "../Event/Cancel";
import { FooterWrapper } from "../Event/Create/style";
import Reschedule from "../Event/Reschedule";
import PaymentTab from "./Tab/Payment";
import CreateEventTab from "./Tab/ShowEventTab";

interface CreateEventProps {
  closeModal: () => void;
  event: any;
  defaultTab?: string;
  setAppointments?: any;
}

export const ShowEvent = (props: CreateEventProps) => {
  const tabs = ["Compromisso", "Pagamento"];
  const [form, setForm] = useState<any>({});
  const [modalRescheduleIsOpen, setModalRescheduleIsOpen] = useState(false);
  const [modalCancelEventIsOpen, setModalCancelEventIsOpen] = useState(false);
  const [tabActive, setTabActive] = useState(props?.defaultTab || tabs[0]);
  const [isValidRemark, setIsValidRemark] = useState(true);

  const handleInputChange = (key: any, value: any) => {
    setForm({ ...form, [key]: value });
  };

  const closeRescheduleModal = () => {
    setModalRescheduleIsOpen(false);
  };

  const closeCancelEventModal = () => {
    setModalCancelEventIsOpen(false);
  };

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalRescheduleIsOpen ? 1 : 0 },
  });

  const fadeCancel = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalCancelEventIsOpen ? 1 : 0 },
  });

  const handleTabChange = (tab: string) => {
    setTabActive(tab);
  };


  useEffect(() => {
    const eventDate = props.event.start;
    const subtractDate = subtrairUmDiaComHorario(new Date(eventDate).toISOString());
    const currentDate = new Date();
    const periodRemarkDate = new Date(subtractDate);
    if (currentDate >= periodRemarkDate)
        setIsValidRemark(false);
  }, []);

  return (
    <ModalWrapperWithTab
      tabs={tabs}
      onChangeTab={(tab) => {
        handleTabChange(tab);
      }}
      defaultTab={props?.defaultTab}
      size="large"
    >
      <Modal
        isOpen={modalRescheduleIsOpen}
        onRequestClose={closeRescheduleModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fade}>
          <Reschedule
            appointment={props.event}
            closeModal={() => {
              setModalRescheduleIsOpen(false);
            }}
          />
        </animated.div>
      </Modal>
      <Modal
        isOpen={modalCancelEventIsOpen}
        onRequestClose={closeCancelEventModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fadeCancel}>
          <Cancel
            appointment={props.event}
            setAppointments={props.setAppointments}
            closeModal={() => {
              setModalCancelEventIsOpen(false);
            }}
          />
        </animated.div>
      </Modal>

      {tabActive == "Compromisso" && (
        <CreateEventTab closeModal={props.closeModal} event={props.event} />
      )}

      {tabActive == "Pagamento" && (
        <PaymentTab
          closeModal={props.closeModal}
          event={props.event}
          setAppointments={props.setAppointments}
        />
      )}

      <FooterWrapper type="flex-end">
        <CustomButton
          theme="cancel"
          disabled={['financeiro'].includes(getGroup())}
          onClick={() => setModalCancelEventIsOpen(true)}
          style={{ marginRight: 12 }}
        >
          Desmarcar
        </CustomButton>
        {isValidRemark && (
          <CustomButton
            theme="reschedule"
            disabled={['financeiro'].includes(getGroup())}
            onClick={() => setModalRescheduleIsOpen(true)}
            style={{ marginRight: 12 }}
          >
            Remarcar
          </CustomButton>
        )}
        <CustomButton
          disabled={['financeiro'].includes(getGroup())}
          theme="success" onClick={props.closeModal}>
          Salvar
        </CustomButton>
      </FooterWrapper>
    </ModalWrapperWithTab>
  );
};

export default ShowEvent;
