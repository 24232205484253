import { ButtonHTMLAttributes } from "react";
import { GoIssueClosed, GoTrash} from "react-icons/go";
import { IoMdAdd } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { GiCardExchange } from "react-icons/gi";
import { Button } from "./style";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { FaSave } from "react-icons/fa";

interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme: "primary" | "secondary" | "success" | "cancel" | "add" | "addPrimary" | "register" | "reschedule" | "cancelDeleteAppointment" | "deleteAppointment" | 'arrowLeft' | 'arrowRight' | "update" | "pacientePronto";
  rightIcon?: boolean;
}

const getIcons = (theme: string) => {
  switch (theme) {
    case "primary":
      return <></>;
    case "secondary":
      return <></>;
    case "success":
      return <GoIssueClosed size={20}/>;
    case "cancel":
      return <IoCloseOutline size={24} />;
    case "add":
      return <IoMdAdd size={18} />;
    case "addPrimary":
        return <IoMdAdd size={18} />;
    case "register":
      return <IoMdAdd size={18} />;
    case "reschedule":
      return <GiCardExchange size={18} />;
    case "cancelDeleteAppointment":
      return <IoCloseOutline size={18} />;
    case "deleteAppointment":
      return <GoTrash size={18} />;
    case "arrowLeft":
      return <FaChevronLeft size={18}/>
    case "arrowRight":
        return <FaChevronRight size={18}/>
    case "update":
        return <FaSave size={18}/>
    case "pacientePrto":
      return <GoIssueClosed size={20}/>;
    default:
      return <></>;
  }
}

export const CustomButton = (props: CustomButtonProps) => {
  return <Button {...props}>
    {props.theme && !(props.rightIcon) && getIcons(props.theme)}
    {props.children}
    {props.theme && props.rightIcon && getIcons(props.theme)}
  </Button>
}

export default CustomButton;