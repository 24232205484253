import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import bridgesLogo from "../../assets/bridges.png";
import loginImage from "../../assets/login-image.png";
import { 
  Description, 
  Input, 
  InputTitle, 
  InputWrapper, 
  Title, 
  WrapperMid, 
  WrapperLogin, 
  WrapperPage, 
  WrapperImg, 
  WrapperRight
} from "./style"; 
import { RectangularButton } from "../../components/RectangularButton";
import api from "../../service/api";

export const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleReset = () => {
    if (newPassword.length < 8) {
      toast.error("A senha deve ter pelo menos 8 caracteres.");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("As senhas não correspondem.");
      return;
    }

    setIsLoading(true);
    api.post("/reset-password/", { newPassword })
      .then(() => {
        toast.success("Senha alterada com sucesso!");
        navigate("/login"); 
      })
      .catch(() => {
        toast.error("Erro ao alterar a senha. Tente novamente.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <WrapperPage>
      <WrapperMid>
        <WrapperLogin>
          <WrapperImg>
            <img src={bridgesLogo} alt="Bridges Logo" />
          </WrapperImg>
          <Title>Insira a nova senha</Title>
          <InputWrapper>
            <Input
              placeholder="Nova senha"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              placeholder="Confirmar senha"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </InputWrapper>
          <RectangularButton
            onClick={handleReset}
            disabled={isLoading || !newPassword || !confirmPassword}
            text={isLoading ? "Alterando..." : "Confirmar"}
          />
        </WrapperLogin>
        <WrapperRight>
          <img src={loginImage} alt="Login" />
        </WrapperRight>
      </WrapperMid>
    </WrapperPage>
  );
};
