import { ReactElement, ReactNode } from "react";
import { PageHeaderWrapper } from "./style";

interface HeaderPageProps {
    title: string;
    buttonRight?: ReactNode;
}

export const HeaderPage = ({ title, buttonRight }: HeaderPageProps) => {
    return <PageHeaderWrapper>
        <h1>{title}</h1>
        {buttonRight && buttonRight}
    </PageHeaderWrapper>
}