import styled from 'styled-components';

interface MessageProps{
  notch: 'right' | 'left';
}

const FailContainer = styled.div`
  font-size: 24px;
  color: white;
  background: red;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
`

interface MessageContainerProps {
  failState?: boolean;
  lastQuestion?: boolean;
  notch?: string;
}

const MessageContainer = styled.div<MessageContainerProps>`
  display: flex;
  align-items: center;
  ${(props) => props.notch == 'right' ? 'justify-content: end' : ''};
  ${(props) => props.failState ? 'justify-content: end' : ''};
  ${(props) => props.lastQuestion ? 'max-width: 800px;' : ''};
`

const Message = styled.div<MessageProps>`
  background: ${props => props.notch == 'right' ? '#D9FDD3' : '#fff'};
  border-radius: 5px;
  border-top-right-radius: ${props => props.notch == 'right' ? 0 : '5px'};
  border-top-left-radius: ${props => props.notch == 'left' ? 0 : '5px'};
  box-shadow: 1px 1px 1px 1px #0003;
  flex-direction: column;
  float: left;
  margin: 3px 11px 3px 20px;
  min-width: 237px;
  max-width: 75%;
  padding: 6px 9px 8px;
  position: relative;
`

const NotchContainer = styled.div`
`

interface TextTitleProps {
  titleColor?: string
}

const TextTitle = styled.div<TextTitleProps>`
  color: ${props => props.titleColor ? props.titleColor : '#1270FC'}
`

const MessageBalloonContent = styled.div`
  font-size: 16px;
`

interface HourProps {
  failState?: boolean
}

const Hour = styled.span<HourProps>`
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.failState ? 'space-between' : 'end'};
  margin-left: ${(props) => props.failState ? '4px' : '0'};
  font-size: 10px;
  color: #666666;
  margin-top: 3px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const ButtonLastQuestion = styled.button`
  background: #1270FC;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  max-width: 600px;
  padding: 10px;
  border-radius: 2px;
  color: white;
  border: none;
  margin: 10px 0;
`

export const comp = {
  ButtonWrapper,
  ButtonLastQuestion,
  FailContainer,
  MessageContainer,
  Message,
  NotchContainer,
  TextTitle,
  MessageBalloonContent,
  Hour
} 