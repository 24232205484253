import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoEyeOutline } from "react-icons/io5";
import { MdOutlineModeEdit } from "react-icons/md";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { toast } from "react-toastify";
import CustomButton from "../../components/CustomButton";
import CustomTable from "../../components/CustomTable";
import LabelTags from "../../components/LabelTags";
import PageStructure from "../../components/PageStructure";
import { IconWrapper, customStyles } from "../../components/Shared";
import { HeaderPage } from "../../components/Shared/HeaderPage";
import CreateUser from "../../modals/Patient/Create";
import UpdatePatient from "../../modals/Patient/Update";
import api from "../../service/api";
import { formatPhone } from "../../utils/formatPhone";
import {
  Icon,
  SearchInput,
  WrapperIcons,
  WrapperSearchInput,
  WrapperTable,
} from "./style";

export const PatientsPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientTags, setClientTags] = useState<any[]>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [patient, setPatient] = useState({});

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "CPF",
        accessor: "document",
      },
      {
        Header: "Paciente desde",
        accessor: "created_at",
        Cell: ({ value }: { value: string }) => (
          <span>{format(new Date(value), "dd/MM/yyyy HH:mm")}</span>
        ),
      },
      {
        Header: "Telefone",
        accessor: "phone",
        Cell: ({ value }: { value: string }) => (
          <span>
            {formatPhone(value.replace("+55", "")) || "Não informado"}
          </span>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }: { value: string }) => (
          <span>{value || "Não informado"}</span>
        ),
      },
      {
        Header: "Tag",
        accessor: "tag",
        Cell: (data: any) => (
          <LabelTags
            info={data.row.original.tag?.name}
            color={data.row.original.tag?.color}
          />
        ),
      },
      {
        Header: "Ações",
        accessor: "",
        Cell: (data: any) => (
          <WrapperIcons>
            <IconWrapper
              onClick={() => {
                navigate("details", {
                  state: { patient: data.row.original },
                });
              }}
            >
              <IoEyeOutline size={20} color="var(--primary-icon-color)" />
            </IconWrapper>
            <IconWrapper
              onClick={() => {
                setShowEditModal(true);
                setPatient(data.row.original);
              }}
            >
              <MdOutlineModeEdit size={20} color="var(--primary-icon-color)" />
            </IconWrapper>
          </WrapperIcons>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      let toastLoading = toast.loading("Carregando paciente", {
        className: "toast-loading",
      });

      api
        .get(`/clients/?name=${clientName}`)
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          toast.error("Erro ao carregar paciente!");
        })
        .finally(() => {
          toast.dismiss(toastLoading);
        });
    }, 500);

    return () => clearTimeout(timeout);
  }, [clientName]);

  const fetchPatients = () => {
    let toastLoading = toast.loading("Carregando pacientes...", {
      className: "toast-loading", // Classe para o estilo do loading
    });

    api
      .get("/clients")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        toast.error("Erro ao carregar pacientes!");
      })
      .finally(() => {
        toast.dismiss(toastLoading);
      });
  };

  const fetchClientTags = () => {
    api
      .get("/tags/")
      .then((resp) => {
        setClientTags(resp.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchPatients();
    fetchClientTags();
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalIsOpen ? 1 : 0 },
  });

  const editFade = useSpring({
    from: { opacity: 0 },
    to: { opacity: showEditModal ? 1 : 0 },
  });

  return (
    <PageStructure>
      <Modal
        isOpen={showEditModal}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={editFade}>
          <UpdatePatient
            closeModal={closeEditModal}
            updatePatients={fetchPatients}
            patient={patient}
          />
        </animated.div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fade}>
          <CreateUser closeModal={closeModal} updateClients={fetchPatients} />
        </animated.div>
      </Modal>
      <HeaderPage
        title="Pacientes"
        buttonRight={
          <CustomButton onClick={() => setIsOpen(true)} theme="addPrimary">
            Registrar pacientes
          </CustomButton>
        }
      />
      <WrapperSearchInput>
        <SearchInput
          placeholder="Pesquise pelo nome do paciente"
          onChange={(e) => setClientName(e.target.value)}
        />
        <Icon>
          <FiSearch />
        </Icon>
      </WrapperSearchInput>
      <WrapperTable>
        <CustomTable
          columns={columns}
          columnMinWidths={{}}
          data={data}
          maxHeight="calc(100vh - 190px)"
        />
      </WrapperTable>
      {/* <Pagination
        totalPages={1}
        onPageChange={(pageNumber) => {
          
        }}
      /> */}
    </PageStructure>
  );
};

export default PatientsPage;
