import { TextareaHTMLAttributes } from "react";
import { TextAreaInput } from "./style";
import { Wrapper } from "../Shared";

interface CustomTextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
  }
  
  const CustomTextArea: React.FC<CustomTextAreaProps> = ({ label, ...rest }) => {
    return (
      <Wrapper>
        {label && <span>{label}</span>}
        <TextAreaInput {...rest}/>
      </Wrapper>
    );
  };
  
  export default CustomTextArea;