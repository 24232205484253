import { useEffect, useState } from "react";
import { CiMail } from "react-icons/ci";
import { GoLocation } from "react-icons/go";
import { IoMdTime } from "react-icons/io";
import { MdOutlineBadge } from "react-icons/md";
import { PiIdentificationCardLight, PiPhone, PiUserCircleLight } from "react-icons/pi";
import { RiHotelLine } from "react-icons/ri";
import { toast } from "react-toastify";
import AnonymousProfilePhoto from "../../assets/userAnonymous.jpeg";
import CustomDropdown from "../../components/CustomDropDown";
import CustomInput from "../../components/CustomInput";
import ModalStepByStep from "../../components/ModalStepByStep";
import { ProfileImageWrapper } from "../../pages/Patients/Profile/style";
import api from "../../service/api";
import { formatDocument } from "../../utils/formatDocument";
import { formatPhone } from "../../utils/formatPhone";
import { ContentWrapper, InfoWrapper } from "../Event/Create/style";
import {
  ConfigHoursContainer,
  ConfigHoursWrapper,
  CustomInputWrapper,
  FlexContainer,
  HeaderTitle,
  WeekContainer,
  WeekendContainer,
} from "./style";

interface CreateProfessionalProps {
  closeModal: () => void;
  updateClients?: () => void;
}

export const CreateProfessional = ({
  closeModal,
  updateClients,
}: CreateProfessionalProps) => {
  const [form, setForm] = useState<any>({
    name: "",
    document: "",
    email: "",
    phone: "",
    address: "",
    n_conselho: "",
  });

  const [formWeek, setFormWeek] = useState<any>({
    Domingo: {},
    Sabado: {},
    Segunda: {},
    Terça: {},
    Quarta: {},
    Quinta: {},
    Sexta: {},
  });

  const steps = [
    {
      stepTitle: "Informações Básicas",
    },
    {
      stepTitle: "Configuração de horário",
    },
    {
      stepTitle: "Foto de Perfil",
    },
  ];
  const weekDay = ["Segunda", "Terça", "Quarta", "Quinta", "Sexta"];
  const weekendDay = ["Domingo", "Sabado"];

  const [selectedEstablishment, setSelectedEstablisiment] = useState<any>(null);
  const [establishments, setEstablishments] = useState<any>([]);
  const [stepActive, setStepActive] = useState(steps[0]);
  const [loading, setLoading] = useState(false);
  const [imagem, setImagem] = useState<any>(null);
  const [selectedImage, setSelectedImage] = useState<any>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    if (name == "document") value = formatDocument(value);
    else if (name == "phone") value = formatPhone(value);
    else if (name == 'n_conselho') value = value.replace(/\D/g, '');
    setForm({ ...form, [name]: value });
  };

  const handleHoursConfigChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let { name, value } = event.target;

    const [day, field] = name.split(",");
    setFormWeek({
      ...formWeek,
      [day]: {
        week_day: day,
        ...formWeek[day],
        [field]: value + ":00",
      },
    });
  };

    const saveClient = () => {
        setLoading(true);
        const formData = new FormData();

        formData.append('name', form["name"]);
        formData.append('document', form["document"]);
        formData.append('email', form["email"]);
        formData.append('phone', form["phone"]);
        formData.append('address', form["address"]);
        formData.append('n_conselho', form["n_conselho"]);
        formData.append('estabilishments', JSON.stringify(selectedEstablishment.id));

        if(imagem)
          formData.append('photo', selectedImage);

        api.post("/professionals/", formData)
            .then((response) => {
                toast.success("Profissional cadastrado com sucesso!");
                closeModal?.()
                for (const chave in formWeek) {
                    if(!(Object.keys(formWeek[chave]).length)) continue

                    api.post(`/agenda/?id=${response.data.id}`, {
                        ...formWeek[chave],
                        professional: response.data.id
                    })
                    .then((response) => {
                        setLoading(false);
                        closeModal();
                        updateClients?.();
                    })
                    .catch((error) => {
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                }
            })
            .catch((error) => {
                toast.error("Ocorreu um erro ao cadastrar o Profissional!");
            })
            .finally(() => {
                setLoading(false);
            });
    };

  useEffect(() => {
    api
      .get("/establishments/")
      .then((response) => {
        setEstablishments(response.data);
      })
      .catch((error) => {
        
      });
  }, []);

  const handleEstablishmentSelect = (option: any) => {
    setSelectedEstablisiment(option);
    setForm({ ...form, estabilishments: [option.id] });
  };

  const validateNextStep = () => {
    if (stepActive.stepTitle == "Informações Básicas") {
      if (form["name"].length < 3) {
        toast.error("Digite um nome válido");
        return false;
      } else if (form["document"].replace(/\D/g, "").length != 11) {
        toast.error("Digite um cpf válido");
        return false;
      } else if (selectedEstablishment == null) {
        toast.error("Escolha um estabelecimento válido");
        return false;
      } else if (form["phone"].replace(/\D/g, "").length != 11) {
        toast.error("Digite um número de telefone válido");
        return false;
      }
    }

    return true;
  };

  const handleFileUpload = (event: any) => {
    const arquivo = event.target.files[0];
    const reader = new FileReader();
    setSelectedImage(arquivo)

    reader.onload = () => {
      const result = reader.result as any
      setImagem(result);
    };

    reader.readAsDataURL(arquivo);
  };

  return (
    <ModalStepByStep
      steps={steps}
      onChangeStep={(step) => {
        setStepActive({ stepTitle: step.stepTitle });
      }}
      closeModal={closeModal}
      onConclude={() => {
        saveClient();
      }}
      validateNextStep={() => validateNextStep()}
      isUpdateModal={false}
    >
      <ContentWrapper>
        <InfoWrapper>
          {stepActive.stepTitle == steps[0].stepTitle && (
            <>
              <FlexContainer>
                <CustomInput
                  disabled={loading}
                  value={form["name"]}
                  onChange={handleInputChange}
                  label="Nome"
                  name="name"
                  placeholder="Digite o nome do profissional"
                  leftIcon={
                    <PiUserCircleLight
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
                <CustomInput
                  disabled={loading}
                  value={form["document"]}
                  onChange={handleInputChange}
                  label="CPF"
                  name="document"
                  placeholder="Digite o CPF do profissional"
                  leftIcon={
                    <PiIdentificationCardLight
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
              </FlexContainer>
              <FlexContainer>
                <CustomInput
                  disabled={loading}
                  value={form["email"]}
                  onChange={handleInputChange}
                  label="Email"
                  name="email"
                  placeholder="Digite o Email do profissional"
                  leftIcon={
                    <CiMail size={20} color="var(--primary-icon-color)" />
                  }
                />
                <CustomDropdown
                  leftIcon={
                    <RiHotelLine size={20} color="var(--primary-icon-color)" />
                  }
                  selectedOption={selectedEstablishment?.name}
                  setSelectedOption={handleEstablishmentSelect}
                  options={establishments}
                  label="Estabelecimento"
                />
              </FlexContainer>

              <FlexContainer>
                <CustomInput
                  disabled={loading}
                  value={form["phone"]}
                  onChange={handleInputChange}
                  label="Telefone"
                  name="phone"
                  placeholder="Digite o Telefone do profissional"
                  leftIcon={
                    <PiPhone size={20} color="var(--primary-icon-color)" />
                  }
                />
                <CustomInput
                  disabled={loading}
                  value={form["n_conselho"]}
                  onChange={handleInputChange}
                  label="Número do conselho"
                  name="n_conselho"
                  placeholder="Digite o número do conselho do profissional"
                  leftIcon={
                    <MdOutlineBadge size={20} color="var(--primary-icon-color)" />
                  }
                />
              </FlexContainer>
              <CustomInput
                  disabled={loading}
                  value={form["address"]}
                  onChange={handleInputChange}
                  label="Endereço"
                  name="address"
                  placeholder="Digite o endereço do profissional"
                  leftIcon={
                    <GoLocation size={20} color="var(--primary-icon-color)" />
                  }
                />
            </>
          )}

          {stepActive.stepTitle == steps[1].stepTitle && (
            <ConfigHoursContainer>
              <WeekendContainer>
                {weekendDay.map((day) => {
                  return (
                    <ConfigHoursWrapper>
                      <HeaderTitle>{day}</HeaderTitle>
                      <CustomInputWrapper>
                        <CustomInput
                          disabled={loading}
                          label="Horário de Entrada"
                          name={day + ",start_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          leftIcon={
                            <IoMdTime
                              size={16}
                              color="var(--primary-icon-color)"
                            />
                          }
                        />

                        <CustomInput
                          disabled={loading}
                          label="Horário de saída"
                          name={day + ",end_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          leftIcon={
                            <IoMdTime
                              size={16}
                              color="var(--primary-icon-color)"
                            />
                          }
                        />
                      </CustomInputWrapper>

                      <CustomInputWrapper>
                        <CustomInput
                          disabled={loading}
                          label="Almoço Entrada"
                          name={day + ",lunch_start_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          leftIcon={
                            <IoMdTime
                              size={16}
                              color="var(--primary-icon-color)"
                            />
                          }
                        />

                        <CustomInput
                          disabled={loading}
                          label="Almoço saída"
                          name={day + ",lunch_end_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          leftIcon={
                            <IoMdTime
                              size={16}
                              color="var(--primary-icon-color)"
                            />
                          }
                        />
                      </CustomInputWrapper>
                    </ConfigHoursWrapper>
                  );
                })}
              </WeekendContainer>

              <WeekContainer>
                {weekDay.map((day) => {
                  return (
                    <ConfigHoursWrapper>
                      <HeaderTitle>{day}</HeaderTitle>
                      <CustomInputWrapper>
                        <CustomInput
                          disabled={loading}
                          label="Horário de Entrada"
                          name={day + ",start_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          leftIcon={
                            <IoMdTime
                              size={16}
                              color="var(--primary-icon-color)"
                            />
                          }
                        />

                        <CustomInput
                          disabled={loading}
                          label="Horário de saída"
                          name={day + ",end_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          leftIcon={
                            <IoMdTime
                              size={16}
                              color="var(--primary-icon-color)"
                            />
                          }
                        />
                      </CustomInputWrapper>

                      <CustomInputWrapper>
                        <CustomInput
                          disabled={loading}
                          label="Almoço Entrada"
                          name={day + ",lunch_start_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          leftIcon={
                            <IoMdTime
                              size={16}
                              color="var(--primary-icon-color)"
                            />
                          }
                        />

                        <CustomInput
                          disabled={loading}
                          label="Almoço Saída"
                          name={day + ",lunch_end_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          leftIcon={
                            <IoMdTime
                              size={16}
                              color="var(--primary-icon-color)"
                            />
                          }
                        />
                      </CustomInputWrapper>
                    </ConfigHoursWrapper>
                  );
                })}
              </WeekContainer>
            </ConfigHoursContainer>
          )}

          {stepActive.stepTitle == steps[2].stepTitle && (
            <>
              <FlexContainer>
                <ProfileImageWrapper
                  src={imagem || AnonymousProfilePhoto}
                  alt="Foto de perfil"
                />
                <div style={{marginLeft: '10px'}}>
                  <CustomInput
                    label="Escolha uma foto"
                    type="file"
                    disableBorder
                    onChange={handleFileUpload}
                  />
                </div>
              </FlexContainer>
            </>
          )}
        </InfoWrapper>
      </ContentWrapper>
    </ModalStepByStep>
  );
};

export default CreateProfessional;
