import styled from 'styled-components';

export const AvatarContainer = styled.div`
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const OnlineIndicator = styled.div`
  width: 12px;
  height: 12px;
  background-color: #4caf50;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  bottom: 5px;
  right: 5px;
`;